import {
  createReceiver,
  createSender,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'store/wish-lists/'

const ADD_TO_WISHLIST_URL = prefixAPI('create/', MODEL)
const REMOVE_FROM_WISHLIST_URL = prefixAPI('delete/', MODEL)
const WISHLIST_IDS_LIST_URL = prefixAPI('list-ids/', MODEL)
const WISHLIST_URL = prefixAPI('list/{?limit,offset}', MODEL)

export const addToWishlist = createSender(ADD_TO_WISHLIST_URL)
export const removeFromWishlist = createSender(
  REMOVE_FROM_WISHLIST_URL
)

export const getWishListIds = createReceiver(
  WISHLIST_IDS_LIST_URL
)

export const getWishList = createReceiver(
  WISHLIST_URL
)
