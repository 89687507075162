
export default {
  name: 'StepsController',
  data() {
    return {

    }
  },
  render() {
    return this.$scopedSlots.default({

    })
  },
}
