//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Confirm',
  props: ['promise', 'question'],
  methods: {
    submitForm(value) {
      this.promise(value)
      this.$emit('close')
    },
  },
}
