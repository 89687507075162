import { withDefaultProps } from '@aspectus/vue-utils'

import BaseView from '@app/List/View'
import SearchFilter from '@app/Filters'
import SlimFilter from '@app/Filters/Slim/View'

import {
  productsListUrl,
  searchListUrl,
  attributesResource,
  shopProductListUrl,
  availableAttributesResource,
} from '@api/catalog.service'

// const CatalogView = {
//   name: 'CatalogViewTest',
//   productsResource: productsListUrl,
//

const composer = (
  listUrl,
  filtersUrl,
  availableFiltersUrl,
  filterComponent = 'search-filter'
) => withDefaultProps({
  productsResource: () => listUrl,
  attributesResource: () => filtersUrl,
  availableAttributesResource: () => availableFiltersUrl,
  filterComponent: () => filterComponent,
})(BaseView)

const CatalogView = composer(
  productsListUrl,
  attributesResource,
  availableAttributesResource
)

const ShopView = composer(
  shopProductListUrl,
  // { execute: () => new Promise(resolve => (resolve([]))) },
  attributesResource,
  availableAttributesResource
  // 'slim-filter'
)

const SearchView = composer(
  searchListUrl,
  attributesResource,
  availableAttributesResource
  // 'slim-filter'
)

export default function plugin(Vue, { postfix = '-filters' } = { }) {
  Vue.component(`catalog${postfix}`, CatalogView)
  Vue.component(`shop${postfix}`, ShopView)
  Vue.component(`search${postfix}`, SearchView)
  Vue.component('search-filter', SearchFilter)
  Vue.component('slim-filter', SlimFilter)
}
