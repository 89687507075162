//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapState,
  mapActions,
} from 'vuex'

export default {
  name: 'FavoritePreview',
  url: `${window.indexPage}buyer/wishlist/`,
  isAuth: window.authent,
  data() {
    return {
    }
  },
  computed: {
    ...mapState('like', [
      'count',
    ]),
  },
  created() {
    if (this.$options.isAuth) this.getWishListIds()
  },
  methods: {
    ...mapActions('like', [
      'getWishListIds',
    ]),
  },

}
