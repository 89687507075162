import { createRouter } from '@/router';
import { createI18n } from '@/i18n';
import { createStoreObject } from '@/store';
import { mapState, mapActions, mapGetters } from 'vuex'
// import App from '@/App';
// Router, Store,

import {
  getCookie,
} from '@utils/cookies'

const DEFAULT_PHONE_MASK = '+380#########'

export const VYBIR_CATEGORY_FOR_ADULT_COOKIE_NAME = 'VYBIR_CATEGORY_FOR_ADULT'

const WAITING_LIST_COUNT_KEY = 'waitinglistCount'
const WISH_LIST_COUNT_KEY = 'wishlistCount'
const COMPARISON_LIST_COUNT_KEY = 'comparitonCount'

export function createApp({ Vue, I18n, Store, Router }) {
  const store = createStoreObject({ Vue, Store });
  const i18n = createI18n({ Vue, I18n });
  const router = createRouter({ Vue, Router, store });
  Vue.prototype.$body = document.getElementById('app')
  Vue.prototype.$copyToClipboard = function copyToClipboard(
    value,
    successTitle = this._('Copied to clipboard success header'),
    successText = this._('Copied to clipboard success text!')
  ) {
    // HTTPS ок localhost
    navigator.clipboard.writeText(value)
      .then(() => {
        this.$notify({
          type: 'success',
          title: successTitle,
          text: successText,
        })
      })
      .catch(() => {
        this.$notify({
          type: 'warning',
          title: this._('Не удалось скопировать'),
          text: this._('Наши специалисты уже работают над этим'),
        })
      });
  };
  const app = new Vue({
    i18n,
    router,
    store,
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
        windowHeight: window.innerHeight,
        isLoadMenu: false,
      }
    },
    computed: {
      ...mapState('search', [
        'show_overlay',
      ]),
      ...mapState('cart', [
        'cart_info',
      ]),
      ...mapState('menu', [
        'menuIsLoading',
      ]),
      ...mapGetters('like', {
        [WISH_LIST_COUNT_KEY]: 'getCount',
      }),
      ...mapGetters('comparison', {
        [COMPARISON_LIST_COUNT_KEY]: 'getCount',
      }),
      ...mapGetters('waitinglist', {
        [WAITING_LIST_COUNT_KEY]: 'getCount',
      }),
    },
    mounted() {
      this.checkCookieForSpecialCategories()
    },
    methods: {
      ...mapActions('search', [
        'trigger_overlay',
      ]),
      ...mapActions('menu', [
        'getMenu',
      ]),
      getMaxH() {
        return window.innerHeight * 0.9
      },
      checkCookieForSpecialCategories() {
        if (!window.categoryForAdults) return
        const show = getCookie(VYBIR_CATEGORY_FOR_ADULT_COOKIE_NAME)
        if (show) return
        this.openAgeVerifyModal()
      },
      linkOpener(url) {
        window.location.href = url
      },
      openAgeVerifyModal() {
        this.$modal.show(() => import('@app/UiElements/AgeVerifyModal'), {

        }, {
          height: 'auto',
          name: this.name,
          minWidth: 300,
          classes: ['fullwidth-modal'],
          adaptive: true,
          clickToClose: false,
          scrollable: true,
          overlayTransition: 'unset',
          transition: 'unset',
        })
      },
    },
  });
  function getCurrentDecimal(from) {
    let price = 0
    try {
      price = Number(from.replace(',', '.'))
    } catch (e) {
      price = from
    }
    return app.$n(price, 'decimal')
  }
  Vue.prototype.getCurrentDecimal = getCurrentDecimal
  Vue.prototype.defaultPhoneMask = DEFAULT_PHONE_MASK
  return { app, i18n }
}
