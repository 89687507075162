
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ComparisonPreview',
  props: ['link', 'getData'],
  data() {
    return {
    }
  },
  computed: {
    ...mapState('comparison', [
      'comparison_ids',
    ]),
  },
  created() {
    if (this.getData) this.getComparisonList()
  },
  methods: {
    ...mapActions('comparison', [
      'getComparisonList',
    ]),
  },
  render() {
    return this.$scopedSlots.default({
      length: this.comparison_ids.length,
    })
  },
}
