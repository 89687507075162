//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import debounce from 'lodash.debounce'

export default {
  name: 'MegaMenu',
  props: ['parent', 'level', 'listing', 'menu', 'showChild'],
  data() {
    return {
      list: [],
      actives: [],
      path: window.location.pathname,
      lang: window.language,
    }
  },
  created() {
    const delay = 200
    this.debounceSetActive = debounce((parent, level, idx) => {
      this.setActive(parent, level, idx)
    }, delay)
  },
  // computed: {
  //   ...mapGetters('menu', [
  //     'menu',
  //   ]),
  // },
  methods: {
    updater(level, parent, list) {
      this.$emit('update', level - 1, list, level, parent)
    },
    async getCategories(parent, level) {
      if (this.menu && this.menu.has(parent)) {
        this.list = this.menu.get(parent)
      }
      this.updater(level, parent, this.list)
    },
    setActive(parent, level, idx) {
      // eslint-disable-next-line no-new-object
      const actives = Array.from({ length: this.listing.length }, () => new Object())
      this.actives = []
      actives.forEach(el => {
        this.actives.push(el)
      })
      this.$set(this.actives[idx], 'is_active', 'is-active')
      // this.$set()
      // if (this.listing[idx].has_children) {
      //   this.getCategories(parent, level)
      //   return
      // }
      this.updater(level, parent, [])
    },
  },
}
