const classTriggerForSearch = (el, val) => {
  if (val) {
    el.classList.add('on-overlay')
  } else {
    el.classList.remove('on-overlay')
  }
}

const SET_OVERLAY = 'SET_OVERLAY'
const SET_SEARCH = 'SET_SEARCH'

export function createStore() {
  return {
    namespaced: true,
    state: {
      global_search: '',
      show_overlay: false,
    },
    actions: {
      trigger_overlay({ commit }, payload) {
        const mobileEl = document.getElementById('header')
        // let desktop_el = document.getElementById('desktop-search')
        // let desktop = 1200
        // if (window.innerWidth < desktop) {
        // } else {
        //   classTriggerForSearch(desktop_el, payload)
        // }
        classTriggerForSearch(mobileEl, payload)
        commit(SET_OVERLAY, payload)
      },
    },
    mutations: {
      [SET_SEARCH](state, payload) {
        state.global_search = payload
      },
      [SET_OVERLAY](state, payload) {
        state.show_overlay = payload
      },
    },
  }
}
