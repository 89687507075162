//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { omit, remove } from 'ramda';
import { isEmpty } from '@aspectus/vue-utils';
// import { FILTERS_ALL } from '../const';
import {
  MultiselectRenderer,
  SelectRenderer,
  SliderRenderer,
  // DateRangeRenderer,
  CombinedInputRenderer,
  AutocompleteMultiselectRenderer,
} from './renderers';

const EXCLUSIVE_FILTER_TYPES = [
  'combined-input',
];

export default {
  props: ['all', 'filters'],

  renderersMap: {
    select: SelectRenderer,
    checkbox: MultiselectRenderer,
    'int-range': SliderRenderer,
    // 'date-range': DateRangeRenderer,
    'combined-input': CombinedInputRenderer,
    'autocomplete-multiselect': AutocompleteMultiselectRenderer,
  },

  computed: {
    displayed() {
      return (this.all || [])
        // .filter(({ id }) => FILTERS_ALL.includes(id))
        .filter(({ props: { attribute: { props: { label } } } }) => (
          label in this.filters && !isEmpty(this.filters[label])))
        .filter(filter => filter.props.filter_widget_type in this.$options.renderersMap);
    },
    simpleFilters() {
      return this.displayed
        .filter(filter => !EXCLUSIVE_FILTER_TYPES.includes(filter.type));
    },
    combinedInputFilters() {
      return this.displayed.filter(filter => 'combined-input' === filter.type);
    },
  },

  methods: {
    update(value) {
      this.$emit('update:filters', value);
    },
    clear() {
      this.$emit('input', {});
    },
    remove(id) {
      this.update(omit([id], this.filters));
    },

    removeCombinedInputValue(filter, value) {
      const current = this.filters[filter.id];
      const index = current.indexOf(value);

      this.update({ ...this.filters, [filter.id]: remove(index, 1, current) });
    },
  },
};

