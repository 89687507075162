import {
  getCookie,
  setCookie,
} from '@utils/cookies';

const classTrigger = el => {
  const content = document.getElementById('content-parent')
  const cards = Array.from(content.getElementsByClassName('product-card'))
  cards.forEach(card => {
    card.classList.remove(el.classRemover)
    card.classList.add(el.classAdder)
    card.parentElement.classList.remove(...card.parentElement.classList)
    // el.cellRemover.forEach(cl => card.parentElement.classList.remove(cl))
    el.cellAdder.forEach(cl => card.parentElement.classList.add(cl))
  })
}
const SAME_LARGE = ['g-cell', 'g-cols', 'g-cols--12', 'g-cols--6-md', 'g-cols--4-lg']
const SAME_TINY = ['g-cell', 'g-cols', 'g-cols--6', 'g-cols--4-md', 'g-cols--3-lg', 'g-cols--3-xl']

const LARGE_CARDS = [...SAME_LARGE, 'g-cols--4-xl', 'g-cols--3-mac']
const TINY_CARDS = [...SAME_TINY, 'g-cols--2-mac']

// const SLIM_LARGE_CARDS = [...SAME_LARGE]
// const SLIM_TINY_CARDS = [...SAME_TINY]

const DEFAULT_LAYOUT = [LARGE_CARDS, TINY_CARDS]
// const SLIM_LAYOUT = [SLIM_LARGE_CARDS, SLIM_TINY_CARDS]

const SET_VIEW = 'SET_VIEW'
const SET_TILES_CLASSES = 'SET_TILES_CLASSES'

export const COOKIE_NAME = 'VYBIR_TILE_VIEW_SIZE'

export function createStore() {
  return {
    namespaced: true,
    state: {
      notif_count: 2,
      view: getCookie(COOKIE_NAME) || 16,
      tiles: [
        {
          size: 9,
          text_size: 'nine',
          classAdder: 'ss',
          classRemover: 'product-card--mini',
          cellAdder: LARGE_CARDS,
          cellRemover: TINY_CARDS,
        },
        {
          size: 16,
          text_size: 'sixteen',
          classRemover: 'hhh',
          classAdder: 'product-card--mini',
          cellRemover: LARGE_CARDS,
          cellAdder: TINY_CARDS,
        },
      ],
    },
    actions: {
      trigger_view({ commit, state }, { size, isTiny = false }) {
        // eslint-disable-next-line eqeqeq
        const val = state.tiles.find(el => el.size == size)
        commit(SET_TILES_CLASSES, isTiny)
        commit(SET_VIEW, size)
        classTrigger(val)
      },
    },
    mutations: {
      [SET_VIEW](state, payload) {
        state.view = payload
        setCookie(COOKIE_NAME, Number(payload))
      },
      [SET_TILES_CLASSES](state) {
        let tiles = DEFAULT_LAYOUT
        state.tiles.forEach(tile => {
          const [add, remove] = tiles
          tile.cellAdder = add
          tile.cellRemover = remove
          tiles = tiles.reverse()
        })
      },
    },
  }
}
