//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  getSellerShippingServices,
  // getNovaposhtaCities,
  // getNovaposhtaWarehouses,
} from '@api/order.service'

import pathOr from 'ramda/src/pathOr'

import {
  receiveResource, createResource,
} from '@resource/resource'

import cancellablePromiseProxy from '@aspectus/cancellable-promise-proxy';

const FIRST_PAGE_LIMIT = 0

export default {
  name: 'OrderDelivery',
  props: ['fields'],
  keyGetter: x => `${x.type}:${x.id}`,
  data() {
    return {
      variants: [],
      selected: '',
      city: JSON.parse(window.current_city),
      form: {
        shop: this.fields.shop,
        city: '',
        shipping_info: '',
        street: '',
        house: '',
        apartment: '',
        warehouse: '',
      },
      // shipping_variant: [],
      isLoading: false,
      WIDGETS_MAP: {
        autocomplete_single: {
          tag: 'd-control-multiselect',
          props: {
            label: 'caption',
          },
          events: {
            open: this.getAndGenerate,
            'search-change': this.getAndGenerate,
          },
          afterList: this.getAndGenerate,
        },
        autocomplete_with_input: {
          tag: 'd-control-multiselect',
          props: {
            label: 'caption',
            taggable: true,
          },
          events: {
            open: this.getAndGenerate,
            'search-change': this.getAndGenerate,
            tag: this.addTag,
          },
          afterList: this.getAndGenerate,
        },
        input: {
          tag: 'd-control-input',
          events: {},
        },
      },
      currentRequest: '',
    }
  },
  watch: {
    'fields.city': {
      handler(nval) {
        if (nval) {
          this.city = nval
          this.form.city = nval
        }
      },
      deep: true,
    },
  },
  computed: {
    thirdPartyCities() {
      if (this.config) {
        return this.config?.new_post_pickup?.fields[0]?.props.options
      }
      return []
    },
  },
  created() {
    this.getShippingVariants()
  },
  mounted() {
    // this.config.new_post_pickup.fields[1].props.disabled = !this.form.city
    // console.log(this.config.new_post_pickup.fields[1])
  },
  methods: {
    addTag(event, field, parent) {
      console.log(event, field, parent, 'TAG');
      // const [path] = JSON.parse(field.props.value_path).map(o => o.split('.'))
      // console.log(path);
      // field.props.value = {}
      // let last = ''
      // path.forEach(key => {
      //   if (last) {
      //     field.props.value[last][key] = event
      //   } else {
      //     last = key
      //     field.props.value[last] = {}
      //   }
      // })
      field.props.value = event
      console.log(field.props.value)
    },
    cityChange() {
      const pr = arg => new Promise((resolve, reject) => {
        resolve(arg)
        reject(new Error('error'))
      }).then(() => {
        this.city = arg
        this.form.city = arg
        this.$emit('city:change', arg)
      })
      this.$modal.show(
        () => import('@app/UiElements/Header/CityChange'),
        {
          info: {
            simpleSet: true,
            promise: pr,
          },
        }, {
          height: 'auto',
          classes: ['cart-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
    },
    eventGenerator(events, ...args) {
      return Object.entries(events).reduce((acc, e) => {
        const [key, val] = e
        acc[key] = $event => val($event, ...args)
        return acc
      }, {})
    },
    expandDependencies(f, p) {
      return p.dependencies.reduce((acc, obj) => {
        const path = obj.lookup_value_path.split('.')
        if (!this.form[obj.source]) return acc
        acc[obj.lookup] = pathOr('', path, this.form[obj.source])
        return acc
      }, {})
    },
    expandParams(f, p) {
      const dependencies = this.expandDependencies(f, p) || {}
      console.log(p.meta, p)
      const refs = p.meta.reduce((acc, obj) => {
        const path = obj.name.split('.')
        acc[obj.key] = pathOr('', path, this[obj.from_field])
        return acc
      }, {})
      return {
        ...dependencies, ...refs,
      }
    },
    async getAndGenerate(event, field, parent, visible) {
      if (false === visible) return
      this.isLoading = true

      if (this.currentRequest) this.currentRequest.cancel()
      const cancelController = new AbortController();
      const base = receiveResource.config('signal', cancelController.signal)
      const executer = createResource(field.props.url, base)

      const params = this.expandParams(field, parent) || {}
      if (null !== event) field.props.search = event
      let offset = (field.props.meta.offset +
        field.props.meta.limit) || 0

      if (!visible) offset = FIRST_PAGE_LIMIT

      const limit = 100

      // nextTick - for get last value of field.props.search
      this.$nextTick(async () => {
        this.currentRequest = cancellablePromiseProxy(
          new Promise((resolve, reject) => {
            executer.execute({
              ...params, search: field.props.search, offset, limit,
            })
              .then(resolve, reject);
          }),
          { cancelController }
        ).then(({
          data: {
            items,
            pagination,
          },
        }) => {
          this.isLoading = false
          if (visible) {
            field.props.options.push(...items)
          } else {
            field.props.options = items
          }
          field.props.meta = pagination
        })
      })
    },
    internalCities() {
      if (this.config && this.config.pickup) {
        return this.config.pickup.fields[0].props.options
      }
      return []
    },
    async getShippingVariants() {
      const { data: { items } } = (await getSellerShippingServices.execute({ id: this.fields.shop }))
      // this.shipping_variant = items
      this.variants = items.reduce((acc, obj) => {
        const variant = obj.props.configs.map(el => ({ ...el, ...{ meta: obj.props.metadata } }))
        acc.push(...variant)
        return acc
      }, [])
      // const [shipping] = this.variants
      // eslint-disable-next-line prefer-destructuring
      this.form.shipping_info = this.variants[0]
      this.$nextTick(() => {
        this.$emit('update', this.form)
      })
    },
    check() {
      this.$emit('update', this.form)
    },
    getCustomLabel(obj, path) {
      if (!path.props.value_path) return path.props.label

      const l = JSON.parse(path.props.value_path).filter(o => pathOr(false, o.split('.'), obj)).map(o => pathOr(null, o.split('.'), obj))

      return l.join(', ') || obj
    },
  },
}
