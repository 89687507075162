import CartView from '@app/Cart/View'
import CartPreview from '@app/Cart/Preview'
import SimpleCardUi from '@app/Cart/SimpleCardUi'
import CartProduct from '@app/Cart/Product'
import CartProductUi from '@app/Cart/ProductUi'
import AddToCart from '@app/Cart/Add'

export default function install(Vue) {
  Vue.component(CartProductUi.name, CartProductUi)
  Vue.component(CartProduct.name, CartProduct)
  Vue.component(CartPreview.name, CartPreview)
  Vue.component(SimpleCardUi.name, SimpleCardUi)
  Vue.component(CartView.name, CartView)
  Vue.component('AddToCart', AddToCart)
}
