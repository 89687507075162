//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  SellerRegister,
} from '@api/auth.service'

import CodeChecker from '@app/Auth/Seller/CodeChecker'

import FormMixin from '@app/Forms/FormMixin'
import VerifyMixin from '@app/Auth/Verify.mixin'

import {
  MOBILEREG,
  EMAILREG,
} from '@/const'

const PHONE_WAY = 'default'
const EMAIL_WAY = 'email'

export default {
  name: 'SellerRegister',
  mixins: [FormMixin, VerifyMixin],
  components: {
    CodeChecker,
  },
  data() {
    return {
      accept_rules: false,
      role: window.current_host,
      non_field_errors: '',
      labels: {
        code: this._('ИНН для ФЛП или ЕГРПОУ для Юридических лиц'),
      },
      domain: window.current_domain,
      offer: window.offer_seller,
      rules: window.rules_seller,
      form: {
        first_name: '',
        email: '',
        phone: '',
        password: '',
        code: '',
      },
      form_confirm: {
        activation_code: '',
      },
      fields: [
        // {
        //   size: '12',
        //   title: this._('Название предприятия'),
        //   rules: 'required',
        //   name: 'first_name',
        //   props: {
        //     disabled: true,
        //   },
        //   type: 'text',
        //   tag: 'd-control-input',
        // },
        {
          size: '6',
          title: this._('Е-mail или номер телефона'),
          type: 'text',
          rules: 'required|email_phone',
          name: 'username',
          tag: 'd-control-input',
        },

        {
          size: '6',
          title: this._('Пароль'),
          rules: 'required|password',
          type: 'password',
          name: 'password',
          tag: 'd-control-input',
        },
      ],
    }
  },
  computed: {
    isEmail() {
      return EMAILREG.test(this.form.username)
    },
    isPhone() {
      return MOBILEREG.test(this.form.username)
    },
    way() {
      return this.isEmail ? EMAIL_WAY : PHONE_WAY
    },
  },
  methods: {
    completeData({ name, code }) {
      this.non_field_errors = ''
      this.form.first_name = name
      this.form.code = code
    },
    async validate(action) {
      const isValid = (await this.$refs.validator.validate())
      console.log(isValid)
      if (!isValid) return
      action()
    },
    async prepareData(id) {
      this.non_field_errors = ''
      this.$refs.validator.validate()
      this.$refs.checker.$refs.validator.validate()
      this.form.id_token = id
      this.submit(true, this.form)
    },
    send() {
      return this.beforeSend(SellerRegister)
    },
  },
}

