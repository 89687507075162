import AttributesView from './AttributesView'
import CounterController from './CounterController'
import Counter from './Counter'
import PriceCalculator from './PriceCalculator'

export default function install(Vue) {
  Vue.component('AttributesView', AttributesView)
  Vue.component('CounterController', CounterController)
  Vue.component('Counter', Counter)
  Vue.component('PriceCalculator', PriceCalculator)
}
