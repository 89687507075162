//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'NewUser',
  props: ['fields', 'order'],
  data() {
    return {
      isLoad: false,
      authUser: window.authent,
      city: '',
      form_fields: [
        {
          name: 'first_name',
          cols: '12 6-md 4-fhd',
          label: this._('Ваше имя'),
          rules: 'required|onlyCyrillic',
        },
        {
          name: 'last_name',
          cols: '12 6-md 4-fhd',
          label: this._('Ваш фамилия'),
          rules: 'required|onlyCyrillic',
        },
        {
          name: 'middle_name',
          cols: '12 6-md 4-fhd',
          label: this._('Отчество получателя'),
          rules: 'required|onlyCyrillic',
        },
        {
          name: 'email',
          cols: '12 6-md 4-fhd',
          label: this._('Электронная почта'),
          rules: 'required|email',
        },
        {
          name: 'phone',
          cols: '12 6-md 4-fhd',
          label: this._('Номер телефона'),
          rules: 'required|phone',
          mask: this.defaultPhoneMask,
        },
        // {
        //   name: 'city',
        //   cols: '12 6-md 4-fhd',
        //   label: this._('Введите населенный пункт Украины *'),
        //   tag: 'city-change-element',
        //   event: city => this.$emit('city:change', city),
        // },
      ],
      form: {
        first_name: this.fields.first_name,
        last_name: this.fields.last_name,
        middle_name: this.fields.middle_name,
        email: this.fields.email,
        phone: this.fields.phone,
        register: false,
      },
      offer: window.offer,
      rules: window.rules,
    }
  },

  watch: {
    'order.city': {
      handler(nval) {
        console.log('object', nval);
        this.city = nval
        this.form.city = nval
      },
      deep: true,
    },
    fields: {
      handler() {
        Object.keys(this.form).forEach(key => {
          this.form[key] = this.fields[key]
        })
        this.$emit('update', this.form)
      },
      immediate: false,
      deep: true,
    },
  },

}
