const Sorting = () => import('@app/Filters/Sorting')
const SelectWidget = () => import('@app/Filters/Widgets/SelectWidget')
const MultiselectWidget = () => import('@app/Filters/Widgets/MultiselectWidget')
const CheckboxWidget = () => import('@app/Filters/Widgets/CheckboxWidget')
const DatePickerWidget = () => import('@app/Filters/Widgets/DatePickerWidget')
const DateRangeWidget = () => import('@app/Filters/Widgets/DateRangeWidget')
const CombinedInputWidget = () => import('@app/Filters/Widgets/CombinedInputWidget')
const AutocompleteMultiselectWidget = () => import('@app/Filters/Widgets/AutocompleteMultiselectWidget')
const RangeWithVariantsWidget = () => import('@app/Filters/Widgets/RangeWithVariantsWidget')
const SimpleMultiselectWidget = () => import('@app/Filters/Widgets/SimpleMultiselectWidget')
const InputWidget = () => import('@app/Filters/Widgets/InputWidget')
const SearchWidget = () => import('@app/Filters/Widgets/SearchWidget')

const WIDGETS_MAP = {
  prices: RangeWithVariantsWidget,
  'date-range': DateRangeWidget,
  'date-picker': DatePickerWidget,
  input: InputWidget,
  search: SearchWidget,
  select: SelectWidget,
  checkbox: CheckboxWidget,
  'combined-input': CombinedInputWidget,
  'autocomplete-multiselect': AutocompleteMultiselectWidget,
  'simple-select': MultiselectWidget,
  'simple-multiselect': SimpleMultiselectWidget,
}

export default {
  components: {
    Sorting,
  },
  widgetsMap: WIDGETS_MAP,
  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'prices',
    'pagination',
    'sortlist',
    'show_all',
    'label',
  ],
  data() {
    return {
      search: '',
    }
  },
  computed: {
    parameters() {
      return {
        disabled: this.disabled,
        values: this.value,

      }
    },
    baseFilters() {
      return this.getAvailableFilters(this.filters)
      // .filter(filter => filter.is_base);
    },
    exclusiveFilters() {
      return this.getAvailableFilters(this.filters)
      // .filter(filter => !filter.is_base);
    },
    scopedFilters() {
      return [
        {
          condition: true,
          filters: this.baseFilters,
        },
        {
          condition: this.show_all,
          filters: this.exclusiveFilters,
        },
      ]
    },
  },
  mounted() {
    if (this.$store) {
      this.$store.subscribe(mutation => {
        if ('search/SET_SEARCH' === mutation.type) {
          this.search = mutation.payload
          this.onlyValue('search', this.search)
        }
      })
    }
  },
  methods: {
    async searchValues({ props }, all) {
      console.log(all);
      props.has_search = true
      const { search } = props
      // const { data: { items } } = (await getAttributeValuesResource.execute({ id, search, category: this.label }))
      // console.log(items);
      for (let x = 0; x < all.values.length; x++) {
        const field = all.values[x].props.title.toLowerCase()
        const res = field.includes(search.toLowerCase())
        this.$set(all.values[x], 'is_valid', res)
      }
      // all.values = items
    },
    selectAll(label, all) {
      // eslint-disable-next-line no-prototype-builtins
      this.updateValue(label, all.filter(e => !e.hasOwnProperty('is_valid') || e.is_valid).map(e => e.props.label))
    },
    getMultiValue(key) {
      return {
        min_value: this.value[`${key}_min`],
        max_value: this.value[`${key}_max`],
      }
    },
    getAvailableFilters(filters = []) {
      if (!filters) return []
      // return filters
      return filters.filter(el => !(!el.props.attribute.props.values.length && !el.props.has_search))
      // .filter(filter => filter.type in this.$options.widgetsMap)
    },
    onlyValue(key, value) {
      this.$emit('input', { [key]: value })
    },
    updateValue(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    updateMultipleValues(key, values) {
      let res = {}
      Object.keys(values).forEach(k => {
        res = { ...res, [`${key}_${k}`]: values[k] }
      })
      this.$emit('input', Object.assign(this.value, res))
    },
  },
}
