export const toggleActivity = {
  bind(el, binding, vnode) {
    el.addEventListener('click', () => {
      console.log(el)
      const ref = vnode.context.$refs[binding.value]
      const $s = ref[0]
      if ($s) {
        if ($s.classList.contains('is-active')) {
          $s.classList.remove('is-active')
        } else {
          $s.classList.add('is-active')
        }
      }
    })
  },
}

export const hoverSimulate = {
  bind(el, binding, vnode) {
    el.addEventListener('mouseover', () => {
      const siblings = Array.from(vnode.elm.offsetParent.children)
      siblings.forEach(item => item.firstChild.classList.remove('is-active'))
      if (!binding.value) return
      el.classList.add('is-active')
    })
  },
}
export const hoverSimulateTest = {
  bind(el, { value: { method } }, vnode) {
    el.addEventListener('mouseover', () => {
      const siblings = Array.from(vnode.elm.offsetParent.children)
      siblings.forEach(item => item.getElementsByClassName(vnode.elm.classList[0])[0].classList.remove('is-active'))
      el.classList.add('is-active')
      method()
    })
  },
}

export const toggleMenu = {
  bind(el) {
    el.addEventListener('click', () => {
      const overlay = document.getElementById('menu-overlay')
      const menu = document.getElementById('fat-menu')
      if (el.classList.contains('is-opened')) {
        el.classList.remove('is-opened')
        el.firstChild.classList.remove('is-active')
        menu.classList.remove('is-active')
        overlay.classList.add('is-hidden')
        return
      }
      // eslint-disable-next-line no-underscore-dangle
      window.app.__vue__.getMenu()
      el.classList.add('is-opened')
      el.firstChild.classList.add('is-active')
      menu.classList.add('is-active')
      overlay.classList.remove('is-hidden')
    })
  },
}
const remover = () => {
  const overlay = document.getElementById('menu-overlay')
  const menu = document.getElementById('fat-menu')
  const triggers = Array.from(document.getElementsByClassName('catalog-btn'))
  triggers.forEach(b => {
    b.classList.remove('is-opened')
    b.firstChild.classList.remove('is-active')
  })
  overlay.classList.add('is-hidden')
  menu.classList.remove('is-active')
  menu.classList.remove('is-hovered')
}

export const closeMenu = {
  bind(el) {
    el.addEventListener('mouseenter', () => {
      const menu = document.getElementById('fat-menu')
      if (menu.classList.contains('is-hovered')) {
        remover()
      }
    })
    el.addEventListener('click', () => {
      remover()
    })
  },
}

const ZERO = 0
// const OFFSET = 24
const hoverEffect = async (el, vnode) => {
  if (el.classList.contains('is-active')) return
  // eslint-disable-next-line no-underscore-dangle
  const child = vnode.elm.lastChild.__vue__
  await child.getAttributes()
  if (child.list && child.list.attributes && !child.list.attributes.length) return
  // let hover = el.getElementsByClassName('simple-hover')[0]
  // let body = el.getElementsByClassName('other-info')[0]
  // console.dir(body)
  // hover.style.marginBottom = `-${body.offsetHeight + OFFSET}px`
  // hover.style.height = `-${body.offsetHeight + OFFSET}px`
  el.classList.add('is-active')
}
const antiHoverEffect = (el, vnode) => {
  // eslint-disable-next-line no-underscore-dangle
  const child = vnode.elm.lastChild.__vue__
  child.clearRequest()
  const [hover] = el.getElementsByClassName('simple-hover')
  el.classList.remove('is-active')
  if (hover) hover.style.marginBottom = ZERO
}
const clearAllHovers = () => new Promise(res => {
  const cards = Array.from(document.getElementsByClassName('product-card'))
  cards.forEach(antiHoverEffect)
  res(true)
})

const getLink = el => el.getElementsByClassName('product__link is-active')[0].dataset.href

const goToProductPage = (el, e) => {
  if (
    el.classList.contains('is-active') &&
    e.target.classList.contains('product__link')
  ) {
    getLink(el)
    // window.location.href = getLink(el)
  }
}

// product card

export const cardHover = {
  bind(el, binding, vnode) {
    if (!binding.value) return
    if (
      navigator.maxTouchPoints > ZERO ||
      navigator.msMaxTouchPoints > ZERO
    ) {
      el.addEventListener('click', e => {
        goToProductPage(el, e)
        clearAllHovers().then(() => hoverEffect(el, vnode))
      })
      return
    }
    el.addEventListener('click', e => goToProductPage(el, e))

    el.addEventListener('mouseenter', () => {
      hoverEffect(el, vnode)
    })
    el.addEventListener('mouseleave', () => {
      antiHoverEffect(el, vnode)
    })
  },
}

export const productImgsMiniCards = () => {
  const arr = [...document.getElementsByClassName('product-mini-img')]
  arr.forEach(el => el.classList.remove('is-active'))
}

export const eventHandler = () => {
  const event = navigator.maxTouchPoints > ZERO || navigator.msMaxTouchPoints > ZERO ? 'click' : 'mouseenter'
  return event
}

const imageSourceReplacer = (value, el) => {
  const target = document.getElementById('product-img')
  const [img] = target.getElementsByTagName('img')
  const [source] = target.getElementsByTagName('source')
  img.src = value.origin
  img.alt = el.dataset.alt
  source.srcset = value.webp
  productImgsMiniCards()
  el.classList.add('is-active')
}

export const productImage = {
  bind(el, binding) {
    const event = eventHandler()
    el.addEventListener(event, () => {
      imageSourceReplacer(binding.value, el)
    })
  },
}

export default {
  cardHover,
  toggleActivity,
  toggleMenu,
  closeMenu,
  hoverSimulate,
  hoverSimulateTest,
  productImage,
}
