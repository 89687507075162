//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FakeInput',
  props: {
    tag: {
      default: 'd-control-input',
    },
    label: {},
    model: {},
    valueFormatter: {
    },
    showAfter: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      show: false,
      value: this.model,
    }
  },
  watch: {
    model() {
      this.value = this.model
    },
  },
  methods: {
    focus() {
      if (this.disabled) return
      this.show = true
      this.$nextTick(() => {
        const parent = this.$refs.visibleinput
        let [input] = parent.$children
        input = input.$children[0].$children[0].$children[0].$children[0].$refs.input

        if (input.focus) {
          input.focus()
          return
        }
        input.$el.focus()
      })
    },
    blur() {
      console.log('test');

      this.show = false
      console.log('blur', this.value);
      this.$emit('input', this.value)
    },
  },
}
