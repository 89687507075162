import {
  productPjaxResource,
} from '@api/pjax.service'

const SAVE_CONTENT = 'SAVE_CONTENT'

// const EXCLUDED_LABEL = 'index'

export function createStore() {
  return {
    namespaced: true,
    state: {
      result: '',
      load: false,
    },
    getters: {
      getResult: state => state.result,
    },
    actions: {
      async getContent({ commit }, payload) {
        this.load = true
        try {
          const data = (await productPjaxResource.execute(payload))
          commit(SAVE_CONTENT, data)
          this.load = false
          setTimeout(() => {
            // very bag. hot fix. haven`t time(((
            const input = document.getElementById('js-product-title')
            console.log(input);
            // if (EXCLUDED_LABEL === data.label) {
            //   if (input.firstChild) {
            //     input.innerHTML = ''
            //   }
            //   return
            // }
            if (input.firstChild) {
              input.firstChild.innerText = data.title
              return
            }
            const t = document.createElement('h1')
            t.className = 'product-title'
            t.innerText = data.title
            input.appendChild(t)
          }, 0)
        } catch (e) {
          console.log(e);
          this.load = false
        }
      },
    },
    mutations: {
      [SAVE_CONTENT](state, payload) {
        state.result = payload
      },
    },
  }
}
