//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  getSellerPaymentGateways,
} from '@api/order.service'

import CodeChecker from '@app/Auth/Seller/CodeChecker'

const CASH_COMPANY = 'cash_company'

export default {
  name: 'OrderPayment',
  props: ['fields'],
  keyGetter: x => `${x.type}:${x.id}`,
  CASH_COMPANY,
  components: {
    CodeChecker,
  },
  data() {
    return {
      variants: [],
      info: {
        code: this._('ИНН/ЕДРПОУ'),
        name: this._('Название'),
        status: this._('Статус'),
        type: this._('Тип'),
      },
      form: {
        payment_gateway: '',
        opendatabot_info: {},
      },
    }
  },
  created() {
    this.getPaymentsVariants()
  },
  methods: {
    async getPaymentsVariants() {
      const { data: { items } } = (await getSellerPaymentGateways.execute({ id: this.fields.shop }))
      this.variants = items
      const [payment] = this.variants
      this.form.payment_gateway = payment
      this.$nextTick(() => {
        this.$refs[`payment_${this.form.payment_gateway.id}`][0].checked = true
        this.$emit('update', this.form)
      })
    },
    completeData(info) {
      this.non_field_errors = ''
      this.form.opendatabot_info = info
      this.$emit('update', this.form)
    },
  },
}
