import { documentOffsetTop } from './utils'

const ACTIVE_ICON = 'mdi-chevron-up'
const PASSIVE_ICON = 'mdi-chevron-down'
const INITIAL_HEIGHT = 0
const FIRST_ELEMENT = 1

export default {
  name: 'vue-accordion',
  props: {
    active: {
      default: () => false,
    },
    collapsable: {
      default: () => false,
    },
    id: { },
    fullHeight: {
      default: () => false,
    },
    activeIcon: {
      default: () => ACTIVE_ICON,
    },
    passiveIcon: {
      default: () => PASSIVE_ICON,
    },
    initialHeight: {
      default: () => INITIAL_HEIGHT,
    },
    options: {
      default: () => [],
    },
  },
  data() {
    return {
      isDesktop: false,
      activeState: this.active,
      windWidth: 1200,
      contentHeight: this.initialHeight,
    }
  },
  computed: {
    style() {
      return {
        icon: this.activeState ? this.activeIcon : this.passiveIcon,
        height: this.activeState ?
          this.stylesGenerator(this.contentHeight) : this.stylesPxGenerator(this.initialHeight),
      }
    },
  },
  watch: {
    options: {
      handler() {
        for (let x = 0; x < 1000; x += 300) {
          setTimeout(() => {
            this.calcHeight()
          }, x)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.checkWidth()
    this.calcHeight()
    this.checkUrl()
  },
  methods: {
    stylesPxGenerator(val) {
      return `${val}px`
    },
    stylesGenerator(val) {
      if (this.fullHeight) {
        return 'initial'
      }
      return `${val}px`
    },
    calcHeight() {
      this.$nextTick(() => {
        const el = this.$el.firstChild.lastChild
        if (el) this.contentHeight = el.scrollHeight
      })
    },
    toggleActive() {
      this.activeState = !this.activeState
      this.calcHeight()
    },
    checkUrl() {
      let { hash } = window.location
      if (hash) {
        hash = hash.split('#')[FIRST_ELEMENT]
        if (hash === this.id) {
          this.activeState = true
        }
      }
    },
    async scrolly(time) {
      setTimeout(async () => {
        const offset = 90
        const top = documentOffsetTop(document.getElementById(this.id), this.initialHeight) - offset
        window.scrollTo({ top, behavior: 'smooth' })
      }, time)
    },
    checkWidth() {
      if (window.innerWidth > this.windWidth) {
        this.isDesktop = true
      }
    },
  },
  render() {
    return this.$scopedSlots.default({
      style: this.style,
      activeState: this.activeState,
      toggleActive: this.toggleActive,
      isEmpty: Number(this.contentHeight) <= Number(this.initialHeight),
      isDesktop: this.isDesktop,
      collapse: () => {
        this.activeState = false
      },
    })
  },
}
