import {
  createReceiver,
  createUpdaterWithFile,
  createUpdater,
  createRemover,
  createSender,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'shops'
const p = postfix => MODEL + postfix
const MODEL_DOCUMENTS = p('/documents')
const MODEL_DOCUMENTS_HUB = p('/documents-hub')

export const SHOP_RETRIVE_URL = prefixAPI('/retrieve/', MODEL)
export const SHOP_UPDATE_URL = prefixAPI('/update/', MODEL)

export const SHOP_POSITIONS_LIST_URL = prefixAPI('/positions/list/', MODEL)

export const DOCUMENTS_CREATE_URL = prefixAPI('/create/', MODEL_DOCUMENTS)
export const DOCUMENTS_LIST_URL = prefixAPI('/list/', MODEL_DOCUMENTS)
export const DOCUMENTS_DELETE_URL = prefixAPI('/files/delete/{id}/', MODEL_DOCUMENTS)
export const DOCUMENTS_UPDATE_URL = prefixAPI('/update/{id}/', MODEL_DOCUMENTS)
export const DOCUMENTS_DESCRIPTION_URL = prefixAPI('/descriptions/', MODEL_DOCUMENTS)

export const SEND_SHOP_TO_VERIFICATION_URL = prefixAPI('/verification-request/', MODEL)
export const DUMMY_URL = prefixAPI('/dummy/', MODEL)

export const DOCUMENTS_HUB_LIST_URL = prefixAPI('/list/{?title,offset,limit}', MODEL_DOCUMENTS_HUB)
export const DOCUMENT_SEND_TO_VCHASNO_URL = prefixAPI('/actions/send-to-vchasno/{id}/', MODEL_DOCUMENTS_HUB)

export const SHOP_STATUSES_HISTORY_URL = prefixAPI('/status/history/list/', MODEL)

// export const SHOP_CA_URL = prefixAPI('/status/history/list/', MODEL)

export const ShopRetrieve = createReceiver(SHOP_RETRIVE_URL)
export const ShopPositionsList = createReceiver(SHOP_POSITIONS_LIST_URL)
export const ShopUpdate = createUpdaterWithFile(SHOP_UPDATE_URL)

export const DocumentCreate = createSender(DOCUMENTS_CREATE_URL)
export const DummyResource = createReceiver(DUMMY_URL)
export const DocumentList = createReceiver(DOCUMENTS_LIST_URL)
export const DocumentDelete = createRemover(DOCUMENTS_DELETE_URL)
export const DocumentUpdate = createUpdater(DOCUMENTS_UPDATE_URL)
export const DocumentsDescriptions = createReceiver(DOCUMENTS_DESCRIPTION_URL)

export const SendShopToVerification = createUpdater(SEND_SHOP_TO_VERIFICATION_URL)

export const DocumentsHubList = createReceiver(DOCUMENTS_HUB_LIST_URL)
export const StatusesHistoryList = createReceiver(SHOP_STATUSES_HISTORY_URL)

export const SendDocumentToVchasno = createSender(DOCUMENT_SEND_TO_VCHASNO_URL)
