//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: ['preview', 'promise', 'ratio', 'rate'],
  data() {
    return {
      imgURL: this.preview,
      rotate: 0,
      scale: 1,
    }
  },
  watch: {
    scale(val) {
      this.$refs.clipper.setWH$.next(val)
    },
  },
  mounted() {
    console.log(this.$refs.clipper);
    this.$refs.clipper.onChange$.subscribe(() => {
      // This happens whenever zooming, moving and rotating occur.
    })
  },
  methods: {
    crop() {
      console.log(this.$refs.clipper)
      const canvas = this.$refs.clipper.clip()
      const dataURL = canvas.toDataURL()
      const blobBin = atob(dataURL.split(',')[1])
      const array = []
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i))
      }
      const file = new Blob([new Uint8Array(array)], { type: 'image/png' })
      const newFile = new File([file], 'name.png', { type: 'image/png' })
      console.dir(newFile)
      this.promise(newFile)
      this.$emit('close', newFile)
    },
  },
}
