import {
  route,
} from '@app/Routing/helpers'

const UserInfo = () => import('@app/Buyer/User/Info')
const OrderList = () => import('@app/Buyer/Orders/List')
const CartView = () => import('@app/Buyer/Cart/View')
const ChatsList = () => import('@app/Buyer/Chats/List')
const DashboardView = () => import('@app/Buyer/Dashboard/View')
const FeedbackList = () => import('@app/Buyer/Feedback/List')
const Wishlist = () => import('@app/Buyer/Wishlist/List')
const Waitinglist = () => import('@app/Buyer/Waitinglist/List')

const USER_DATA = 'personal/'
const ORDER_LIST = 'orders/'
const CART_LIST = 'cart/'
const CHATS_LIST = 'chats/'
const DASHBOARD_VIEW = 'dashboard/'
const FEEDBACK_LIST_VIEW = 'feedback/'
const WISHLIST_LIST = 'wishlist/'
export const WAITINGLIST_LIST = 'waiting-list/'

export const USER_PERSONAL_NAME = 'user:personal'
export const ORDERS_LIST_NAME = 'user:orders'
export const CART_LIST_NAME = 'user:cart'
export const CHATS_LIST_NAME = 'user:chats'
export const DASHBOARD_VIEW_NAME = 'user:dashboard'
export const FEEDBACK_VIEW_NAME = 'user:feedback'
export const WISHLIST_LIST_NAME = 'user:wishlist'
export const WAITINGLIST_LIST_NAME = 'user:waitinglist'

export function createRoutes(options) {
  return [
    route(ORDER_LIST, OrderList, ORDERS_LIST_NAME, {
      meta: {
        hideVisitCard: true,
      },
    }),
    route(USER_DATA, UserInfo, USER_PERSONAL_NAME, options),
    route(CART_LIST, CartView, CART_LIST_NAME, {
      meta: {
        hideVisitCard: true,
      },
    }),
    route(CHATS_LIST, ChatsList, CHATS_LIST_NAME, {
      meta: {
        hideVisitCard: true,
        tag: 'wds-chat-launcher',
      },
    }),
    route(DASHBOARD_VIEW, DashboardView, DASHBOARD_VIEW_NAME, {
      meta: {
        hideVisitCard: true,
      },
    }),
    route(FEEDBACK_LIST_VIEW, FeedbackList, FEEDBACK_VIEW_NAME, {
      meta: {
        hideVisitCard: true,
      },
    }),
    route(WISHLIST_LIST, Wishlist, WISHLIST_LIST_NAME, {
      meta: {
        hideVisitCard: true,
      },
    }),
    route(WAITINGLIST_LIST, Waitinglist, WAITINGLIST_LIST_NAME, {
      meta: {
        hideVisitCard: true,
      },
    }),
    { path: '', redirect: DASHBOARD_VIEW },
  ]
}
