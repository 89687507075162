//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: 'LightBox',
  props: {
    source: {
      type: Array,
    },
  },
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      visible: false,
      img: [],
      idx: 0,
    }
  },
  methods: {
    showImg(idx) {
      this.idx = idx
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
  },
}
