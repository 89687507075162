//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect'

const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

export default {
  name: 'MultiSelect',
  components: { Multiselect },
  computed: {
    eSlots() {
      return getESlots(Object.keys(this.$scopedSlots))
    },
  },
}
