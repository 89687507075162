import { accessGuard } from '@aspectus/vue-router-permissions';
import { createRoutes as createCabinetForBuyer } from '@app/Buyer/router'
// import { createRoutes as createCabinetForSeller } from '@app/Seller/router'
import { prefixRoutes } from '@app/Routing/helpers';
import { prefixLanguage } from '@utils/urls';

// options should be here
const BASE_USER_CABINET = '/cabinet/'
// const BASE_SELLER_CABINET = '/'

const ROUTER_MAP = {
  // seller: options => prefixRoutes(prefixLanguage(BASE_SELLER_CABINET), createCabinetForSeller(options)),
  buyer: options => prefixRoutes(prefixLanguage(BASE_USER_CABINET), createCabinetForBuyer(options)),
}

export function createRoutes(options) {
  return [
    ROUTER_MAP[window.current_host](options),
  ];
}

export function scrollBehavior(to, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }

  return {
    x: window.pageXOffset,
    y: window.pageYOffset,
  };
}

export function createRouter(options = {}) {
  const { Router, Vue } = options;
  const router = new Router({
    mode: 'history',
    base: '/',
    scrollBehavior,
    routes: createRoutes({ Router, Vue }),
  });

  router.beforeEach(accessGuard(options));

  return router;
}
