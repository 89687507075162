//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductBaseView from './Variants/Base'
import ProductSetView from './Variants/Set'

export default {
  name: 'CartProductUi',
  components: {
    ProductBaseView,
    ProductSetView,
  },
  props: {
    product: {
      default: () => { },
    },
    load: {},
  },

  computed: {
    isSet() {
      return this.product.base.element.props.set
    },
    productProps() {
      const { relations, base, price } = this.product
      return {
        load: this.load,
        product: base,
        relations,
        price,
        disabled: this.$attrs.disabled,
        canRemove: this.$attrs.canRemove,
      }
    },
  },
}
