import VueSelectionController from '@aspectus/vue-selection-controller'
import OrderView from './View'

export function install(Vue) {
  Vue.component(OrderView.name, OrderView)
  Vue.use(VueSelectionController, {
    name: 'v-selection-controller',
  })
}

export default { install }
