//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UiMixin from '@app/Filters/mixins/UiMixin';

const ATTRIBUTES_FOR_SEARCH_COUNT = 20

export default {
  mixins: [UiMixin],
  props: [
    'isDesktop',
    'disabled',
    'value',
    'filters',
    'available',
    'prices',
    'pagination',
  ],
  ATTRIBUTES_FOR_SEARCH_COUNT,
  data() {
    return {
      search: '',
      search_filter: {
        label: 'search',
        title: this._('Поиск'),
        id: 'search_id',
      },
    };
  },
  methods: {
  },
};
