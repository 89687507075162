import {
  // createReceiver,
  createSender,
} from '@resource/resource'

import {
  prefixAPI,
} from '@resource/api'

const MODEL = 'authentication'
const OPEN_DATA = 'opendatabot'

// MODEL
const USER_REGISTER_URL = prefixAPI('/register/', MODEL)
const USER_LOGIN_URL = prefixAPI('/login/', MODEL)
const USER_LOGOUT_URL = prefixAPI('/logout/', MODEL)
const USER_RECOVERY_URL = prefixAPI('/password/reset/request/', MODEL)
const USER_ACTIVATE_URL = prefixAPI('/activate/', MODEL)
const USER_SEND_CODE_URL = prefixAPI('/send-activation-code/', MODEL)
const SELLER_REGISTER_URL = prefixAPI('/register-seller/', MODEL)
const PASSWORD_CHANGE_URL = prefixAPI('/password/change/', MODEL)
const PASSWORD_CONFIRM_URL = prefixAPI('/password/reset/confirm/', MODEL)
// OPEN DATA
const CHECK_CODE_URL = prefixAPI('/check-code/', OPEN_DATA)

const TWO_FACTOR_REQUEST = prefixAPI('/request-confirmation/', '2factor')
const TWO_FACTOR_REQUEST_CONFIRM = prefixAPI('/confirm/', '2factor')

// MODEL
export const UserRegister = createSender(USER_REGISTER_URL)
export const UserLogin = createSender(USER_LOGIN_URL)
export const UserLogout = createSender(USER_LOGOUT_URL)
export const UserRecovery = createSender(USER_RECOVERY_URL)
export const UserActivate = createSender(USER_ACTIVATE_URL)
export const ResendCode = createSender(USER_SEND_CODE_URL)
export const ChangePassword = createSender(PASSWORD_CHANGE_URL)
export const ConfirmPassword = createSender(PASSWORD_CONFIRM_URL)
export const SellerRegister = createSender(SELLER_REGISTER_URL)
// OPEN DATA
export const CheckCode = createSender(CHECK_CODE_URL)

export const make2FactorRequest = createSender(TWO_FACTOR_REQUEST)
export const confirm2FactorRequest = createSender(TWO_FACTOR_REQUEST_CONFIRM)
