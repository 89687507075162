//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'YoutubeLoader',
  data() {
    return {
      link: '',
    }
  },
  methods: {
    send(valid) {
      this.$refs.video_validator.validate()
      if (!valid) return
      this.$emit('update', this.link)
      this.link = ''
    },
  },
}
