

export default {
  name: 'ModalTrigger',
  props: {
    url: {
      type: String,
      default: () => null,
    },
    name: {
      type: String,
    },
    classes: {
      // type: Array,
      default: () => [],
    },
  },
  methods: {
    openPopup() {
      this.$modal.show(this.componentLoader,
        {
          info: this.$attrs,
        }, {
          height: 'auto',
          name: this.name,
          minWidth: 300,
          classes: this.classes.concat(['defaultModal']),
          adaptive: true,
          scrollable: true,
        })
    },
  },
  computed: {
    componentLoader() {
      return () => import(`@app/${this.url}`)
    },
  },
  render() {
    return this.$scopedSlots.default({
      open: e => {
        this.openPopup(e)
      },
    })
  },
}
