import {
  ShopRetrieve,
} from '@api/seller.service'
import { Shop } from './base/shop'

const SET_SHOP_INFORMATION = 'SET_SHOP_INFO'
const SET_PART_OF_SHOP_INFORMATION = 'SET_PART_OF_SHOP_INFO'
const SET_SPA_SUBTITLE = 'SET_SPA_SUBTITLE'

const GET_SHOP_INFO = 'getShopInfo'
const UPDATE_USER_INFO = 'updateUserInfo'
const SET_SPA_SUBTITLE_ACTION = 'setSPASubtitle'

export function createStore({ ShopClass = Shop }) {
  return {
    namespaced: true,
    state: {
      shop: new ShopClass({}, false),
      spa_subtitle: '',
    },
    getters: {
      isAuthenticated: state => state.shop.isAuthenticated(),
      isDetermined: state => state.shop.determined,
      status: state => state.shop.info && state.shop.info.props && state.shop.info.props.status,
      id: state => state.shop.info && state.shop.info.id,
    },
    actions: {
      async [GET_SHOP_INFO]({ commit }) {
        return ShopRetrieve.execute().then(({ data }) => {
          commit(SET_SHOP_INFORMATION, data)
        })
      },
      [UPDATE_USER_INFO]({ commit }, payload) {
        commit(SET_PART_OF_SHOP_INFORMATION, payload)
      },
      [SET_SPA_SUBTITLE_ACTION]({ commit }, payload) {
        commit(SET_SPA_SUBTITLE, payload)
      },
    },
    mutations: {
      [SET_SHOP_INFORMATION](state, payload) {
        state.shop = new ShopClass(payload)
        if (payload.props?.authorized_person && payload.props?.private_metadata?.opendatabot) {
          clearInterval(window.pingShopInfo)
        }
      },
      [SET_PART_OF_SHOP_INFORMATION](state, payload) {
        // TODO: use ShopClass
        state.user.props[payload.key] = payload.value
      },
      [SET_SPA_SUBTITLE](state, payload) {
        state.spa_subtitle = payload
      },
    },
  }
}
