import * as rules from 'vee-validate/dist/rules'
import trans from '@aspectus/vee-i18n'

import VueTrans, { compileMessages } from '@aspectus/vue-trans'

const NUMERIC = 'numeric';
const LONG = 'long';
const DATE_CONFIG = {
  year: NUMERIC,
  month: NUMERIC,
  day: NUMERIC,
};
const SHORT_DATE_CONFIG = {
  ...DATE_CONFIG,
  year: '2-digit',
};
const TIME_CONFIG = {
  hour: NUMERIC,
  minute: NUMERIC,
};
const DATETIME_CONFIG = {
  ...DATE_CONFIG,
  ...TIME_CONFIG,
};

const dateTimeFormats = {
  [window.language]: {
    shortDate: SHORT_DATE_CONFIG,
    date: DATE_CONFIG,
    time: TIME_CONFIG,
    month: { year: NUMERIC, month: LONG },
    datetime: DATETIME_CONFIG,
  },
};

const SHORT = { notation: 'compact', compactDisplay: 'short' };
const DECIMAL = { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }
const SHORT_UNIT = { ...SHORT, style: 'unit' };
const u = unit => ({ ...SHORT_UNIT, unit });

const numberFormats = {
  [window.language]: {
    liter: u('liter'),
    short: SHORT,
    kilogram: u('kilogram'),
    degree: u('degree'),
    decimal: DECIMAL,
  },
}
// source https://kazupon.github.io/vue-i18n/guide/pluralization.html#custom-pluralization
const pluralizationRules = {
  [window.language]: (choice, choicesLength) => {
    console.log(choice, choicesLength);
    if (0 === choice) {
      return 0;
    }

    const teen = 10 < choice && 20 > choice;
    const endsWithOne = 1 === choice % 10;

    if (4 > choicesLength) {
      return (!teen && endsWithOne) ? 1 : 2;
    }
    if (!teen && endsWithOne) {
      return 1;
    }
    if (!teen && 2 <= choice % 10 && 4 >= choice % 10) {
      return 2;
    }

    return (4 > choicesLength) ? 2 : 3;
  },
}

const { locale, messages } = compileMessages()

const messagesWithPlural = {
  ru: {
    ...messages.ru,
    товар: 'товаров | товар | товара | товаров',
    сообщение: 'сообщений | сообщение | сообщения | сообщений',
    заказ: 'заказов | заказ | заказа | заказов',
  },
  uk: {
    ...messages.uk,
    товар: 'товарiв | товар | товари | товарiв',
    сообщение: 'повідомлень | повідомлення | повідомлення | повідомлень',
    заказ: 'замовленнь | замовлення | замовлення | замовленнь',
  },
}

export function createI18n({ Vue, I18n }) {
  Vue.use(VueTrans)
  const i18n = new I18n({
    messages: messagesWithPlural,
    locale,
    dateTimeFormats,
    numberFormats,
    silentTranslationWarn: true,
    pluralizationRules,
  })

  trans(i18n, rules)

  return i18n
}
