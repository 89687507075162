//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  AddChat,
} from '@api/chat.service'

import {
  createMessage,
} from '@api/seller/chat.service'

import {
  prefixLanguage,
} from '@utils/urls'

const BUYER_TYPE = 'buyer'
const BUYER_CHAT_URL = '/cabinet/chats/'

const SELLER_CHAT_URLS_MAP = {
  product: '/chat/products/',
  order: '/chat/orders/',
  shop: '/chat/questions/',
}

const redirectUrlGenerator = type => {
  if (window.userType === BUYER_TYPE) {
    return prefixLanguage(BUYER_CHAT_URL)
  }
  return prefixLanguage(SELLER_CHAT_URLS_MAP[type])
}

export default {
  name: 'AddChat',
  props: ['info'],
  AddChat,
  createMessage,
  data() {
    return {
      btnText: this._('Отправить сообщение'),
      form: {
        type: this.info.type,
        shop: this.info.subject_id,
        element: {
          type: this.info.element_type,
          id: this.info.element_id,
        },
        message: '',
      },
      fields: [
        {
          name: 'message',
          tag: 'd-control-textarea',
          cols: '12',
          title: this._('Ваше сообщение'),
          rules: 'required|min:5',
          props: {
            class: 'is-textarea',
            rows: 8,
          },
        },
      ],
    }
  },

  methods: {
    afterSend({ data: { id, props: { type } } }) {
      const url = `${redirectUrlGenerator(type)}?room=${id}`

      this.$emit('close')
      window.location.href = url
    },
    dataFormatter(init) {
      const data = JSON.parse(JSON.stringify(init))
      if ('None' !== this.info.chat) {
        data.chat = this.info.chat
        data.text = init.message
        return data
      }
      data.message = {
        text: init.message,
      }
      return data
    },
  },
}
