
import {
  make2FactorRequest,
} from '@api/auth.service'

import {
  AnswerMessage,
} from '@utils/submit'

export default {
  name: 'TokenConfirmationCheck',
  props: {
    token: {
      default: '+380507342777',
    },
    action: {
      type: Function,
    },
    additionalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      is_confirmed: false,
      code: '',
    }
  },
  methods: {
    async sendCode() {
      try {
        const { id } = (await make2FactorRequest.execute({}, {
          token: this.token,
          ...this.additionalData,
        }))
        this.openModal(id)
      } catch (e) {
        e.json().then(body => {
          const errors = {}

          body.errors.forEach(error => {
            if ('request' === error.domain && error.state) {
              Object.assign(errors, error.state)
            }
          })
          console.log(errors);
          const [{ message }] = errors.token || errors.non_field_errors
          const meta = {
            messages: [{
              title: this._('Token validation error header'),
              text: message,
            }],
            timeout: 5000,
          }
          AnswerMessage(meta, this)
        })
      }
    },
    openModal(id) {
      this.$modal.show(
        () => import('@app/2Factor/TokenConfirmation'),
        {
          token: this.token,
          id,
          action: this.action,
          additionalData: this.additionalData,
        }, {
          height: 'auto',
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
  render() {
    return this.$scopedSlots.default({
      is_confirmed: this.is_confirmed,
      sendCode: force => this.sendCode(force),
    })
  },
}
