//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  pricesResource,
} from '@api/catalog.service'

export default {
  props: [
    'space',
    'filters',
    'pagination',
    'attributesResource',
    'availableAttributesResource',
    'label',
    'sortlist',
    'items',
    'filterComponent',
  ],
  pricesResource,
  methods: {
    changeFilters(value) {
      const params = Object.assign(value, { label: this.label })
      this.$emit('update:filters', params)
    },
  },
}

