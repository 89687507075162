//
//
//

import { mapActions } from 'vuex'

export default {
  name: 'menu-getter',
  // mounted() {
  //   this.getMenu()
  // },
  methods: {
    ...mapActions('menu', [
      'getMenu',
    ]),
  },
}
