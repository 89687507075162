//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'paginated-resource-controller',

  props: {
    resource: {},
    resourceUrl: {},
    parameters: Object,
    changeParameters: {
      type: Function,
      default: () => {},
    },
    loaderController: {
      default: 'resource-loader-controller',
    },
    paginationController: {
      default: 'pagination-controller',
    },
  },
};

