//
//
//
//
//
//
//

export default {
  name: 'ErrorComponent',
  block: 'error-label',
  props: {
    classes: {},
  },

}
