import ComparisonPreview from '@app/Comparison/Preview'

import AddToComparison from '@app/Comparison/Add'
import ComparisonList from '@app/Comparison/List'
import ComparisonView from '@app/Comparison/View'
import ProductView from '@app/Comparison/ProductView'

export default function plugin(Vue) {
  Vue.component(ComparisonPreview.name, ComparisonPreview)
  Vue.component('AddToComparison', AddToComparison)
  Vue.component(ComparisonList.name, ComparisonList)
  Vue.component(ComparisonView.name, ComparisonView)
  Vue.component(ProductView.name, ProductView)
}
