//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import Controller from './Controller'

export default {
  name: 'SearchView',
  props: ['className', 'fieldId', 'url'],
  components: {
    Controller,
  },
  data() {
    return {
      show: true,
      search: '',
      label: this._('Я шукаю'),
    }
  },
  watch: {
    global_search(nval) {
      this.search = nval
    },
  },
  computed: {
    ...mapState('search', [
      'global_search',
    ]),
  },

  mounted() {
    // this.$store.subscribe(mutation => {
    //   if ('search/SET_SEARCH' === mutation.type) {
    //     this.search = mutation.payload
    //   }
    // })
  },
  methods: {
    focus() {
      this.show = false
      this.$nextTick(() => {
        const parent = this.$refs.visibleinput
        let [input] = parent.$children
        console.dir(input);
        input = input.$children[0].$children[0].$children[0].$children[0].$refs.input

        if (input.focus) {
          input.focus()
          return
        }
        input.$el.focus()
      })
    },
  },
}
