var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoad)?_c('ui-loader'):_vm._e(),_c('div',{staticClass:"g-row"},_vm._l((_vm.form_fields),function(ref){
var rules = ref.rules;
var name = ref.name;
var label = ref.label;
var cols = ref.cols;
var tag = ref.tag;
var event = ref.event;
var mask = ref.mask;
return _c('cell',{key:name,staticClass:"vb-space_mt vb-space_mt--6",attrs:{"cols":cols}},[_c('tag',{directives:[{name:"mask",rawName:"v-mask",value:(mask),expression:"mask"}],ref:name,refInFor:true,attrs:{"rules":rules,"input-label":label,"has-label":true,"tag":tag || "d-control-input","name":name,"currentCity":_vm.order.city},on:{"input":function($event){_vm.$emit("update", _vm.form); event && event($event)},"save":function($event){return event($event)}},model:{value:(_vm.form[name]),callback:function ($$v) {_vm.$set(_vm.form, name, $$v)},expression:"form[name]"}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }