import {
  OrderStatistic,
} from '@api/seller/dashboard.service'

import {
  ImportStatistic,
} from '@api/import.service'

const SET_ORDERS_COUNTS = 'SET_ORDERS_COUNTS'
const SET_IMPORT_COUNTS = 'SET_IMPORT_COUNTS'
const SET_COUNT = 'SET_COUNT'

const NEW_ORDER = 'new'

export function createStore() {
  return {
    namespaced: true,
    state: {
      count: 0,
      orders: [],
      import: {},
    },
    getters: {
      getCount: state => state.count,
      getNewOrdersCount: state => state.orders.find(el => el.title === NEW_ORDER),
      getImportProductCountWithErrors: state => state.import.invalid_products_count,
      getImportProductCountForCreate: state => state.import.valid_without_products_count,
    },
    actions: {
      async getOrdersStatistics({ commit }) {
        const { data } = (await OrderStatistic.execute())
        commit(SET_ORDERS_COUNTS, data)
      },
      async getImportStatistics({ commit }) {
        const { data } = (await ImportStatistic.execute())
        commit(SET_IMPORT_COUNTS, data)
      },
    },
    mutations: {
      [SET_IMPORT_COUNTS](state, payload) {
        state.import = payload
      },
      [SET_COUNT](state) {
        state.count = state.orders.length
      },
      [SET_ORDERS_COUNTS](state, payload) {
        state.orders = payload
      },
    },
  }
}
