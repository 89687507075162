//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapActions,
} from 'vuex'

import {
  getComparisonCategories,
  getComparisonList,
  removeFromComparison,
  clearCategoryComparison,
} from '@api/comparison.service'

import {
  confirmWithPromise,
} from '@utils/helpers'

export default {
  name: 'ComparisonList',
  props: [
    'baseUrl',
  ],
  data() {
    return {
      list: {
        items: [],
        pagination: {},
      },
      loading: false,
    }
  },
  auth: window.authent,
  created() {
    this.getList()
  },
  methods: {
    ...mapActions('comparison', [
      'getComparisonList',
    ]),
    async clearByCategory(id) {
      const question = this._('Удалить категорию из сравнения?')
      await confirmWithPromise(question, this)
      await clearCategoryComparison.execute({ id })
      this.getList()
      this.getComparisonList()
    },
    async handlePaginate({ offset }, category, index) {
      if (this.loading) {
        return
      }
      await this.getCurrentList(category, index, offset)
    },
    async getList() {
      const { data } = (await getComparisonCategories.execute())
      this.list = data
      this.list.items.forEach(async ({ id }, i) => {
        await this.getCurrentList(id, i, 0)
      })
    },
    async rm(id, category, i) {
      const question = this._('Удалить товар из сравнение?')
      await confirmWithPromise(question, this)
      await removeFromComparison.execute({ id })
      this.getList(category, i, 0)
      this.getComparisonList()
    },
    async getCurrentList(category, idx, offset) {
      this.loading = true
      const { data: children } = (await getComparisonList.execute({
        category,
        offset,
        limit: 8,
      }))
      this.$set(this.list.items[idx], 'children', children)
      this.loading = false
    },
  },
}
