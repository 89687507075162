//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  UserLogin,
} from '@api/auth.service'

import LoginMixin from '@app/Auth/Login.mixin.js'

export default {
  name: 'OrderLogin',
  mixins: [LoginMixin],
  methods: {
    send(data) {
      console.log('login!!!!!!!!!');
      return UserLogin.execute({}, data)
        .then(() => {
          window.location.reload()
        })
    },
  },
}
