//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import CatalogSidebar from './Sidebar'
import CatalogContent from './Content'

export default {
  components: { CatalogSidebar, CatalogContent },
  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'availableAttributesResource',
    'label',
    'sortlist',
    'filterComponent',
    'initial',
  ],

  created() {
    // this.search()
  },

  methods: {
    search() {
      this.receive(this.parameters)
    },
  },
}

