//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapState,
  mapActions,
} from 'vuex'

export default {
  name: 'UserAccountActions',
  props: ['userLink', 'sellerLinkAuth', 'sellerLinkRegister'],
  computed: {
    ...mapState('staff', [
      'user',
      'oppositeAccountExists',
    ]),
  },
  created() {
    this.getUserInfo()
    this.getOppositeAccount()
  },
  methods: {
    ...mapActions('staff', [
      'updateUserInfo',
      'getUserInfo',
      'getOppositeAccount',
    ]),
  },

}
