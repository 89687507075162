
import { mapGetters } from 'vuex'

export default {
  name: 'MegaMenuSafe',
  data() {
    return {
      list: {},
      menu: new Map(),
    }
  },
  computed: {
    ...mapGetters('menu', [
      'all',
    ]),
  },
  methods: {
    addList(key, list, level, parent) {
      const { length } = Object.keys(this.list)
      for (let x = length; x >= +level; x--) {
        delete this.list[x]
      }
      this.$set(this.list, key, {})
      this.$set(this.list[key], 'list', this.all.get(parent))
      this.$set(this.list[key], 'level', level)
      this.$set(this.list[key], 'parent', parent)
      this.menu.set(parent, list)
    },
  },
  render() {
    return this.$scopedSlots.default({
      list: this.list,
      add: (key, list, level, parent) => this.addList(key, list, level, parent),
      menu: this.all,
    })
  },
}
