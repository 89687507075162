import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import Vuex from 'vuex';
import Tag from '@aspectus/vue-tag'
import DermisPlugin from '@aspectus/vue-dermis';

import VueMultiselect from 'vue-multiselect';
import VueObserveVisibility from 'vue-observe-visibility';
import VModal from 'vue-js-modal';
import * as VueGoogleMaps from 'vue2-google-maps'
import VueCountdown from '@chenfengyuan/vue-countdown'

import UiClickOutside from '@aspectus/vue-clickaway'
import VueBem from '@aspectus/vue-bem'
import VueGrid from '@aspectus/vue-grid'
import VueAccordion from '@aspectus/vue-accordion'

import VueAllSelectionController from '@aspectus/vue-all-selection-controller'

import Components from '@app'
import ResourceComponents from '@resource'

import filters from '@utils/filters'
import AuthProvider from '@access/AuthProvider'
import MetaCtrlEnter from 'meta-ctrl-enter';
import PortalVue from 'portal-vue'

import Notifications from 'vue-notification'

import { mask } from 'vue-the-mask'

const updatedMask = {
  bind(el, binding) {
    if (!binding.value) return
    mask(el, binding)
  },
}

export function install(Vue) {
  Vue.use(PortalVue)
  Vue.use(MetaCtrlEnter);
  Vue.use(VueRouter);
  Vue.use(VueI18n);
  Vue.use(Vuex);
  Vue.use(Tag);
  Vue.use(VueGrid);
  Vue.use(Notifications)
  // Vue.use(VueClipboard)
  Vue.use(Components)
  Vue.use(ResourceComponents)
  Vue.use(VueObserveVisibility);
  Vue.use(VueAllSelectionController);

  // Vue.component('range-slider', Range)
  Vue.component('AuthProvider', AuthProvider);

  Vue.component('vue-multiselect', VueMultiselect);
  Vue.component(VueCountdown.name, VueCountdown)

  Vue.use(filters);
  Vue.use(UiClickOutside);
  Vue.use(VueAccordion);
  Vue.use(VueBem, {
    blockNameKey: 'block',
  })

  Vue.use(VModal, {
    dynamic: true,
    dialog: true,
    dynamicDefaults: {
      transition: 'nice-modal-fade',
    },
  })

  Vue.use(VueGoogleMaps, {
    load: {
      key: window.gmap,
      libraries: 'places',
    },
  })
  Vue.use(DermisPlugin, {
    prefix: 'ds-',
    prefixType: 't-',
    prefixUtils: 'u-',
  })
  Vue.directive('mask', updatedMask)
}

export default { install };
