import VuejsClipper from 'vuejs-clipper'

import Cropper from './Cropper'
import Clipper from './Clipper'
import Gallery from './Gallery'
import GalleryProductView from './GalleryProductView'
import YoutubeLoader from './YoutubeLoader'

function install(Vue) {
  Vue.component(Cropper.name, Cropper)
  Vue.component(Gallery.name, Gallery)
  Vue.component(GalleryProductView.name, GalleryProductView)
  Vue.component(YoutubeLoader.name, YoutubeLoader)
  Vue.component('Clipper', Clipper)
  Vue.use(VuejsClipper, {
    components: {
      clipperFixed: 'image-clipper-basic',
      clipperPreview: 'my-preview',
      clipperUpload: 'clipper-upload',
      clipperRange: 'clipper-range',
    },
  })
}

export default { install }
