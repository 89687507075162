import './public-path';

// import $ from 'jquery'
import is from 'is_js_new'

import Vue from 'vue';
import Router from 'vue-router';
import I18n from 'vue-i18n';
import { Store } from 'vuex';

import { install } from '@/install';
import { createApp } from '@/app';

import {
  MOBILEREG,
  CYRILLIC_CHARS,
  EMAILREG,
  CODE_VARIANTS_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_REG,
} from '@/const'

import {
  extend,
} from 'vee-validate'
import {
  required, confirmed, email,
  min, min_value as minValue, max, max_value as maxValue,
  ext,
} from 'vee-validate/dist/rules'

import { validate as UrlValidate } from '@/js/validation/url.js'
import '@/js/validation'
import '@/js/utils'

import directives from '@utils/directives'
import WEBCASEConsole from './webcase-console';

import './styles/index.sass';
import '@webcase/vue-chat/src/styles.sass';
import '@webcase/vue-chat/src/styles/avatar.sass';

WEBCASEConsole()

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(install)

Vue.mixin({
  directives,
})

const { app } = createApp({
  Vue, Router, I18n, Store,
})

window.is = is
// eslint-disable-next-line no-multi-assign
// window.$ = window.jQuery = $
// window.jQuery = $

app.$mount('#app')

extend('password', {
  validate(value) {
    if (PASSWORD_MIN_LENGTH > value.length) return false;
    return PASSWORD_REG.test(value);
  },
  computesRequired: true,
  message: app._('The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number.'),
});

extend('phone', {
  validate(value) {
    // Regex for a international phone number
    return MOBILEREG.test(value)
  },
  message: app._('Phone is not valid'),
})

extend('onlyCyrillic', {
  validate(value) {
    // Regex for cylirric characters
    return CYRILLIC_CHARS.test(value)
  },
  message: app._('Only Cyrillic characters'),
})

extend('email_phone', {
  validate(value) {
    // Regex for a international phone number
    return EMAILREG.test(value) || MOBILEREG.test(value)
  },
  message: app._('Set correct email or phone. Correct phone format is: +380XXXXXXXXX '),
})

extend('url', {
  validate: value => UrlValidate(value),
  message: app._('Set correct url'),
})

extend('required', { ...required, message: app._('This field is required.') })
extend('confirmed', { ...confirmed, message: app._('Passwords must match.') })
extend('min', { ...min, message: app._('Value is too short.') })
extend('min_value', { ...minValue, message: app._('Minimum value is {min}.') })
extend('max', { ...max, message: app._('Value is too long.') })
extend('max_value', { ...maxValue, message: app._('Maximum value is {max}.') })
extend('ext', { ...ext, message: app._('Invalid file format') })
extend('email', { ...email, message: app._('Email is invalid.') })

extend('start_end_time', {
  validate: (value, { other }) => { console.log(value, other); return value < other },
  message: app._('Время начала не может быть больше времени завершения'),
  params: [{ name: 'other', isTarget: true }],
})

const getTime = dateString => {
  if (!dateString) return null
  const [day, month, year] = dateString.split('.')
  return new Date(`${month}.${day}.${year}`).getTime()
}

extend('start_end_date', {
  validate: (value, { other }) => { console.log(getTime(value), getTime(other)); return getTime(value) <= getTime(other) },
  message: app._('Дата начала не может быть больше даты завершения'),
  params: [{ name: 'other', isTarget: true }],
})

extend('legal_code', {
  validate(value) {
    return CODE_VARIANTS_LENGTH.find(variant => new RegExp(`^\\d{${variant}}$`).exec(value))
  },
  message() {
    return app._('Введите валидный код')
  },
})
