//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SlimController from '@app/Filters/Slim/Controller'

import ViewMixin from '@app/Filters/mixins/ViewMixin'

export default {
  mixins: [ViewMixin],
  components: {
    SlimController,
  },
}

