//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable max-len */

import {
  mapState,
  mapActions,
} from 'vuex'

import {
  // pathGetter,
  confirmWithPromise,
} from '@utils/helpers'

import {
  attributesResource,
} from '@api/catalog.service'
import {
  removeFromComparison,
  clearComparison,
  clearCategoryComparison,
} from '@api/comparison.service'

import equals from 'ramda/src/equals'
// import propEq from 'ramda/src/propEq'

export default {
  name: 'ComparisonView',
  props: [
    'categoryId',
    'categoryLabel',
    'categoryTitle',
    'baseUrl',
  ],
  data() {
    return {
      currency: window.currency,
      is_auth: window.authent,
      attributes: [],
      val: [],
      product_attrs: '',
      show: false,
    }
  },
  computed: {
    ...mapState('comparison', [
      'list',
    ]),
  },
  async created() {
    await this.getAttributes()
    await this.getListAndCalc()
  },
  mounted() {
    window.addEventListener('resize', this.calcHeight, { passive: true })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calcHeight, { passive: true })
  },
  methods: {
    ...mapActions('comparison', [
      'getList',
    ]),
    async getListAndCalc() {
      await this.getList(this.categoryId)
      this.calcHeight()
      this.showAll()
    },
    showAll() {
      this.show = !this.show
      this.product_attrs = this.list.map(el => el.props.product.props.attributes)
      this.attrs = this.attributes
      this.$nextTick(() => {
        this.calcHeight()
      })
    },
    showDiff() {
      this.show = !this.show
      const arrs = []
      this.product_attrs = this.attributes.reduce((acc, obj) => {
        const attrId = obj.props.attribute.id
        acc = acc || {}
        const current = this.list
          .map(({ props: { product: { props: { attributes } } } }) => this.getAttributesIds(attributes, attrId))
        const result = current.every(k => equals(k, current[0]))
        if (!result) {
          this.list.forEach(({ props: { product: { props: { attributes } } } }, id) => {
            if (attributes[attrId]) {
              acc[id][attrId] = attributes[attrId]
            }
          })
          arrs.push(obj)
        }
        return acc
      }, Array.from({ length: this.list.length }, () => ({})))
      this.attrs = arrs
      this.$nextTick(() => {
        this.calcHeight()
      })
    },
    async clear() {
      const question = this._('Очистить сравнение?')
      await confirmWithPromise(question, this)
      await clearComparison.execute()
      await this.getList(this.categoryId)
    },
    hoverEffect(event, idx, action) {
      const one = 1
      Array.from(document.getElementsByClassName('first-column__cell'))[idx - one].classList[action]('is-active')
      this.list.forEach((el, i) => {
        Array.from(document.getElementsByClassName(`cell${i}`))[idx].classList[action]('is-active')
      })
    },
    async clearByCategory(id) {
      const question = this._('Удалить все товары из сравнения?')
      await confirmWithPromise(question, this)
      await clearCategoryComparison.execute({ id })
      await this.getListAndCalc()
    },
    getValue(obj, getter) {
      return getter(obj)
    },
    async rm(id) {
      await removeFromComparison.execute({ id })
      await this.getList(this.categoryId)
      this.calcHeight()
    },
    async getAttributes() {
      const { data } = (await attributesResource.execute({
        category: this.categoryLabel,
      }))
      this.attributes = data
    },
    getAttributesIds(arr, key) {
      if (!arr[key]) return []
      return arr[key].map(el => el.props.id)
    },
    getAttribute(arr, key) {
      return arr[key].map(el => el.props.title).join(', ')
    },
    hasAttribute(arr, key) {
      return Object.keys(arr).find(el => el === String(key))
    },
    calcHeight() {
      const one = 1
      const zero = 0
      this.val = []
      let total = this.attributes.length
      total += one
      for (let x = zero; x < total; x++) {
        this.val.push({
          h: 'auto',
        })
      }
      if (this.val.length > zero) {
        this.val = JSON.parse(JSON.stringify(this.val))
        this.val.forEach((el, i) => {
          this.val[i] = 'auto'
        })
      }
      this.isPageLoading = true
      const time = 500
      setTimeout(() => {
        const arr = []
        this.list.forEach((el, i) => {
          arr.push(Array.from(document.getElementsByClassName(`cell${i}`)).map(element => ({ h: element.scrollHeight })))
        })
        arr.push(Array.from(document.getElementsByClassName('firstcell')).map(element => ({ h: element.scrollHeight })))
        this.val = JSON.parse(JSON.stringify(this.val))
        const [firstVariant] = arr
        this.val = firstVariant
        for (let i = zero; i < arr.length; i++) {
          for (let j = zero; j < arr[i].length; j++) {
            if (this.val[j].h < arr[i][j].h) {
              this.val[j].h = arr[i][j].h
              this.$set(this.val[j], 'h', arr[i][j].h)
            }
          }
        }
        this.val = JSON.parse(JSON.stringify(this.val))
        this.val.forEach((el, i) => {
          el.h = `${el.h}px`
          this.$set(this.val[i], 'h', `${el.h}`)
        })
        if (total - one !== this.val.length) {
          const other = total - this.val.length
          for (let x = zero; x < other; x++) {
            this.val.push({
              h: 'auto',
            })
          }
        }
        this.isPageLoading = false
      }, time)
    },
  },
}
