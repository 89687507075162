//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  ReviewMediaList,
} from '@api/vendor.service'

export default {
  name: 'ReviewsMedia',
  props: {
    id: [Number, String],
    type: String,
    initOption: {
      type: Object,
      default: () => ({
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 564,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 4,
            },
          },

          {
            breakpoint: 1599,
            settings: {
              slidesToShow: 5,
            },
          },

        ],
      }),
    },
  },
  data() {
    const laptopDivisionWidth = 1200;
    return {
      isDesktop: laptopDivisionWidth <= window.innerWidth,
      list: [],
      timestamp: new Date(),
      options: {
        prevArrow: '<i class="controls controls--variant_default controls-left mdi mdi-chevron-left"></i>',
        nextArrow: '<i class="controls controls--variant_default controls-right mdi mdi-chevron-left "></i>',
        dots: false,
        preload: true,
        focusOnSelect: false,
        arrows: true,
        infinite: true,
        ...this.initOption,
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    photoMap() {
      return this.list.map(el => el.props.image)
    },
    show(index) {
      console.log(index);
      this.$modal.show(
        () => import('@app/Review/MediaDetail'),
        {
          list: this.list,
          index,
        },
        {
          height: 'auto',
          classes: 'loadPopup',
          adaptive: true,
          scrollable: true,
        }
      )
    },
    async getList() {
      const media = ['photos', 'videos']
      media.forEach(async key => {
        const { data: { items } } = (await ReviewMediaList.execute({ key, type: this.type, id: this.id }))
        console.log(items);
        this.list.push(...items)
        // this.timestamp = new Date()
      })
    },
  },
}
