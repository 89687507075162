//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import debounce from 'lodash.debounce'

const INITIAL_COUNT = 1
export default {
  name: 'Counter',
  props: {
    amount: {
      type: [String, Number],
      default: () => INITIAL_COUNT,
    },
    disable: {
      default: () => false,
      type: Boolean,
    },
    maxCount: {
    },
    min: {
      default: 1,
    },
    step: {
      default: 1,
    },
  },
  data() {
    return {
      one: this.min,
      show: false,
      count: this.amount,
    }
  },
  watch: {
    amount(nval) {
      this.count = nval
    },
  },
  computed: {
    cantIncrease() {
      return Number(this.maxCount) <= this.count
    },
  },
  created() {
    const delay = 1000
    this.debounceAction = debounce(() => {
      this.emitter()
    }, delay)
  },
  methods: {
    check(e) {
      if (this.cantIncrease) {
        this.count = this.amount
        e.preventDefault()
      }
    },
    setDefault(e) {
      console.log(e.target.value, this.min, (Math.round((e.target.value - this.min) * 1e12) / 1e12))
      const division = (Math.round((e.target.value - this.min) * 1e12) / 1e12 % this.step).toFixed(2) % this.step
      console.log(division);
      if (division) {
        this.count = Math.round((this.count - division) * 1e12) / 1e12
        e.preventDefault()
      }
      if (e.target.value < this.one || '' === e.target.value) {
        this.count = this.one
        e.preventDefault()
      }
      this.debounceAction()
    },
    deleteMore() {
      this.count = Math.round((this.count - this.step) * 1e12) / 1e12
      if (this.count < this.one) {
        this.count = this.one
      }
      this.changeCount()
    },
    addMore() {
      this.count = Math.round((this.step + this.count) * 1e12) / 1e12
      this.changeCount()
    },
    changeCount() {
      this.$emit('immediately', { count: this.count })
      this.debounceAction()
    },
    emitter() {
      this.$emit('change', { count: this.count })
    },
  },

}
