import { createStore as createVendorStore } from '@store/vendor'
import { createStore as createSearchStore } from '@store/search'
import { createStore as createCartStore } from '@store/cart'
import { createStore as createChatStore } from '@store/chat'
import { createStore as createComparisonStore } from '@store/comparison'
import { createStore as createLikesStore } from '@store/like'
import { createStore as createMenuStore } from '@store/menu'
import { createStore as createPjaxStore } from '@store/pjax'
import { createStore as createShopStore } from '@store/shop'
import { createStore as createStaffStore } from '@store/staff'
import { createStore as createStatisticStore } from '@store/statistic'
import { createStore as createTipsStore } from '@store/tips'
import { createStore as createWaitingList } from '@store/waitinglist'

export function createStore(params) {
  return {
    modules: {
      cart: createCartStore(params),
      chat: createChatStore(params),
      search: createSearchStore(params),
      vendor: createVendorStore(params),
      comparison: createComparisonStore(params),
      like: createLikesStore(params),
      menu: createMenuStore(params),
      pjax: createPjaxStore(params),
      shop: createShopStore(params),
      staff: createStaffStore(params),
      statistic: createStatisticStore(params),
      tips: createTipsStore(params),
      waitinglist: createWaitingList(params),
    },
  }
}

export function createStoreObject({ Store, Vue }) {
  return new Store(createStore({ Store, Vue }))
}
