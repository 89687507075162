//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import cancellablePromiseProxy from '@aspectus/cancellable-promise-proxy';
import { receiveResource, createResource } from '@resource/resource'

import {
  PRODUCT_ATTRIBUTES_URL,
} from '@api/store.service'

export default {
  name: 'AttributesView',
  props: ['id', 'immediate'],
  data() {
    return {
      list: [],
      activeVariant: {},
      currentRequest: null,
    }
  },
  mounted() {
    if (this.immediate) this.getAttributes()
  },
  methods: {
    getActive(id) {
      return !!this.activeVariant.ids.find(i => i === id)
    },
    getPreview(variant, id) {
      return variant?.previews[id]
    },
    getUrl(id) {
      const filteredByDisabled = this.list.variants.find(el => !el.is_active && el.ids.find(i => i === id))
      if (this.activeVariant) {
        const [first, second] = this.activeVariant.ids
        const pairWithActive = this.list.variants.find(el => !el.is_active && (el.ids.includes(first) || el.ids.includes(second)) && el.ids.find(i => i === id))
        if (pairWithActive) return pairWithActive
        return { ...filteredByDisabled, multiple: !!second }
      }

      return { ...filteredByDisabled, multiple: true }
    },
    clearRequest() {
      if (this.currentRequest) this.currentRequest.cancel()
    },
    async getAttributes() {
      if (this.list.length) return
      if (this.currentRequest) this.currentRequest.cancel()
      const cancelController = new AbortController();
      const base = receiveResource.config('signal', cancelController.signal)
      const resource = createResource(PRODUCT_ATTRIBUTES_URL, base)

      this.currentRequest = cancellablePromiseProxy(
        new Promise((resolve, reject) => {
          resource.execute({
            ids: [this.id],
          })
            .then(resolve, reject);
        }),
        { cancelController }
      ).then(({ data }) => {
        const [first] = data
        this.list = first
        this.activeVariant = first.variants.find(el => el.is_active)
      })
    },
    getCurrentVariantInfo(arr) {
      return arr.find(v => this.activeVariant.ids.find(a => a === v.props.id))
    },
  },
}
