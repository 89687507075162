var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-selection-controller',{attrs:{"options":_vm.list,"multiple":true,"key-getter":_vm.$options.keyGetter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var options = ref.options;
var model = ref.value;
var change = ref.change;
return [_c('validation-provider',{attrs:{"rules":{ required: { allowFalse: false } },"name":"claim_type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return _vm._l((options),function(ref){
var value = ref.value;
return _c('div',{staticClass:"vb-space_mt vb-space_mt--3"},[_c('tag',{key:("key_" + (value.id)),ref:("claim_type_" + (value.id)),refInFor:true,staticClass:"d-control-static-input d-control-static-input--appearance_inline",attrs:{"rules":{ required: { allowFalse: false } },"tag":"input","input-label":value.props.title,"id":("claim_type_" + (value.id)),"type":"checkbox","name":"claim_type"},on:{"change":function($event){change(value); _vm.$emit("input", _vm.type)}},model:{value:(model),callback:function ($$v) {model=$$v},expression:"model"}}),_c('label',{staticClass:"input-group__input",attrs:{"for":("claim_type_" + (value.id))}},[_vm._v(_vm._s(value.props.title))])],1)})}}],null,true)})]}}]),model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})}
var staticRenderFns = []

export { render, staticRenderFns }