import {
  ValidationProvider,
  ValidationObserver,
  localize,
} from 'vee-validate'

import Vue from 'vue'

// import
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// localize hack ! is not localize dublicate !
const LOCALE = window.language
localize(LOCALE)
