import {
  ResendCode,
  // UserActivate,
} from '@api/auth.service'

import {
  AnswerMessage,
} from '@utils/submit'

import {
  defaultValidatorErrorsParser,
} from '@app/Forms/FormMixin'

export default {
  name: 'UserVerify',
  data() {
    return {
      form_confirm: {
        activation_code: '',
      },
      time: 60000,
      sended: false,
    }
  },
  methods: {
    restart() {
      this.non_field_errors = ''
      this.form_confirm.activation_code = ''
      this.sended = false
    },
    collectData() {
      if (this.sended) {
        return this.form_confirm
      }
      return this.form
    },
    async resendCode() {
      try {
        await ResendCode.execute({}, { user: this.id })
        this.time += 1
        const message = {
          messages: [
            {
              title: this._('Resend Registration code success title'),
              text: this._('Resend Registration code success text'),
            },
          ],
          timeout: 3000,
        }
        AnswerMessage(message, this)
      } catch (e) {
        console.log(e)
      }
    },
    beforeSend(userRegisterResource) {
      this.non_field_errors = false
      const data = this.collectData()
      const action = userRegisterResource
      this.sended = false
      return action.execute({}, data)
        .then(({ data: resdata }) => {
          AnswerMessage(resdata, this)
          this.$emit('close')
          // return
          // if (this.form_confirm.activation_code) {
          // }
          // this.sended = true
          // this.id = resdata.item.id
          // this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      console.log(errors);
      const parsed = defaultValidatorErrorsParser(errors)
      if (this.form_confirm.activation_code) {
        this.sended = true
      }
      console.log(parsed);
      this.non_field_errors = parsed.non_field_errors
      if (parsed.code) {
        this.non_field_errors = parsed.code
      }
      this.$refs.validator.setErrors(parsed)
    },
  },
}
