import {
  createReceiver,
  createSender,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'cart'

const CART_CHANGE_URL = prefixAPI('/change/', MODEL)
const CART_CLEAR_URL = prefixAPI('/clear/', MODEL)
const CART_RETRIEVE_URL = prefixAPI('/retrieve/', MODEL)
const CART_INFO_RETRIEVE_URL = prefixAPI('/quantity/', MODEL)
const CART_COUPON_URL = prefixAPI('/coupon/', MODEL)

export const changeCartEntity = createSender(CART_CHANGE_URL)
export const clearCartEntity = createSender(CART_CLEAR_URL)
export const addCartCoupon = createSender(CART_COUPON_URL)
export const retrieveCartEntity = createReceiver(CART_RETRIEVE_URL)
export const retrieveCartInfo = createReceiver(CART_INFO_RETRIEVE_URL)
