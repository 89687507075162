import {
  createReceiver,
  createSender,
  createUpdater,
  createRemover,
  createOptionsGetter,
  createUpdaterWithFile,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'staff'
const EMAIL_MODEL = `${MODEL}/email`
const PHONE_MODEL = `${MODEL}/phone`
const LEGAL_ENTITY_MODEL = `${MODEL}/legal-entity`

export const USER_RETRIVE_URL = prefixAPI('/users/retrieve/', MODEL)
export const USER_UPDATE_URL = prefixAPI('/users/update/', MODEL)

export const USER_CHECK_OPPOSITE_ACCOUNT_URL = prefixAPI('/actions/check-opposite-user-type-existence/', MODEL)

// отправляет эмейл с ссылкой для активации.
export const EMAIL_VERIFY_URL = prefixAPI('/verify/{id}/', EMAIL_MODEL)
export const EMAIL_SEND_CODE_URL = prefixAPI('/confirmation-send/{id}/', EMAIL_MODEL)
export const EMAIL_DESTROY_URL = prefixAPI('/destroy/{id}/', EMAIL_MODEL)

// создает новый эмейл адресс с отправкой письма для его подтверждения
export const EMAIL_CHANGE_URL = prefixAPI('/change/', EMAIL_MODEL)

// отправляет смс-ку с кодом
export const PHONE_SEND_CODE_URL = prefixAPI('/confirmation-send/{id}/', PHONE_MODEL)
// верификация по коду
export const PHONE_VERIFY_URL = prefixAPI('/verify/{id}/', PHONE_MODEL)
// создает новый телефон с отправкой кода подтверждения
export const PHONE_CHANGE_URL = prefixAPI('/change/', PHONE_MODEL)
// destroy/ phone
export const PHONE__DESTROY_URL = prefixAPI('/destroy/{id}/', PHONE_MODEL)

export const LEGAL_PERSONE_UPDATE_URL = prefixAPI('/update/', LEGAL_ENTITY_MODEL)

export const userRetrieve = createReceiver(USER_RETRIVE_URL)
export const userUpdate = createUpdaterWithFile(USER_UPDATE_URL)
export const userOptionsRetrieve = createOptionsGetter(USER_UPDATE_URL)

export const SendCodeForEmail = createUpdater(EMAIL_SEND_CODE_URL)
export const VerifyEmail = createUpdater(EMAIL_VERIFY_URL)
export const DestroyEmail = createRemover(EMAIL_DESTROY_URL)
export const ChangeEmail = createSender(EMAIL_CHANGE_URL)

export const SendCodeForPhone = createUpdater(PHONE_SEND_CODE_URL)
export const VerifyPhone = createUpdater(PHONE_VERIFY_URL)
export const ChangePhone = createSender(PHONE_CHANGE_URL)
export const DestroyPhone = createRemover(PHONE__DESTROY_URL)

export const LegalPersoneUpdate = createUpdater(LEGAL_PERSONE_UPDATE_URL)

export const CheckOppositeAccount = createReceiver(USER_CHECK_OPPOSITE_ACCOUNT_URL)
