//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Payment from './Parts/Payment'
import Delivery from './Parts/Delivery'

const WIDGETS_MAP = {
  delivery: Delivery,
  payment: Payment,
}

export default {
  name: 'OrderPaymentAndDelivery',
  widgetsMap: WIDGETS_MAP,
  props: ['fields', 'className'],
  data() {
    return {
      form: {
        shipping_service: null,
        payment_gateway: '',
        city: JSON.parse(window.current_city),
        street: '',
        house: '',
        apartment: '',
        warehouse: '',
      },

    }
  },
  methods: {
    simulate(event) {
      const data = Object.assign(this.form, event)
      this.$emit('update', data)
    },
  },
}
