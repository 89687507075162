

import {
  mapActions,
} from 'vuex'

export default {
  name: 'WaitingListGetter',
  data() {
    return {
      isAuth: window.is_authenticated,
    }
  },
  mounted() {
    if (this.isAuth) this.getWaitingListIds()
  },
  methods: {
    ...mapActions('waitinglist', [
      'getWaitingListIds',
    ]),
  },
  render() {
    return this.$scopedSlots.default({})
  },
}
