import { formatTranslation as f } from '@utils/strings';
// import { DATE_CONFIG } from '@md/ui/components/controls/DatePicker';

const RendererMixin = {
  functional: true,
  props: ['filter', 'value'],
};

function renderValue(h, context, value) {
  const { filter } = context.props;

  return h('span', { attrs: { class: 'product-variants__el product-variants__el--large product-variants__el--with-times' } }, [
    f(
      context.parent._('<[caption]>: <[value]>'),
      { caption: filter.props.attribute.props.title, value }
    ),
  ]);
}

export const SelectRenderer = {
  mixins: [RendererMixin],
  render(h, context) {
    return renderValue(h, context, context.props.value.caption);
  },
};

export const MultiselectRenderer = {
  mixins: [RendererMixin],
  render(h, context) {
    const { value, filter } = context.props;
    const final = filter.props.attribute.props.values.filter(
      el => value.find(v => v === el.props.label)
    )
    console.log(final);
    const compiled = (final && final.length && final || [])
      .map(({ props: { title } }) => title)
      .join(', ');

    return renderValue(h, context, compiled);
  },
};

export const SliderRenderer = {
  mixins: [RendererMixin],
  render(h, context) {
    const { value } = context.props;
    const { min, max } = value;
    const _ = context.parent._.bind(context.parent);
    let compiled = '';
    if ('min' in value && 'max' in value) {
      compiled = f(_('От <[min]> до <[max]>'), { min, max });
    } else if ('min' in value) {
      compiled = f(_('От <[min]>'), { min });
    } else if ('max' in value) {
      compiled = f(_('До <[max]>'), { max });
    }

    return renderValue(h, context, compiled);
  },
};

// export const DateRangeRenderer = {
//   mixins: [RendererMixin],
//   render(h, context) {
//     const { value } = context.props;
//     const _ = context.parent._.bind(context.parent);
//     const d = context.parent.$d.bind(context.parent);
//     const starts = d(new Date(value.starts), DATE_CONFIG);
//     const ends = d(new Date(value.ends), DATE_CONFIG);

//     let compiled = '';
//     if (starts !== ends) {
//       compiled = f(_('<[starts]> - <[ends]>'), { starts, ends });
//     } else {
//       compiled = f(_('<[starts]>'), { starts });
//     }

//     return renderValue(h, context, compiled);
//   },
// };

export const CombinedInputRenderer = {
  mixins: [RendererMixin],
  render(h, context) {
    const { value } = context.props;

    return renderValue(h, context, `${value.element.caption} / ${value.search}`);
  },
};

export const AutocompleteMultiselectRenderer = MultiselectRenderer;
