//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  ReviewListResoure,
} from '@api/vendor.service'

import AddReview from '@app/Review/Add'

export default {
  name: 'UserReviewList',
  props: {
    product_type: {
      type: String,
    },
    product_id: {
      type: String,
    },
  },
  data() {
    return {
      list: {},
      offset: 0,
      limit: 3,
      meta: {},
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const params = {
        type: this.product_type,
        id: this.product_id,
        offset: this.offset,
      }
      const { data: { items, pagination } } = (await ReviewListResoure
        .execute(params))
      this.list = items
      this.meta = pagination
    },
    reply(id, parentId, name) {
      this.$modal.show(
        AddReview,
        {
          product_type: this.product_type,
          product_id: this.product_id,
          reply_name: name,
          reply: parentId,
          id,
        }, {
          height: 'auto',
          classes: ['extralarge-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
}
