var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"review-view"},[_c('h3',{staticClass:"ds-caption ds-caption--size_md ds-caption--size_xl-xl ds-caption--bold"},[_vm._v(_vm._s(_vm._('Отзыв о товаре')))]),_c('vue-accordion',{attrs:{"init":false,"id":_vm.review.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var activeState = ref.activeState;
var toggleActive = ref.toggleActive;
var collapse = ref.collapse;
var style = ref.style;
return [_c('div',{staticClass:"vb-accordion vb-accordion--review vb-accordion--clear",class:{"ns-accordion--is-open": activeState},attrs:{"id":_vm.review.id}},[_c('div',[_c('div',{staticClass:"vb-accordion__question vb-accordion__question--clear"},[_c('div',{staticClass:"review review--clear"},[_c('div',{staticClass:"review__top"},[_c('div',{staticClass:"review-author"},[_c('div',{staticClass:"review-author__img-wrap"},[_c('img',{staticClass:"review-author__img",attrs:{"src":"/static/img/user_dummy.svg"}})]),_c('p',{staticClass:"review-author__name"},[_vm._v(_vm._s(_vm.review.props.name))])]),_c('div',{staticClass:"review__rate"},[_c('pretty-stars',{attrs:{"rating":_vm.review.props.rate,"read-only":true}}),_c('p',{staticClass:"review__text"},[_vm._v(" "+_vm._s(_vm.review.props.created_at))])],1),_c('modal-trigger',{attrs:{"url":"Review/AddClaim","name":"AddClaim","review":_vm.review.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('a',{staticClass:"complaint-btn",on:{"click":function($event){$event.preventDefault();return open($event)}}},[_c('span',{staticClass:"complaint-btn__icon icon-alert"}),_c('p',{staticClass:"complaint-btn__title"},[_vm._v(_vm._s(_vm._('Пожаловаться')))])])]}}],null,true)})],1),_c('div',{staticClass:"review__content"},[_c('p',{staticClass:"review__body"},[_vm._v(_vm._s(_vm.review.props.comment))]),_c('p',{staticClass:"review__subtitle"},[_vm._v(_vm._s(_vm._('Достоинства ')))]),_c('p',{staticClass:"review__body"},[_vm._v(_vm._s(_vm.review.props.advantages))]),_c('p'),_c('p',{staticClass:"review__subtitle"},[_vm._v(_vm._s(_vm._('Недостатки')))]),_c('p',{staticClass:"review__body"},[_vm._v(_vm._s(_vm.review.props.disadvantages))]),_c('p'),_c('div',{staticClass:"g-row g-row--appearance_spaced g-row--space_lg media"},[_vm._l((_vm.review.props.photos),function(photo,i){return _c('light-box',{key:("photo" + (photo.id)),staticClass:"g-cols g-cols--6 g-cols--4-md g-cols--6-xl g-cols--3-mac g-cell",attrs:{"source":_vm.photoMap(_vm.review.props.photos)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('figure',{staticClass:"ds-aspect-ratio ds-aspect-ratio--appearance_filled ds-aspect-ratio--ratio_16x9"},[_c('div',{staticClass:"ds-aspect-ratio__body"},[_c('div',{staticClass:"media-preview",on:{"click":function($event){return show(i)}}},[_c('img',{staticClass:"media-preview__img",attrs:{"src":photo.props.image}})])])])]}}],null,true)})}),_vm._l((_vm.review.props.videos),function(video,i){return _c('div',{key:("video" + (video.id)),staticClass:"g-cols g-cols--6 g-cols--4-md g-cols--6-xl g-cols--3-mac g-cell"},[_c('figure',{staticClass:"ds-aspect-ratio ds-aspect-ratio--appearance_filled ds-aspect-ratio--ratio_16x9"},[_c('div',{staticClass:"ds-aspect-ratio__body"},[_c('div',{staticClass:"media-preview"},[_c('ui-youtube-view',{attrs:{"url":video.props.url},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var play = ref.play;
var preview = ref.preview;
return [_c('div',{staticClass:"media-preview__mask",on:{"click":play}},[_c('img',{staticClass:"media-preview__img",attrs:{"src":preview}}),_c('div',{staticClass:"media-preview__play icon-play"})])]}}],null,true)})],1)])])])})],2)]),_c('div',{staticClass:"review__footer"},[_c('div',{staticClass:"review__bottom"},[(_vm.review.props.children.length)?_c('a',{staticClass:"read-more",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return toggleActive($event)}}},[_c('p',{staticClass:"read-more__title"},[_vm._v(_vm._s(activeState ? _vm._('Скрыть ответы') : _vm._('Читать ответы')))]),_c('span',{staticClass:"read-more__icon"},[_c('div',{staticClass:"mdi",class:activeState ? "mdi-chevron-up" : "mdi-chevron-down"})])]):_vm._e()]),(activeState)?_c('div',{staticClass:"vb-accordion__description vb-accordion__description--spaced",style:({maxHeight: "initial"})},_vm._l((_vm.review.props.children),function(child){return _c('div',{staticClass:"review__question review__question--clear"},[_c('div',{staticClass:"review review--answer"},[_c('div',{staticClass:"review__top"},[_c('div',{staticClass:"review-author"},[_c('p',{staticClass:"review-author__name"},[_vm._v(_vm._s(child.props.name))])]),_c('div',{staticClass:"review__rate"},[_c('p',{staticClass:"review__text"},[_vm._v(_vm._s(child.props.created_at))])]),_c('modal-trigger',{attrs:{"url":"Review/AddClaim","name":"AddClaim","review":child.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('a',{staticClass:"complaint-btn",on:{"click":function($event){$event.preventDefault();return open($event)}}},[_c('span',{staticClass:"complaint-btn__icon icon-alert"}),_c('p',{staticClass:"complaint-btn__title"},[_vm._v(_vm._s(_vm._('Пожаловаться')))])])]}}],null,true)})],1),_c('div',{staticClass:"review__content"},[_c('p',{staticClass:"review__body"},[_vm._v(_vm._s(child.props.comment))])])])])}),0):_vm._e()])])])])])]}}])}),_c('review-slim-add',{attrs:{"promise":_vm.info.promise,"info":_vm.collectData},on:{"isOver":function($event){return _vm.$emit("close")}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }