const SET_COUNT = 'SET_COUNT'

export function createStore() {
  return {
    namespaced: true,
    state: {
      count: [],
    },
    getters: {
      // eslint-disable-next-line no-return-assign
      getCount: state => state.count.reduce((acc, obj) => acc += Object.values(obj)[0], 0),
      // FIXME
      getProductChatCount: state => state.count[0] && state.count[0].chat_product,
      getOrderChatCount: state => state.count[1] && state.count[1].chat_order,
      getSellerChatCount: state => state.count[2] && state.count[2].chat_seller,
    },
    actions: {
      updateCount({ commit }, payload) {
        commit(SET_COUNT, payload)
      },
    },
    mutations: {
      [SET_COUNT](state, payload) {
        state.count = payload
      },
    },
  }
}
