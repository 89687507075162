//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'menu-drawer',
  computed: {
    ...mapGetters('menu', [
      'menu',
    ]),
  },
}
