//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  CheckCode,
} from '@api/auth.service'

import {
  AnswerMessage,
} from '@utils/submit'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'Login',
  props: {
    label: {
    },
    initCode: {
    },
    autofocus: {
      default: true,
    },
  },
  mixins: [FormMixin],
  data() {
    return {
      accept_rules: false,
      offer: window.offer,
      rules: window.rules,
      non_field_errors: '',
      form: {
        code: this.initCode,
      },
      fields: [
        {
          size: '6',
          title: this.label,
          rules: 'required|legal_code',
          type: 'number',
          name: 'code',
          tag: 'd-control-input',
          props: {
            autofocus: this.autofocus,
          },
        },
      ],
    }
  },
  watch: {
    initCode() {
      this.form.code = this.initCode
    },
  },
  mounted() {
    const [prefix, code] = window.location.search.split('=')
    console.log(prefix, code, '&&&&');
    if (code) {
      this.form.code = code
      this.prepareData(true)
      this.$refs.validator.reset()
    }
  },
  methods: {
    async prepareData(valid) {
      this.non_field_errors = ''
      this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      this.isLoad = true
      this.$emit('load', this.isLoad)
      return CheckCode.execute({}, data)
        .then(res => {
          this.isLoad = false
          this.$emit('load', this.isLoad)
          if (res.data.is_valid) {
            this.$emit('setData', res.data)
          } else {
            // eslint-disable-next-line prefer-template
            this.non_field_errors = [res.data.code + ' ' + this._('находится в') + ' ' + res.data.status + ' ' + this._('и не может быть использован.')]
          }
          AnswerMessage(res.data, this)
          this.$emit('close')
          this.$refs.validator.reset()
        }, err => {
          this.isLoad = false
          this.$emit('load', this.isLoad)
          return Promise.reject(err)
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },
}

