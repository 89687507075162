import { pick, omit } from 'ramda'
import { renderSlim } from '@aspectus/vue-utils'

import {
  debounce,
} from '@utils/helpers'

const PAGINATION_KEYS = ['limit', 'offset', 'total']
const FILTER_PAGINATION_DROP_KEYS = []

export default {
  name: 'pagination-controller',

  props: {
    receiver: {
      type: Function,
      required: true,
    },
    parameters: {},
    resultPagination: {},
    label: {},
    paginationKeys: {
      type: Array,
      default: () => PAGINATION_KEYS,
    },
    filterPaginationDropKeys: {
      type: Array,
      default: () => FILTER_PAGINATION_DROP_KEYS,
    },
  },

  data() {
    return {
      filters: {},
      pagination: {},
    }
  },

  watch: {
    parameters: {
      immediate: true,
      handler(value = {}) {
        const filters = omit(this.paginationKeys, value)
        const pagination = {
          ...this.pagination,
          ...pick(this.paginationKeys, value),
        }
        this.filters = filters
        this.pagination = pagination
      },
    },
    resultPagination: {
      immediate: true,
      handler(value = {}) {
        // if (!(value && value.total)) {
        //   return
        // }

        this.pagination = { ...this.pagination, ...value }
      },
    },
  },

  methods: {
    update(parameters) {
      const time = 1000
      const action = debounce(this.updater, time)
      return action(parameters)
    },
    updater(parameters) {
      const shift = 1
      const page = Math
        .floor(parameters.offset / parameters.limit) + shift
      parameters.page = {
        page: page || shift,
      }
      parameters.limit = parameters.limit || this.pagination.limit
      this.$emit('update:parameters', parameters)
      return this.receiver(parameters)
    },
    changeFilters(filters) {
      return this.update(omit(this.filterPaginationDropKeys, {
        ...filters,
      }))
    },

    changePagination(pagination) {
      this.update({
        ...pick(
          this.paginationKeys, pagination
        ),
        ...this.filters,
      })
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      filters: this.filters,
      changeFilters: this.changeFilters,
      pagination: this.pagination,
      changePagination: this.changePagination,
    }), h, 'tag')
  },
}
