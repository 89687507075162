//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ViewMixin from '@app/Filters/mixins/ViewMixin'

export default {
  mixins: [ViewMixin],
}

