var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-section-wrap vb-space_mt vb-space_mt--5"},[_c('div',{staticClass:"checkout-title"},[_c('p',{staticClass:"checkout-title__title"},[_vm._v(_vm._s(_vm._('Получатель')))])]),_c('div',{staticClass:"ds-panel ds-panel--space_sm ds-panel--space_xl-xl"},[_c('div',{staticClass:"ds-panel__element ds-panel__element--offset_full"},[_c('div',{staticClass:"g-row g-row--appearance_column g-row--space_xl-xl"},[_c('cell',{attrs:{"cols":"12"}},[_c('v-selection-controller',{attrs:{"options":_vm.variants,"multiple":false,"key-getter":_vm.$options.keyGetter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var options = ref.options;
var model = ref.value;
var change = ref.change;
return [_c('row',_vm._l((options),function(ref){
var value = ref.value;
return _c('cell',{key:value.name,attrs:{"cols":""}},[_c('tag',{key:value.name,ref:("user_" + (value.name)),refInFor:true,attrs:{"tag":"d-control-static-input","input-label":value.label,"value":_vm.getValue(value, model),"id":("user_" + (value.name)),"name":"user","type":"radio"},on:{"change":function($event){change(value); _vm.checkFields(value)}}})],1)}),1)]}}]),model:{value:(_vm.current_user),callback:function ($$v) {_vm.current_user=$$v},expression:"current_user"}})],1),_c('div',{staticClass:"g-cell g-cols g-cols--12"},[(_vm.isLoad)?_c('ui-loader'):_vm._e(),_c('div',{staticClass:"g-row"},_vm._l((_vm.form_fields),function(ref){
var rules = ref.rules;
var name = ref.name;
var label = ref.label;
var cols = ref.cols;
var tag = ref.tag;
var model = ref.model;
var mask = ref.mask;
return _c('cell',{key:name,staticClass:"vb-space_mt vb-space_mt--6",attrs:{"cols":cols}},[_c('tag',{directives:[{name:"mask",rawName:"v-mask",value:(mask),expression:"mask"}],ref:name,refInFor:true,attrs:{"rules":rules,"input-label":label,"has-label":true,"tag":tag || "d-control-input","name":name},on:{"input":function($event){return _vm.$emit("update", _vm.form)}},model:{value:(_vm.form[model]),callback:function ($$v) {_vm.$set(_vm.form, model, $$v)},expression:"form[model]"}})],1)}),1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }