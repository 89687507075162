//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  name: 'Recipient',
  props: ['fields'],
  keyGetter: x => `${x.name}`,
  data() {
    return {
      current_user: {
        name: 'i_am',
        label: this._('Я'),
        compile_from_info: true,
      },
      isLoad: false,
      authUser: window.authent,
      variants: [
        {
          name: 'i_am',
          label: this._('Я'),
          compile_from_info: true,
        },
        {
          name: 'other_person',
          label: this._('Другой человек'),
        },
      ],
      form_fields: [
        {
          model: 'phone',
          name: 'recipient_phone',
          cols: '12 6-md 4-fhd',
          label: this._('Мобильный номер получателя'),
          rules: 'required|phone',
          mask: this.defaultPhoneMask,
        },
        {
          model: 'last_name',
          name: 'recipient_last_name',
          cols: '12 6-md 4-fhd',
          label: this._('Фамилия получателя'),
          rules: 'required|onlyCyrillic',
        },
        {
          model: 'first_name',
          name: 'recipient_first_name',
          cols: '12 6-md 4-fhd',
          label: this._('Имя получателя'),
          rules: 'required|onlyCyrillic',
        },
        {
          model: 'middle_name',
          name: 'recipient_middle_name',
          cols: '12 6-md 4-fhd',
          label: this._('Отчество получателя'),
          rules: 'required|onlyCyrillic',
        },
      ],
      form: {
        first_name: this.fields.first_name,
        last_name: this.fields.last_name,
        phone: this.fields.phone,
        register: false,
        middle_name: this.fields.middle_name,
      },
      offer: window.offer,
      rules: window.rules,
    }
  },

  watch: {
    'fields.customer_info': {
      handler() {
        if (this.current_user.compile_from_info) {
          Object.keys(this.form).forEach(key => {
            const val = this.fields.customer_info[key]
            if (val) this.form[key] = val
          })
          // this.form.middle_name = this.user.props.middle_name
          this.$emit('update', { recipient_info: this.form })
        }
      },
      immediate: false,
      deep: true,
    },
  },
  computed: {
    ...mapState('staff', [
      'user',
    ]),
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('update', { recipient_info: this.form })
    })
  },
  methods: {
    getValue(value, model) {
      return model.find(el => el.name === value.name)
    },
    checkFields() {
      if (!this.current_user.compile_from_info) {
        Object.keys(this.form).forEach(key => {
          this.form[key] = ''
        })
        return
      }
      Object.keys(this.form).forEach(key => {
        this.form[key] = this.fields.customer_info[key]
      })
      this.form.middle_name = this.user.props.middle_name
    },
  },

}
