//
//
//
//
//
//
//
//
//
//
//
//

import { ConfirmPassword } from '@api/auth.service'

export default {
  name: 'PasswordRecoveryConfirm',
  ConfirmPassword,
  props: ['resource'],
  data() {
    return {
      form: {
        username: '',
        id_token: '',
        new_password1: '',
        new_password2: '',
        role: window.current_host,
      },
      fields: [
        {
          name: 'new_password1',
          tag: 'd-control-input',
          cols: '12',
          title: this._('Новый пароль'),
          type: 'password',
          rules: 'required|min:8',
        },
        {
          name: 'new_password2',
          tag: 'd-control-input',
          cols: '12',
          title: this._('Повторите новый пароль'),
          type: 'password',
          rules: 'required|confirmed:new_password1',
        },
      ],
    }
  },
  mounted() {
    const state = new URLSearchParams(window.location.search).get('_state')
    console.log(JSON.parse(atob(state)));
    const { id, username } = JSON.parse(atob(state))
    console.log(id, username)
    this.form.id_token = id
    this.form.username = username
  },
  methods: {
    errorsParser(errors, $this) {
      console.log(errors);
      // const [first] = errors
      $this.non_field_errors = [errors.non_field_errors?.message]
    },
  },
}
