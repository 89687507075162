//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  ReviewCreate,
  ReviewPhotoCreate,
  ReviewPhotoRemove,
  ReviewVideoCreate,
  ReviewVideoRemove,
} from '@api/vendor.service'

import {
  AnswerMessage,
} from '@utils/submit'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'ReviewAdd',
  mixins: [FormMixin],
  props: {
    info: {},
    product_type: {
      type: String,
    },
    product_id: {
      type: String,
    },
    reply: {},
    id: {},
    reply_name: {},
    promise: {},
    inline: {},
  },
  ReviewPhotoCreate,
  ReviewVideoCreate,
  ReviewPhotoRemove,
  ReviewVideoRemove,
  data() {
    return {
      rates: {
        1: this._('Плохо'),
        2: this._('Более менее'),
        3: this._('Нормально'),
        4: this._('Хорошо'),
        5: this._('Отлично'),
      },
      labels: {
        name: this._('Имя'),
        email: this._('Ваш email'),
        advantages: this._('Переваги'),
        disadvantages: this._('Недоліки'),
        comment: this._('Ваше враження'),
      },
      form: {
        comment: this.info.reply_name ? `${this.info.reply_name}, ` : '',
        name: this.info.user_name || '',
        phone: this.info.user_phone || '',
        email: this.info.user_email || '',
        rate: 5,
        element: {
          type: this.info.product_type,
          id: this.info.product_id,
        },
        parent: this.info.id,
        reply: this.info.reply,
        advantages: '',
        disadvantages: '',
        photos: [],
        videos: [],
      },
      non_field_errors: '',
      isLoad: false,
      currentRating: '',
    }
  },
  mounted() {
    this.currentRating = this.rates[this.form.rate]
  },
  methods: {
    setKeys(list, key) {
      this.form[key] = list.map(el => el.id)
    },
    showCurrentRating(rating) {
      if (rating) {
        this.currentRating = this.rates[rating]
        return
      }
      this.currentRating = this.rates[this.form.rate]
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return

      this.submit(valid, this.form)
    },
    send(data) {
      return ReviewCreate.execute({}, data)
        .then(({ data: res }) => {
          AnswerMessage(res, this)
          this.form.name = null
          this.form.phone = null
          this.form.comment = null
          if (this.promise) this.promise(true)
          this.$refs.validator.$el.reset()
          this.$refs.validator.reset()
          this.$emit('close')
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },

}
