//
//
//
//
//
//
//
//
//
//
//
//

import UiMixin from '@app/Filters/mixins/UiMixin'

import { getCategoriesByShopResource } from '@api/catalog.service'

export default {
  mixins: [UiMixin],
  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'prices',
    'pagination',
    'label',
  ],
  data() {
    return {
      search: '',
      search_filter: {
        label: 'search',
        title: this._('Поиск'),
        id: 'search_id',
      },
      categories: [],
      options: {
        slidesToScroll: 1,
        dots: false,
        preload: true,
        focusOnSelect: false,
        autoplay: false,
        arrows: true,
        prevArrow: '<i class="controls controls--variant_tiny controls--variant_centered controls--appearance_light controls-left mdi mdi-chevron-left"></i>',
        nextArrow: '<i class="controls controls--variant_tiny controls--variant_centered controls--appearance_light controls-right mdi mdi-chevron-right "></i>',
        loop: false,
        fade: false,
        variableWidth: true,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 6,
            },
          },
          {
            breakpoint: 1599,
            settings: {
              slidesToShow: 6,
            },
          },
        ],
      },
    }
  },
  created() {
    // this.getCategories()
  },
  methods: {
    async getCategories() {
      const {
        data: { items },
      } = await getCategoriesByShopResource.execute({ shop: this.label })
      this.categories = items
      let inst = null
      this.categories.push(...items)

      setTimeout(() => {
        inst = setInterval(() => {
          if (this.$refs.slider && this.$refs.slider.destroy) {
            this.$refs.slider.destroy()
            this.$nextTick(() => {
              this.$refs.slider.create()
            })
            setTimeout(() => {
              this.$refs.slider.destroy()
              this.$nextTick(() => {
                this.$refs.slider.create()
              })
            }, 200)
            clearInterval(inst)
          }
        }, 500)
      }, 500)
      // this.$refs.slider.destroy()
      // this.$nextTick(() => {
      //   this.$refs.slider.create()
      // })
    },
  },
}
