//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  searchCategoriesResource,
} from '@api/vendor.service'

import {
  friendlyUrlParser,
} from '@app/Filters/transformers'

const unique = (value, index, self) => self.findIndex(x => x.id === value.id) === index

// import {
//   outstand,
// } from '@utils/helpers'

export default {
  name: 'SearchCategoryView',
  data() {
    return {
      list: [],
      load: false,
    }
  },
  computed: {
    parentsCats() {
      return this.list.filter(({ props }) => !props.depth && props.active_product_count)
    },
  },
  mounted() {
    const data = friendlyUrlParser()
    const { search: [value] } = data
    this.getCats(value)
  },
  methods: {
    getCatByParent(id) {
      return [...new Set(this.list.filter(({ props }) => props.parent === id && props.active_product_count))]
    },
    async getCats(search) {
      this.load = true
      const { data: items } = (await searchCategoriesResource.execute({ search }))
      this.list = items.filter(unique)
      this.load = false
    },
  },
}
