//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import SetElement from '@app/Cart/Variants/SetElement.vue'

export default {
  name: 'ProductSimpleSetView',
  components: {
    SetElement,
  },
  props: {
    product: {
      default: () => { },
    },
    price: {
      default: '0.00',
    },
    isSale: {
      default: () => false,
    },
    load: {},
    disabled: {
      default: () => false,
    },
    canRemove: {
      default: () => false,
    },
  },
  data() {
    return {
      currency: window.currency,
    }
  },
  computed: {
    baseElement() {
      return this.product.element.props
    },
    setElement() {
      return this.product.element.props.set.props
    },
    minimalQuantity() {
      return Math.min(this.baseElement.product.props.stock_quantity, this.setElement.product.props.stock_quantity)
    },
    canChange() {
      // fix me
      return !this.disabled || !this.canRemove || !this.isSale
    },
  },
}
