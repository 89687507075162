import FilterUi from '@app/Filters/Ui'
import SearchChips from '@app/List/Chips'
import Sorting from '@app/Filters/Sorting'
import isShallowEqual from '@wordpress/is-shallow-equal'
import { mapActions, mapState } from 'vuex'

const RangeWithVariantsWidget = () => import('@app/Filters/Widgets/RangeWithVariantsWidget')

export default {
  props: [
    'loading',
    'value',
    'receive',
    'receivePrices',
    'all',
    'prices',
    'available',
    'receiveAvailable',
    'displayed',
    'pagination',
    'sortlist',
    'label',
  ],
  components: {
    FilterUi,
    SearchChips,
    Sorting,
    RangeWithVariantsWidget,
  },
  range: RangeWithVariantsWidget,
  data() {
    return {
      prepared: {},
      show_all: false,
      tiles: [
        {
          size: 9,
          text_size: 'nine',
        },
        {
          size: 16,
          text_size: 'sixteen',
        },
      ],
      windowWidth: 0,
    }
  },

  watch: {
    value: {
      immediate: true,
      handler({ filters = {} } = {}, { filters: oldFilters } = {}) {
        if (isShallowEqual(filters, oldFilters)) {
          return
        }

        this.prepare(filters)

        if (this.receiveAvailable) this.receiveAvailable({ filters })
      },
    },
  },

  computed: {
    ...mapState('vendor', [
      'view',
    ]),
    isDesktopView() {
      return 1200 < this.windowWidth
    },
    totalCount() {
      return Object.hasOwnProperty.call(this.pagination, 'total') ? this.pagination.total : this.value.total
    },
    filters() {
      return this.all
    },
    availableMap() {
      if (!this.available) {
        return {}
      }
      return this.available

      // Object.keys(this.available).reduce((acc, x) => {
      //   this.available[x].forEach(val => {
      //     acc[val] = val

      //   })
      //   return acc
      // }, {})
    },
  },
  created() {
    console.log('sidebar')
    if (this.needPricing) {
      this.receivePrices()
    }
    // TODO: uncomment when back will be done
    // this.receiveAvailable({})
    this.receive({ label: this.label, shop: this.value.shop, category: this.value.category })
    this.windowWidth = window.innerWidth
    // eslint-disable-next-line no-return-assign
    window.addEventListener('resize', () => this.windowWidth = window.innerWidth)
  },
  methods: {
    ...mapActions('vendor', [
      'trigger_view',
    ]),
    setTiles(el) {
      this.trigger_view({ size: el, isTiny: this.value?.tiny })
    },
    toggleFilters() {
      this.show_all = !this.show_all
    },
    prepare(value) {
      this.prepared = value
    },
    handleInputFilter(value) {
      this.handleInput(value)
      this.filter()
    },
    handleInput(value) {
      this.prepare(value)
      // TODO: uncomment when back will be done
      // this.receiveAvailable({ filters: this.prepared })
    },
    getMultiValue(key) {
      return {
        min_value: this.value.filters[`${key}_min`],
        max_value: this.value.filters[`${key}_max`],
      }
    },
    updateMultipleValues(key, values) {
      let res = {}
      Object.keys(values).forEach(k => {
        res = { ...res, [`${key}_${k}`]: values[k] }
      })
      console.log(res);
      this.handleInputFilter({ ...this.value.filters, ...res })
    },
    filter() {
      this.$emit('input', { ...this.value, filters: this.prepared, offset: 0 })
    },
  },
}
