import {
  createReceiver,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'store/statistics'

export const ORDER_STATISTIC_URL = prefixAPI('/orders/', MODEL)
export const REVIEW_STATISTIC_URL = prefixAPI('/reviews/', MODEL)

export const OrderStatistic = createReceiver(ORDER_STATISTIC_URL)
export const ReviewStatistic = createReceiver(REVIEW_STATISTIC_URL)
