
import debounce from 'lodash.debounce'

export default {
  name: 'MegaMenuWrap',
  props: ['parent', 'level', 'menu'],
  data() {
    return {
      list: [],
      activeId: null,
    }
  },
  created() {
    const delay = 200
    this.debounceGetCategories = debounce((parent, level) => {
      this.getCategories(parent, level)
    }, delay)
  },
  methods: {
    async getCategories(parent, level) {
      if (this.menu && this.menu.has(parent)) {
        this.list = this.menu.get(parent)
      }
      this.activeId = parent
      this.$emit('update', 0, this.list, level, parent)
    },
  },
  render() {
    return this.$scopedSlots.default({
      get: (parent, level) => this.debounceGetCategories(parent, level),
      active_id: this.activeId,
    })
  },
}
