//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BoundLabel',
  block: 'input-group',
  props: {
    id: {
      type: String,
      required: false,
    },
    value: {},
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
    showStar: {
      type: Boolean,
      default: true,
    },
  },
}
