//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const { authent } = window
export default {
  name: 'ComparisonProductView',
  authent,
  props: ['card', 'id', 'type'],
  computed: {
    inStock() {
      return 'available' === this.card.availability_status || 'confirmation_of_availability' === this.card.availability_status &&
        (!this.card.track_inventory || this.card.track_inventory && this.card.stock_quantity)
    },
  },
}
