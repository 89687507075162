import MenuDrawer from '@app/Menu/Drawer'
import MenuGetter from '@app/Menu/Getter'
import MenuChildView from '@app/Menu/ChildView'
import MenuLink from '@app/Menu/Link'

export default function install(Vue) {
  Vue.component(MenuDrawer.name, MenuDrawer)
  Vue.component(MenuGetter.name, MenuGetter)
  Vue.component(MenuChildView.name, MenuChildView)
  Vue.component(MenuLink.name, MenuLink)
}
