import { render, staticRenderFns } from "./clipper-basic.vue?vue&type=template&id=68f5c4bd&scoped=true&"
import script from "./clipper-basic.vue?vue&type=script&lang=js&"
export * from "./clipper-basic.vue?vue&type=script&lang=js&"
import style0 from "./clipper-basic.vue?vue&type=style&index=0&id=68f5c4bd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68f5c4bd",
  null
  
)

export default component.exports