//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MenuLink',
  props: ['item', 'action', 'url'],
  data() {
    return {
      lang: 'uk' === window.language,
    }
  },
  methods: {
    linkOpener(url) {
      if (!url) return
      window.location.href = url
    },
  },
}
