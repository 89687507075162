const Feedback = () => import('@app/Forms/Feedback')
const Subscription = () => import('@app/Forms/Subscription')
const FeedbackLarge = () => import('@app/Forms/FeedbackLarge')
const AddNewSeller = () => import('@app/Forms/AddNewSeller')
const FormGenerator = () => import('@app/Forms/FormGenerator')

export default function install(Vue) {
  Vue.component('Feedback', Feedback)
  Vue.component('FeedbackLarge', FeedbackLarge)
  Vue.component('AddNewSeller', AddNewSeller)
  Vue.component('FormGenerator', FormGenerator)
  Vue.component('Subscription', Subscription)
}
