
import { confirmWithPromise } from '@utils/helpers'
import { AnswerMessage } from '@utils/submit'
import { UserLogout } from '@api/auth.service'

export default {
  name: 'Logout',

  methods: {
    async logout() {
      const question = this._('Ви вже йдете?')
      await confirmWithPromise(question, this)
      // eslint-disable-next-line no-return-assign
      await UserLogout.execute().then(({ data }) => AnswerMessage(data, this))
    },
  },
  render() {
    return this.$scopedSlots.default({
      open: () => {
        this.logout()
      },
    })
  },
}
