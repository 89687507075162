import {
  createReceiver,
  createFileSender,
  createSender,
  createUpdater,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'store/importer'

export const IMPORT_RETRIVE_URL = prefixAPI('/retrieve/{id}/', MODEL)
export const IMPORT_TAG_CONFIGUTATION_LIST_URL = prefixAPI('/xml-tag-configuration/list/', MODEL)
export const IMPORT_CREATE_URL = prefixAPI('/create-request/', MODEL)
export const IMPORT_STOP_URL = prefixAPI('/actions/stop-updating/{id}/', MODEL)
export const IMPORT_START_URL = prefixAPI('/actions/start-updating/{id}/', MODEL)
export const IMPORT_RESTART_URL = prefixAPI('/actions/restart-updating/{id}/', MODEL)
export const IMPORT_LIST_URL = prefixAPI('/list/{?offset,limit,type}', MODEL)
export const IMPORT_PRODUCTS_LIST_URL = prefixAPI('/products/list/{?offset,limit,type,filters*}', MODEL)
export const IMPORT_PRODUCTS_IDS_LIST_URL = prefixAPI('/products/list-ids/{?offset,limit,type,filters*}', MODEL)
export const IMPORT_PRODUCTS_UPDATE_URL = prefixAPI('/products/update/{id}/', MODEL)
export const IMPORT_PRODUCTS_WITH_ERRORS_COUNT_URL = prefixAPI('/products/with-errors-count/{id}/', MODEL)
export const IMPORT_PRODUCTS_CREATE_URL = prefixAPI('/products/create/{id}/', MODEL)
export const IMPORT_PRODUCTS_CREATE_SINGLE_URL = prefixAPI('/products/create-single/{id}/', MODEL)
export const IMPORT_STATISTIC_URL = prefixAPI('/statistic/', MODEL)
export const IMPORT_PRODUCTS_BULK_UPDATE_URL = prefixAPI('/products/bulk-update/', MODEL)
export const IMPORT_PREFERENCES_RETRIEVE_URL = prefixAPI('/preferences/retrieve/{id}/', MODEL)
export const IMPORT_PREFERENCES_UPDATE_URL = prefixAPI('/preferences/update/{id}/', MODEL)

export const ImportRetrieve = createReceiver(IMPORT_RETRIVE_URL)
export const ImportCreate = createFileSender(IMPORT_CREATE_URL)
export const ImportList = createReceiver(IMPORT_LIST_URL)
export const ImportStatistic = createReceiver(IMPORT_STATISTIC_URL)
export const ImportTagConfigurationList = createReceiver(IMPORT_TAG_CONFIGUTATION_LIST_URL)
export const ImportStopResource = createSender(IMPORT_STOP_URL)
export const ImportStartResource = createSender(IMPORT_START_URL)
export const ImportRestartResource = createSender(IMPORT_RESTART_URL)
export const ImportProductBulkUpdate = createSender(IMPORT_PRODUCTS_BULK_UPDATE_URL)
export const ImportRequestProductsResource = createReceiver(IMPORT_PRODUCTS_LIST_URL)
export const ImportRequestProductsIdsResource = createReceiver(IMPORT_PRODUCTS_IDS_LIST_URL)
export const ImportRequestProductsWithErrorsCountResource = createReceiver(IMPORT_PRODUCTS_WITH_ERRORS_COUNT_URL)
export const ImportRequestProductsCreateResource = createSender(IMPORT_PRODUCTS_CREATE_URL)
export const ImportRequestProductsCreateSingleResource = createSender(IMPORT_PRODUCTS_CREATE_SINGLE_URL)
export const ImportRequestUpdateProductResource = createUpdater(IMPORT_PRODUCTS_UPDATE_URL)

export const ImportRequestPreferencesResource = createReceiver(IMPORT_PREFERENCES_RETRIEVE_URL)
export const ImportRequestPreferencesUpdateResource = createUpdater(IMPORT_PREFERENCES_UPDATE_URL)
