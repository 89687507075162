import {
  createReceiver,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'core/'

const TIPS_URL = prefixAPI('tips/', MODEL)

export const TipsListResource = createReceiver(TIPS_URL)
