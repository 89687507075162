/* eslint-disable camelcase */
import {
  retrieveCartEntity,
  changeCartEntity,
  clearCartEntity,
  retrieveCartInfo,
} from '@api/cart.service'

const SET_CART = 'SET_CART'
const SET_CART_INFO = 'SET_CART_INFO'

export function createStore() {
  return {
    namespaced: true,
    state: {
      cart: [{
        groups: [],
      }],
      cart_info: {
        quantity: 0,
        total_price: 0,
      },
    },
    getters: {
      getCartByShop: state => id => {
        const SINGLE_SHOP_CART = 1
        if (state.cart.length === SINGLE_SHOP_CART) {
          const [first] = state.cart
          return [first]
        }
        // eslint-disable-next-line eqeqeq
        return state.cart.filter(({ shop: { id: shopId } }) => shopId == id)
      },
    },
    actions: {
      async getCart({ commit }) {
        const { data } = (await retrieveCartEntity.execute())
        commit(SET_CART, { payload: data })
      },
      async changeCart({ commit }, payload) {
        return changeCartEntity.execute({}, payload)
          .then(async ({ data }) => {
            commit(SET_CART, { payload: data })
          })
      },
      async clearCart({ commit }) {
        await clearCartEntity.execute()
        commit(SET_CART, { payload: [] })
      },
      async getCartInfo({ commit }) {
        const { data } = (await retrieveCartInfo.execute())
        commit(SET_CART_INFO, data)
      },
    },
    mutations: {
      [SET_CART](state, payload) {
        state.cart = payload.payload

        if (!state.cart.length) return
        const { quantity, total_price } = state.cart.reduce((acc, obj) => {
          acc.quantity += obj.quantity
          acc.total_price += obj.total_price
          return acc
        }, { quantity: 0, total_price: 0 })
        // state.cart_info.total_price = state.cart.total_price
        state.cart_info.quantity = quantity
        state.cart_info.total_price = total_price
      },
      [SET_CART_INFO](state, payload) {
        state.cart_info = payload
        window.cart_total = state.cart_info.total_price
      },
    },
  }
}
