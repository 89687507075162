import {
  TipsListResource,
} from '@api/core.service'

const SET_TIPS_LIST = 'SET_TIPS_LIST'

export function createStore() {
  return {
    namespaced: true,
    state: {
      tips: {},
    },
    actions: {
      async getTipsList({ commit }) {
        const { data: { props } } = (await TipsListResource.execute())
        commit(SET_TIPS_LIST, props)
      },
    },
    mutations: {
      [SET_TIPS_LIST](state, payload) {
        state.tips = payload
      },
    },
  }
}
