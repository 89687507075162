//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ControllerMixin from '@app/Filters/mixins/ControllerMixin'
import SlimUi from '@app/Filters/Slim/Ui'

export default {
  mixins: [ControllerMixin],
  components: {
    SlimUi,
  },
}

