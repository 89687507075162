//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

export default {
  name: 'element-input',
  props: {

    inputClass: {},
    labelComponent: {},
    id: {},
    value: {},
    labelText: {},
    inputLabel: {},
    required: {},
    providerContext: {},
    type: {},
    element: {},
    showStar: {
      default: true,
    },
  },
  block: 'd-control-input',
  data() {
    return {
      input: '',
      show: false,
    }
  },
  watch: {
    value: {
      handler() {
        this.input = this.value
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    eSlots() {
      return getESlots(Object.keys(this.$scopedSlots))
    },
    listeners() {
      return {
        ...this.$listeners,
        input: $event => this.$emit('input', this.getEvent($event)),
      }
    },
    model: {
      get() {
        return this.input
      },
      set(e) {
        this.input = e
      },
    },
  },
  methods: {
    getEvent($event) {
      if ($event && $event.timeStamp) {
        return $event.target[this.getKey()]
      }
      return $event
    },
    getKey() {
      return 'checkbox' === this.type ? 'checked' : 'value'
    },
    changeType() {
      this.show = !this.show
      if (this.show) {
        this.$refs.input.type = 'text'
        return
      }
      this.$refs.input.type = this.type
    },
  },
}
