import install2Factor from '@app/2Factor'
import installAuth from '@app/Auth'
import installCart from '@app/Cart'
import install from '@app/Controls'
import installComparison from '@app/Comparison'
import installForms from '@app/Forms'
import installFilters from '@app/Filters'
import installGallery from '@app/Gallery'
import installLikes from '@app/Likes'
import installList from '@app/List'
import installMenu from '@app/Menu'
import installOrder from '@app/Order'
import installPagination from '@app/Pagination'
import installPjax from '@app/Pjax'
import installPurchase from '@app/Purchase'
import installReview from '@app/Review'
import installRouting from '@app/Routing'
import installSearch from '@app/Search'
import installTracking from '@app/ProductTracking'
import installUi from '@app/UiElements'
import installI from '@app/I'

export default function plugin(Vue) {
  Vue.use(install)
  Vue.use(install2Factor)
  Vue.use(installAuth)
  Vue.use(installCart)
  Vue.use(installComparison)
  Vue.use(installFilters)
  Vue.use(installForms)
  Vue.use(installGallery)
  Vue.use(installList)
  Vue.use(installLikes)
  Vue.use(installMenu)
  Vue.use(installOrder)
  Vue.use(installPagination)
  Vue.use(installPjax)
  Vue.use(installPurchase)
  Vue.use(installRouting)
  Vue.use(installReview)
  Vue.use(installSearch)
  Vue.use(installTracking)
  Vue.use(installUi)
  Vue.use(installI)
}
