import {
  createSender,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'chat/'

const ADD_CHAT_QUESTION = prefixAPI('cabinet/create', MODEL)
const ADD_CHAT = prefixAPI('create/', MODEL)

export const AddChatQuestion = createSender(ADD_CHAT_QUESTION)
export const AddChat = createSender(ADD_CHAT)
