//
//
//
//
//
//
//

import {
  getSellerCategories,
} from '@api/store.service'

import { mapActions } from 'vuex'

export default {
  name: 'UiSellerCatalog',
  getSellerCategories,
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      catalog: [],
      loading: false,
      error: null,
      show: false,
      array: [],
    }
  },
  async created() {
    await this.getMenu()
    this.getList()
  },
  methods: {
    ...mapActions('menu', [
      'getMenu',
      'addActiveChild',
      'setLoad',
    ]),
    async getList() {
      const { data } = (await getSellerCategories.execute({ id: this.id }))
      this.catalog = data
      this.array = (await this.addActiveChild(this.catalog))
      // const menuWrap = document.getElementById('fat-menu-seller')
      // const menu = Array.from(menuWrap.firstElementChild.children)
      // menu.forEach(element => {
      //   const m = r.find(e => e.id === Number(element.dataset.id))
      //   if (m && !m.is_filterable) {
      //     element.style.display = 'none'
      //   }
      // })
      // r.forEach(element => {
      //   console.dir(menu);
      //   console.log(element);
      // });
    },
    open() {
      console.log('open');
      this.setLoad(true)
      this.$root.isLoadMenu = true
      this.$nextTick(() => {
        this.setLoad(true)
        setTimeout(() => {
          const menuWrap = document.getElementById('seller-menu-content')
          const menu = Array.from(menuWrap.lastChild.lastElementChild.children)
          menu.forEach(element => {
            const m = this.array.find(e => e.id === Number(element.firstChild.dataset.id))
            if (m && !m.is_filterable) {
              element.style.display = 'none'
            }
          })
          setTimeout(() => {
            this.$root.isLoadMenu = false
            this.setLoad(false)
          }, 200)
        }, 500)
      })
      // this.show = !this.show
      // console.log(this.show);
      // const menuWrap = document.getElementById('fat-menu-seller')
      // const overlay = document.getElementById('menu-overlay')
      // const h = document.getElementById('header-wrap')
      // if (this.show) {
      //   menuWrap.classList.add('is-active')
      //   overlay.classList.remove('is-hidden')
      //   menuWrap.style.top = `${h.offsetTop + h.offsetHeight}px`
      //   return
      // }
      // menuWrap.classList.remove('is-active')
      // overlay.classList.add('is-hidden')
    },
  },
}
