//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapState,
} from 'vuex'

export default {
  name: 'ReviewSellerDetail',
  props: ['review', 'replier', 'info'],
  computed: {
    ...mapState('staff', [
      'user',
    ]),
    collectData() {
      return {
        product_type: this.review.props.product.type,
        product_id: this.review.props.product.id,
        reply_name: this.review.props.name,
        reply: this.review.props.id,
        id: this.review.props.id,
        user_name: `${this.user.props.first_name} ${this.user.props.last_name}`,
        user_email: this.user.props.email,
      }
    },
  },
  methods: {
    photoMap(arr) {
      return arr.map(el => el.props.image)
    },
  },
}
