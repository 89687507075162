//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MenuChildView',
  props: ['list', 'parent_uk', 'parent_ru', 'showChild', 'url'],
  data() {
    return {
      lang: 'uk' === window.language,
    }
  },
  methods: {
    linkOpener(url) {
      window.location.href = url
    },
    doNothing() {
      return null
    },
    getUrl(base) {
      if (this.url) {
        return `${this.url}${base.label}/`
      }
      return base.url
    },
    goTo(item) {
      if (!this.showChild) {
        window.location.href = this.getUrl(item)
        return
      }
      if (this.is_linked) {
        window.location.href = this.getUrl(item)
      }
    },
  },
}
