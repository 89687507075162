import UiMessageModal from '@app/UiElements/UiMessageModal'
import {
  redirectPolicy,
} from '@utils/redirect'

export const AnswerMessage = (meta, $this) => {
  console.log(meta);
  if (meta.messages) {
    const { timeout } = meta
    const [firstMessage] = meta.messages
    console.log(firstMessage);
    const result = { ...firstMessage, ...meta.redirect }
    $this.$modal.show(
      UiMessageModal,
      {
        meta: result,
        timeout,
      }, {
        height: 'auto',
        minWidth: 300,
        maxWidth: 370,
        adaptive: true,
      }
    )
  } else if (meta.redirect && meta.redirect.location) {
    redirectPolicy(meta.redirect.location)
    // window.location.pathname = meta.redirect.location
  }
}
