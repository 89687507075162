// export const MOBILEREG = /^[+()-\d]+$/
export const MOBILEREG = /^\+38(0\d{9})$/

export const CYRILLIC_CHARS = /^[а-яієїґА-ЯҐЄІЇ\s]+$/

export const EMAILREG = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const CODE_VARIANTS_LENGTH = [
  8,
  10,
  12,
]

export const PASSWORD_MIN_LENGTH = 7
export const PASSWORD_REG = /^(?=.*?[A-ZА-Я])(?=.*?[a-zа-я])(?=.*?[0-9]).*$/;
