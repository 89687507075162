/**
 * get cookie
 * @param {*} name
 */
export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(
        /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
        '\\$1'
      )}=([^;]*)`
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

const bakeCookie = (name, value, days) => {
  const now = new Date()
  now.setDate(days)
  const cookie = [name, '=', JSON.stringify(value), ';expires', '=', now.toUTCString(), '; path=/;'].join('')
  document.cookie = cookie
}
const ONE_MOUNTH_DAYS_COUNT = 30
/**
 * set cookie without expired date
 * @param {*} name
 * @param {*} val
 */
export function setCookie(name, val, days = ONE_MOUNTH_DAYS_COUNT) {
  // if (this.getCookie(name) ) return
  bakeCookie(name, val, days)
}
