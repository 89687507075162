
export default {
  name: 'GalleryProductView',
  props: {
    images: Array,
  },
  data() {
    return {
      activeImageIndex: 0,
    }
  },
  computed: {
    activeImage() {
      return this.images[this.activeImageIndex]
    },
  },
  methods: {
    setActiveIndex(index) {
      this.activeImageIndex = index
    },
  },
  render() {
    return this.$scopedSlots.default({
      active_index: this.activeImageIndex,
      set_active_index: this.setActiveIndex,
      active_image: this.activeImage,
    })
  },

}
