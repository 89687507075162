import {
  getComparisonList,
  getComparisonIdsList,
} from '@api/comparison.service'

const ADD_TO_COMPARISON = 'ADD_TO_COMPARISON'
const SET_LIST = 'SET_LIST'
const SET_IDS_LIST = 'SET_IDS_LIST'

export function createStore() {
  return {
    namespaced: true,
    state: {
      comparison_ids: [],
      list: [],
    },
    getters: {
      getCount: state => state.comparison_ids.length,
    },
    actions: {
      addId({ commit }, payload) {
        commit(ADD_TO_COMPARISON, payload)
      },
      async getList({ commit }, payload) {
        const { data: { items } } = (await getComparisonList.execute({
          category: payload,
          limit: 20,
        }))
        commit(SET_LIST, items)
      },
      async getComparisonList({ commit }) {
        const items = (await getComparisonIdsList.execute())
        commit(SET_IDS_LIST, items)
      },
    },
    mutations: {
      [ADD_TO_COMPARISON](state, payload) {
        state.comparison_ids.push(payload)
      },
      [SET_LIST](state, payload) {
        state.list = payload
        state.comparison_ids = state.list.map(el => el.id)
      },
      [SET_IDS_LIST](state, payload) {
        state.comparison_ids = payload
      },
    },
  }
}
