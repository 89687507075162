import { renderSlim } from '@aspectus/vue-utils';
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin';
import cancellablePromiseProxy from '@aspectus/cancellable-promise-proxy';
import { receiveResource, createResource } from '@resource/resource'

const createChecker = (callback, promise) => value => callback(value, promise);

export default {
  name: 'resource-loader-controller',
  mixins: [LoadingStateMixin],

  props: {
    resource: {},
    resourceUrl: {},
    immediate: {},
    params: {},
  },

  data() {
    return {
      result: null,
      currentRequest: null,
    };
  },
  created() {
    if (this.immediate) this.receive(this.params)
  },
  methods: {
    applyResult(result, promise) {
      if (this.$options.promise !== promise) {
        return;
      }
      if (result.code) {
        this.result = result.data
        return
      }
      this.result = result;
      this.$emit('result', result);
    },
    receiveWithCancel(parameters) {
      if (this.currentRequest) this.currentRequest.cancel()
      const cancelController = new AbortController();
      const base = receiveResource.config('signal', cancelController.signal)
      const resource = createResource(this.resourceUrl, base)
      return new Promise((res, rej) => {
        this.currentRequest = cancellablePromiseProxy(
          new Promise((resolve, reject) => {
            this.startLoading()
            resource.execute(parameters)
              .then(resolve, reject);
          }),
          { cancelController }
        ).then(response => {
          this.applyResult(response)
          this.stopLoading()
          this.stopLoading()
          res(response)
        }, err => rej(err))
      })
    },
    receive(parameters) {
      if (this.resourceUrl) return this.receiveWithCancel(parameters)
      if (this.$options.promise && this.$options.promise.cancel) {
        this.$options.promise.cancel();
      }
      const promise = this.resource.execute(parameters);
      this.$options.promise = promise;

      this.$nextTick(() => document.dispatchEvent(new Event('data:load')))

      return this.$load(promise.then(createChecker(this.applyResult, promise)));
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      loading: this.loading,
      result: this.result,
      receive: this.receive,
    }), h, 'tag');
  },
};
