//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SetElement',
  props: ['element', 'hasOldPrice', 'price'],
  data() {
    return {
      currency: window.currency,
    }
  },
}
