import installer from '@aspectus/vue-route-installer'

import {
  createRoutes as createUserRoutes,
  DASHBOARD_VIEW_NAME,
} from '@app/Buyer/routes'

import {
  route,
  prefixRoutes,
} from '@app/Routing/helpers'

const CabinetInstaller = () => import('@app/Buyer')
const Cabinet = () => import('@app/Buyer/Views/Cabinet')

// const BASE_CABINET = 'client/'

export function createRoutes(options) {
  const { Vue } = options
  return [
    route('', installer(Cabinet, CabinetInstaller, Vue), {
      redirect: {
        name: DASHBOARD_VIEW_NAME,
      },
    }, {
      children: [
        prefixRoutes('', createUserRoutes(options), {}),
        // { path: '', redirect: BASE_CABINET },
      ],
    }),
  ]
}
