//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  ClaimTypeList,
} from '@api/vendor.service'

export default {
  name: 'ClaimType',
  props: ['value'],
  keyGetter: x => `${x.type}:${x.id}`,
  data() {
    return {
      type: [],
      list: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { data } = (await ClaimTypeList.execute())
      this.list = data
    },
    getValue(value, model) {
      return model.find(el => el.id === value.id)
    },
  },
}
