import Login from '@app/Auth/Login'
import OrderLogin from '@app/Auth/OrderLogin'
import Logout from '@app/Auth/Logout'
import PasswordRecoveryConfirm from '@app/Auth/PasswordRecoveryConfirm'
import ResendTimer from '@app/Auth/ResendTimer'
import SellerRegister from '@app/Auth/Seller/Register'

export default function install(Vue) {
  Vue.component('Login', Login)
  Vue.component('Logout', Logout)
  Vue.component('PasswordRecoveryConfirm', PasswordRecoveryConfirm)
  Vue.component('OrderLogin', OrderLogin)
  Vue.component('SellerRegister', SellerRegister)
  Vue.component('ResendTimer', ResendTimer)
}
