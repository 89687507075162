//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ProductBaseView',
  props: {
    product: {
      default: () => { },
    },
    price: {
      default: '0.00',
    },
    isSale: {
      default: () => false,
    },
    load: {},
    disabled: {
      default: () => false,
    },
    canRemove: {
      default: () => false,
    },
  },
  data() {
    return {
      currency: window.currency,
    }
  },
  computed: {
    canChange() {
      // fix me
      return !this.disabled || !this.canRemove || !this.isSale
    },
    inStock() {
      return 'available' === this.product.element.props.availability_status || 'confirmation_of_availability' === this.product.element.props.availability_status &&
        (!this.product.element.props.track_inventory || this.product.element.props.track_inventory && this.product.element.props.stock_quantity)
    },
  },
}
