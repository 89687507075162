//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NativeSorting from '@app/Filters/NativeSorting'

export default {
  name: 'Sorting',
  components: {
    NativeSorting,
  },
  props: [
    'disabled',
    'value',
    'values',
    'filters',
    'available',
  ],
  data() {
    return {
      defaultValues: [
        {
          title: this._('имени (А-Я)'),
          key: 'name',
        },
        {
          title: this._('имени (Я-А)'),
          key: '-name',
        },
        {
          title: this._('популярности'),
          key: '-rating',
        },
        {
          title: this._('новинки'),
          key: '-date',
        },
      ],
      checked: {},
      show: false,

    }
  },

  watch: {
    value(nval) {
      this.checked = this.normalize(nval)
    },
  },
  mounted() {
    this.checked = this.normalize(this.value)
  },
  methods: {
    submit() {
      this.$emit('input', [this.checked])
    },
    notAvailable(id) {
      const { length } = Object.keys(this.available)
      let answer = false
      if (length) {
        if (this.available[id]) {
          answer = false
        } else {
          answer = true
        }
      } else {
        answer = false
      }
      return answer
    },
    normalize(value) {
      if (!value) return []
      const checked = this.values.find(el => {
        if (el.key === value[0]) {
          return el
        }
        return false
      })
      return checked.key
    },
  },
}
