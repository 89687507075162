
import { mapActions } from 'vuex'
import CartView from '@app/Cart/View'
import ModalWrapper from '@app/Forms/ModalWrapper'

import {
  AnswerMessage,
} from '@utils/submit'

export default {
  name: 'AddToCart',
  props: {
    id: {
      type: [String, Number],
    },
    type: {
      type: String,
    },
    amount: {
      type: [String, Number],
    },
    price: {},
    name: {},
    maxCount: {},
  },
  methods: {
    ...mapActions('cart', [
      'changeCart',
    ]),
    async addToCart() {
      const data = {
        element: {
          id: this.id,
          type: this.type,
        },
        quantity: this.amount,
      }
      const entities = []
      entities.push(data)
      try {
        await this.changeCart({ entities })
        this.openCart()
        this.$emit('success')
      } catch (e) {
        const meta = {
          messages: [{
            title: this._('Максимальна кількость товарів перевищена. Даний товар вже є у кошику'),
            // eslint-disable-next-line prefer-template
            text: this._('Цей Товар можна замовити лише у кількості') + ' ' + this.maxCount + ' ' + this._('шт.'),
          }],
          timeout: 5000,
        }
        AnswerMessage(meta, this)
      }
    },
    openCart() {
      this.$modal.show(
        ModalWrapper,
        {
          info: {
            tag: CartView,
          },
        }, {
          height: 'auto',
          classes: ['cart-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
  render() {
    return this.$scopedSlots.default({
      add: () => {
        this.addToCart()
      },
    })
  },
}
