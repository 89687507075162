//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LoaderBtn',
  props: {
    action: {
      type: [Function, Promise],
      required: true,
      default: () => () => {},
    },
    load: {
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
  },
}
