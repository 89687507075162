
// eslint-disable camelcase
import {
  mapActions,
} from 'vuex'

export default {
  name: 'ProductPjaxTriggers',
  props: ['tabs', 'path'],
  data() {
    return {
      current: this.init,
      link: '',
    }
  },
  created() {
    this.link = this.tabs.find(e => e.href === this.path)
    this.current = this.link.slug
  },
  methods: {
    ...mapActions('pjax', [
      'getContent',
    ]),
    getCurrent(slug) {
      this.link = this.tabs.find(e => e.slug === slug)
      this.current = this.link.slug
      window.history.pushState(
        {},
        null,
        this.link.href
      )
    },
    async get(label, template) {
      this.getCurrent(template)
      // eslint-disable-next-line camelcase
      await this.getContent({ label, filter_template: template })
    },
    hasActive(slug) {
      if (slug === this.current) return 'is-active'
      return ''
    },
  },
  render() {
    return this.$scopedSlots.default({
      get: this.get,
      current: this.current,
      hasActive: this.hasActive,
    })
  },
}
