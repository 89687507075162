import {
  createReceiver,
  baseResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

import {
  friendlyUrlGenerator,
} from '@aspectus/vue-friendly'

const ONLY_ATTRIBUTES_URL = prefixAPI('attributes/filter-list/')
export const onlyAttributesResource = createReceiver(ONLY_ATTRIBUTES_URL)

const PRICES_FILTERS_URL = prefixAPI('store/price-filter/list/')
export const pricesResource = createReceiver(PRICES_FILTERS_URL)

const ATTRIBUTES_URL = prefixAPI('store/catalog-attributes/{?category,is_comparison,shop}')
export const attributesResource = createReceiver(ATTRIBUTES_URL)

const BRANDS_ATTRIBUTES_URL = prefixAPI('store/brand-filters/')
export const brandsAttributesResource = createReceiver(BRANDS_ATTRIBUTES_URL)

const CATEGORIES_URL = prefixAPI('store/categories/list/{?offset,limit,parent,level}')
export const categoriesResource = createReceiver(CATEGORIES_URL)

const CATEGORIES_FOR_MENU_URL = prefixAPI('store/categories/list/menu/{?offset,limit,parent,level}')
export const categoriesForMenuResource = createReceiver(CATEGORIES_FOR_MENU_URL)

const BRANDS_URL = prefixAPI('store/brands/list/')
export const brandsResource = createReceiver(BRANDS_URL)

const ATTRIBUTE_VALUES_URL = prefixAPI('store/catalog-attributes/search/{id}/{?search,category}')

const AVAILABLE_ATTRIBUTES_URL = '/catalog/ajax/available-attributes/'
export const availableAttributesResource =
  // createReceiver(AVAILABLE_ATTRIBUTES_URL)
  friendlyUrlGenerator([AVAILABLE_ATTRIBUTES_URL], baseResource)

const order = {
  path: [
    'prefix',
    'label',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}
const orderSeller = {
  path: [
    'prefix',
    'label',
    'category',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}

const FILTERS_LIST_URL_PREFIX = '/catalog/'
const FILTERS_LIST_URL_POSTFIX = '/ajax/filters/'
export const productsListUrl =
  friendlyUrlGenerator([FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX], baseResource, order)

const SEARCH_FILTERS_LIST_URL_PREFIX = '/search/'
export const searchListUrl =
  friendlyUrlGenerator([SEARCH_FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX], baseResource, order)

const SHOP_FILTERS_LIST_URL_PREFIX = '/shop/'
export const shopProductListUrl =
  friendlyUrlGenerator([SHOP_FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX], baseResource, orderSeller)

const SHOP_CATERORIES_URL = prefixAPI('store/categories/list/{?shop}')
export const getCategoriesByShopResource = createReceiver(SHOP_CATERORIES_URL)

export const getAttributeValuesResource = createReceiver(ATTRIBUTE_VALUES_URL)
