
import Vue from 'vue'

function RenderComponent(template) {
  const renderer = Vue.compile(template)
  return {
    render: renderer.render,
    staticRenderFns: renderer.staticRenderFns,
  }
}

export default {
  name: 'Renderer',
  props: ['result', 'dataRes'],
  data() {
    return {
      component: '',
      False: false,
      True: true,
      None: undefined,
    }
  },
  watch: {

    result: {
      handler(nval) {
        if (nval) {
          if ('object' === typeof nval) {
            this.component = nval.val
            return
          }
          const value = nval
            .replaceAll('[[', '{{')
            .replaceAll(']]', '}}')
            .replaceAll('True', true)
            .replaceAll('False', false)
            .replaceAll('None', undefined)
          this.component = RenderComponent(value)
        }
      },
      immediate: true,
      deep: true,
    },
  },
  render(h) {
    if (this.result) {
      return h(
        'component', {
          is: this.component,
          props: { dataResult: this.dataRes },
        }
      )
    }
    return h('div', this.$slots.render)
  },
}

