//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  getCookie,
} from '@utils/cookies'

export default {
  name: 'Cropper',
  props: {
    promise: {

    },
    type: {
      default: () => 'avatar',
    },
    width: {
    },
    height: {},
    userId: {},
    method: {},
  },
  data() {
    return {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      show: true,
      lang: window.language,
      urls: {
        avatar: '/api/v1/staff/user/avatar/',
        event: '/api/v1/event/images/create/',
        doc: '/api/v1/staff/child/document-photo/',
        docForChild: `/api/v1/staff/child/document-photo/${this.$route.params.id}/`,
      },
    }
  },
  methods: {
    cropSuccess(imgDataUrl) {
      this.userImage = imgDataUrl
    },
    cropUploadSuccess(jsonData) {
      this.promise(jsonData)
      this.$emit('close')
    },
    cropUploadFail(status, field) {
      console.log(status, field)
      this.promise(false)
      this.$emit('close')
    },
  },
}
