//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  ReviewCreate,
} from '@api/vendor.service'

import {
  AnswerMessage,
} from '@utils/submit'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'UserReview',
  mixins: [FormMixin],
  props: {
    info: {

    },
    promise: {},
  },
  data() {
    return {
      placeholder: this._('Поделитесь впечатлением о специалисте. Будьте объективны, ваш отзыв поможет другим пользователям выбрать подходящего специалиста'),
      labels: {
        name: this._('Ваше ім‘я '),
        phone: this._('Ваш номер телефону'),
        comment: this._('Ваше враження'),
      },
      form: {
        comment: this.info.reply_name ? `${this.info.reply_name}, ` : '',
        name: '',
        phone: '',
        element: {
          type: this.info.product_type,
          id: this.info.product_id,
        },
        parent: this.info.id,
        reply: this.info.reply,
      },
      non_field_errors: '',
      isLoad: false,
    }
  },
  methods: {

    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return

      this.submit(valid, this.form)
    },
    send(data) {
      return ReviewCreate.execute({}, data)
        .then(({ data: res }) => {
          this.$emit('close')
          AnswerMessage(res, this)
          this.form.name = null
          this.form.phone = null
          this.form.comment = null
          this.$refs.validator.$el.reset()
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },

}
