//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  mapState,
  mapActions,
} from 'vuex'

import OrderLogin from '@app/Auth/OrderLogin'
import NewUser from './Parts/NewUser'

const WIDGETS_MAP = {
  login: OrderLogin,
  new_user: NewUser,
}

export default {
  name: 'OrderPersonal',
  widgetsMap: WIDGETS_MAP,
  props: ['fields'],
  data() {
    return {
      authUser: window.authent,
      isAuth: false,
      isOldUser: false,
      tabs: [
        {
          key: 'new',
          title: this._('Новый клиент'),
          tag: 'new_user',
          condition: () => this.isLegal,
        },
        {
          key: 'old',
          title: this._('Постоянный клиент'),
          tag: 'login',
          condition: () => this.isOldUser && !this.isAuth,
        },
      ],
      form: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        middle_name: '',
      },

    }
  },
  created() {
    this.getUser(false)
  },
  computed: {
    ...mapState('staff', [
      'user',
    ]),
    isLegal() {
      const result = this.isOldUser && !this.isAuth
      return !result
    },
  },
  methods: {
    ...mapActions('staff', [
      'getUserInfo',
    ]),
    simulate(event) {
      const data = Object.assign(this.form, event)
      this.$emit('update', { customer_info: data })
    },
    async getUser(value) {
      this.isOldUser = value
      try {
        await this.getUserInfo()
        this.isAuth = true
        Object.keys(this.form).forEach(el => {
          this.form[el] = this.user.props[el]
        })
        this.isOldUser = true
      } catch (e) {
        this.isAuth = false
      }
    },
  },
}
