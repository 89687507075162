//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  ReviewListResoure,
} from '@api/vendor.service'

import AddReview from '@app/Review/Add'

export default {
  name: 'ReviewList',
  props: {
    product_type: {
      type: String,
    },
    product_id: {
      type: String,
    },
    resource: {
      default: () => ReviewListResoure,
    },
    shop: {
      type: String,
    },
  },
  data() {
    return {
      list: {},
      offset: 0,
      limit: 3,
      meta: {},
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const params = {
        type: this.product_type,
        id: this.product_id,
        offset: this.offset,
      }
      const { data: { items, pagination } } = (await this.resource
        .execute(params))
      this.list = items
      this.meta = pagination
    },
    toPromise(result) {
      if (!result) return Promise.reject()
      return Promise.resolve(result)
    },
    reply(id, parentId, name) {
      const pr = f => this.toPromise(f)
        .then(() => this.getList())
      this.$modal.show(
        AddReview,
        {
          info: {
            product_type: this.product_type,
            product_id: this.product_id,
            reply_name: name,
            reply: parentId,
            id,
            ...this.$attrs,
          },
          promise: pr,
        }, {
          height: 'auto',
          // classes: ['extralarge-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
}
