import UserReview from '@app/Review/UserReview'
import UserList from '@app/Review/UserList'
import ReviewAdd from '@app/Review/Add'
import ReviewSlimAdd from '@app/Review/SlimAnswer'
import ReviewsMedia from '@app/Review/Media'
import ReviewList from '@app/Review/List'
import ClaimType from '@app/Review/ClaimTypes'
import Liker from '@app/Review/Liker'
import Detail from '@app/Review/Detail'
import SellerDetail from '@app/Review/SellerDetail'

export default function install(Vue) {
  Vue.component(Detail.name, Detail)
  Vue.component(SellerDetail.name, SellerDetail)
  Vue.component(Liker.name, Liker)
  Vue.component(ClaimType.name, ClaimType)
  Vue.component(UserList.name, UserList)
  Vue.component(UserReview.name, UserReview)
  Vue.component(ReviewAdd.name, ReviewAdd)
  Vue.component(ReviewList.name, ReviewList)
  Vue.component(ReviewsMedia.name, ReviewsMedia)
  Vue.component(ReviewSlimAdd.name, ReviewSlimAdd)
}
