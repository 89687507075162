
import { mapState, mapActions } from 'vuex'

export default {
  name: 'CartPreview',
  props: {
    needRequest: {
      default: true,
    },
  },
  computed: {
    ...mapState('cart', [
      'cart_info',
    ]),
  },
  created() {
    if (this.needRequest) {
      this.getCartInfo()
    }
  },
  methods: {
    ...mapActions('cart', [
      'getCartInfo',
    ]),
  },
  render() {
    return this.$scopedSlots.cart({
      quantity: this.cart_info.quantity || 0,
      summ: this.cart_info.total_price || '0.00',
    })
  },
}
