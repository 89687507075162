import {
  createReceiver,
  createSender,
  createUpdater,
  createReplacer,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'chat'

const p = postfix => MODEL + postfix

const MESSAGE_MODEL = p('/messages')

export const ROOM_LIST_URL = prefixAPI('/list/{?filters*,cursor,limit,type}', MODEL)

export const MESSAGES_LIST_URL = prefixAPI('/list/{id}/{?limit,cursor}', MESSAGE_MODEL)
export const MESSAGE_CREATE_URL = prefixAPI('/create/', MESSAGE_MODEL)
export const MESSAGE_DELETE_URL = prefixAPI('/delete/{id}/', MESSAGE_MODEL)
export const MESSAGE_UPDATE_URL = prefixAPI('/update/{id}/', MESSAGE_MODEL)
export const MESSAGE_READ_URL = prefixAPI('/read/{id}/', MESSAGE_MODEL)
export const UNREAD_MESSAGES_COUNT_URL = prefixAPI('/unread-count/', MESSAGE_MODEL)

export const getRoomList = createReceiver(ROOM_LIST_URL)

export const getMessagesList = createReceiver(MESSAGES_LIST_URL)
export const createMessage = createSender(MESSAGE_CREATE_URL)
export const deleteMessage = createUpdater(MESSAGE_DELETE_URL)
export const updateMessage = createUpdater(MESSAGE_UPDATE_URL)
export const readMessage = createReplacer(MESSAGE_READ_URL)
export const unreadMessagesCount = createReceiver(UNREAD_MESSAGES_COUNT_URL)
