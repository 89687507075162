//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex'
import CartProduct from '@app/Cart/Product'
import CartProductUi from '@app/Cart/ProductUi'

export default {
  name: 'OrderCart',
  props: ['shop'],
  components: {
    CartProduct,
    CartProductUi,
  },
  data() {
    return {
      currency: window.currency,
    }
  },
  computed: {
    ...mapState('cart', [
      'cart',
    ]),
    ...mapGetters('cart', [
      'getCartByShop',
    ]),
  },
  async mounted() {
    await this.getCart()
  },
  methods: {
    ...mapActions('cart', [
      'getCart',
    ]),
  },
}
