//
//
//
//
//
//
//
//

import {
  redirectPolicy,
} from '@utils/redirect'

export default {
  name: 'UiMessageModal',
  props: ['meta', 'promise', 'timeout'],
  data() {
    return {
      redirectTime: this.timeout || 0,
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.promise !== undefined) {
        this.promise('true')
      }
      if (this.meta.location) {
        redirectPolicy(this.meta.location)
      }
      this.$emit('close')
    }, this.redirectTime)
  },
}
