export default {
  name: 'CounterController',
  props: {
    min: {
      default: 1,
    },
  },
  data() {
    return {
      amount: this.min,
    }
  },
  methods: {
    changeAmount(val) {
      this.amount = Number(val.count)
    },
  },
  render() {
    return this.$scopedSlots.default({
      amount: this.amount,
      change: val => this.changeAmount(val),
    })
  },
}
