
/* eslint-disable consistent-return */
import Login from '@app/Auth/Login'
import {
  waitingListAddResource,
  waitingListRemoveResource,
} from '@api/waitinglist.service'
import {
  confirmWithPromise,
} from '@utils/helpers'
import {
  mapGetters,
  mapActions,
} from 'vuex'

import {
  WAITINGLIST_LIST,
} from '@app/Buyer/routes'

export default {
  name: 'add-tracking',
  props: {
    isAuth: Boolean,
    id: String,
    isActive: Boolean,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('waitinglist', {
      ids: 'getIds',
    }),
    isTracked() {
      return this.ids.includes(this.id)
    },
  },
  methods: {
    ...mapActions('waitinglist', [
      'getWaitingListIds',
    ]),
    triggerTracking() {
      if (!this.isAuth) {
        this.openAuthModal()
      } else {
        this.add()
      }
    },
    add() {
      if (this.isTracked) {
        window.location.href = `/buyer/${WAITINGLIST_LIST}/`
        return
      }
      return waitingListAddResource.execute({}, { products: this.returnArray(this.id) })
        .then(() => {
          this.getWaitingListIds()
        })
    },
    async remove() {
      const question = this._('Удалить товар из списка отслеживаний?')
      await confirmWithPromise(question, this)
      const data = {
        products: this.returnArray(this.id),
      }
      return waitingListRemoveResource.execute({}, data)
        .then(() => {
          window.location.reload()
        })
    },
    returnArray(id) {
      return Array.isArray(id) ? id : [id]
    },
    openAuthModal() {
      this.$modal.show(
        Login,
        {
          info: this.$attrs,
        }, {
          height: 'auto',
          name: 'login',
          minWidth: 300,
          classes: 'defaultModal',
          adaptive: false,
          scrollable: true,
        }
      )
    },
  },
  render() {
    return this.$scopedSlots.default({
      add: this.triggerTracking,
      remove: () => this.remove(),
      active: this.isTracked,
    })
  },
}
