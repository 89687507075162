//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const RATE_TERRIBLE = 'terrible'
const RATE_BAD = 'bad'
const RATE_OKAY = 'okay'
const RATE_GOOD = 'good'
const RATE_GREAT = 'great'

const VALUES = [
  RATE_TERRIBLE,
  RATE_BAD,
  RATE_OKAY,
  RATE_GOOD,
  RATE_GREAT,
]

const RATES = {
  [RATE_TERRIBLE]: 1,
  [RATE_BAD]: 2,
  [RATE_OKAY]: 3,
  [RATE_GOOD]: 4,
  [RATE_GREAT]: 5,
}

function getRate(rate) {
  const [r] = Object.entries(RATES).find(([, value]) => value === rate)
  return r
}

export default {
  name: 'Liker',
  props: ['init', 'promise'],
  VALUES,
  RATES,
  RATE_TERRIBLE,
  data() {
    return {
      reaction: getRate(this.init || 3),
      offset: 50,
      texts: {
        [RATE_TERRIBLE]: this._('Ужасно'),
        [RATE_BAD]: this._('Плохо'),
        [RATE_OKAY]: this._('Норм'),
        [RATE_GOOD]: this._('Хорошо'),
        [RATE_GREAT]: this._('Отлично'),
      },
    }
  },
  mounted() {
    this.$emit('input', this.$options.RATES[this.reaction])
  },
  methods: {
  },
};
