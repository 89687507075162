import {
  createReceiver,
  createSender,
  // createUpdater,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL_SHIPPING = 'shipping'
const MODEL_PAYMENT = 'payments'
const MODEL_ORDERS = 'orders'

// site
const SHIPPING_LIST_URL = prefixAPI('/methods/list/', MODEL_SHIPPING)
const SHIPPING_AUTOCOMPELETE_LIST_URL = prefixAPI('/methods/autocomplete-list/', MODEL_SHIPPING)
export const SHIPPING_CONFIG_AUTOCOMPELETE_LIST_URL = prefixAPI('/methods/configs/autocomplete-list/{?shipping_method__service}', MODEL_SHIPPING)

export const PAYMENT_LIST_URL = prefixAPI('/gateways/list/', MODEL_PAYMENT)
export const PAYMENT_STATUSES_LIST_URL = prefixAPI('/statuses/list/', MODEL_PAYMENT)
const ORDER_CREATE_URL = prefixAPI('/create/', MODEL_ORDERS)
const ORDER_SUMMARY_URL = prefixAPI('/actions/calculate/{?shipping_method_config,shop}', MODEL_ORDERS)

const GET_SELLER_PAYMENT_GATEWAYS = prefixAPI('/actions/get-payment-gateways/{id}/', MODEL_ORDERS)
const GET_SELLER_SHIPPING_METHODS = prefixAPI('/actions/get-shipping-methods/{id}/', MODEL_ORDERS)

// buyer
const USER_ORDERS_LIST_URL = prefixAPI('/list/user/{?limit,offset}', MODEL_ORDERS)

export const getShippingList = createReceiver(SHIPPING_LIST_URL)
export const getShippingAutocompleteList = createReceiver(SHIPPING_AUTOCOMPELETE_LIST_URL)
export const getShippingConfigAutocompleteList = createReceiver(SHIPPING_CONFIG_AUTOCOMPELETE_LIST_URL)

export const getPaymentList = createReceiver(PAYMENT_LIST_URL)
export const getPaymentStatusesList = createReceiver(PAYMENT_STATUSES_LIST_URL)
export const createOrder = createSender(ORDER_CREATE_URL)
export const getOrderSummary = createSender(ORDER_SUMMARY_URL)

export const getSellerPaymentGateways = createReceiver(GET_SELLER_PAYMENT_GATEWAYS)
export const getSellerShippingServices = createReceiver(GET_SELLER_SHIPPING_METHODS)

export const getUserOrdersList = createReceiver(USER_ORDERS_LIST_URL)
