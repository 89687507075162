import { VueAgile } from '@webcase/vue-slider'

import { Tabs, Tab } from 'vue-slim-tabs'

// add chat component
import AddChat from '@app/Seller/Chats/Add'

import {
  PrettyStars,
} from './Stars.js'

import LightBox from './LightBox'
import LoaderBtn from './LoaderBtn'
import ModalTrigger from './ModalTrigger'
import NotPermission from './NotPermission'
import DateFormat from './DateFormat'
import SellerCatalog from './SellerCatalog'
import MegaMenu from './Header/MegaMenu'
import MegaMenuWrap from './Header/MegaMenuWrap'
import MegaMenuSafe from './Header/MegaMenuSafe'
import UserAccountActions from './Header/UserAccountActions'
import WaitingListGetter from './Header/WaitingListGetter'

const SpecialInformation = () => import('./SpecialInformation')
const UiSidebar = () => import('./Header/UiSidebar')
const UiSearch = () => import('./Header/UiSearch')
const CityChange = () => import('./Header/CityChange')
const CityChangeElement = () => import('./Header/CityChangeElement')
const TreeMenuTrigger = () => import('./Header/TreeMenuTrigger')
const NativeSearch = () => import('./NativeSearch')
const SeoText = () => import('./SeoText')
const UiSlider = () => import('./UiSlider')
const UiLoader = () => import('./UiLoader')
const UiMap = () => import('./UiMap')
const Toggler = () => import('./Toggler')
const UiYoutubeView = () => import('./UiYoutubeView')
const LangSwitcher = () => import('./LangSwitcher')
const UiTable = () => import('./UiTable')

export function install(Vue) {
  Vue.component('LoaderBtn', LoaderBtn)
  Vue.component('WaitingListGetter', WaitingListGetter)
  Vue.component('DateFormat', DateFormat)
  Vue.component('UserAccountActions', UserAccountActions)
  Vue.component('NotPermission', NotPermission)
  Vue.component('UiMap', UiMap)
  Vue.component('CityChange', CityChange)
  Vue.component('CityChangeElement', CityChangeElement)
  Vue.component('TreeMenuTrigger', TreeMenuTrigger)
  Vue.component('LightBox', LightBox)
  Vue.component('ModalTrigger', ModalTrigger)

  Vue.component('SeoText', SeoText)
  Vue.component('PrettyStars', PrettyStars)
  Vue.component('NativeSearch', NativeSearch)
  Vue.component('UiSidebar', UiSidebar)
  Vue.component('UiSlider', UiSlider)
  Vue.component('UiLoader', UiLoader)
  Vue.component('UiSearch', UiSearch)
  Vue.component('VueAgile', VueAgile)
  Vue.component('Toggler', Toggler)
  Vue.component('UiYoutubeView', UiYoutubeView)
  Vue.component('LangSwitcher', LangSwitcher)
  Vue.component('UiTable', UiTable)
  Vue.component('Tabs', Tabs)
  Vue.component('Tab', Tab)
  Vue.component('AddChat', AddChat)
  Vue.component('MegaMenu', MegaMenu)
  Vue.component('MegaMenuWrap', MegaMenuWrap)
  Vue.component('MegaMenuSafe', MegaMenuSafe)
  Vue.component('SpecialInformation', SpecialInformation)
  Vue.component(SellerCatalog.name, SellerCatalog)
}

export default { install }
