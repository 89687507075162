import {
  createReceiver,
  createSender,
  createSimpleSender,
  createRemover,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'comparison/'

const COMPARISON_CREATE_URL = prefixAPI('create/', MODEL)
const COMPARISON_LIST_URL = prefixAPI('list/{?category,limit,offset}', MODEL)
const COMPARISON_CATEGORIES_URL = prefixAPI('categories/', MODEL)
const CLEAR_COMPARISON_URL = prefixAPI('clear/', MODEL)
const CLEAR_CATEGORY_COMPARISON_URL = prefixAPI('clear/{id}/', MODEL)
const COMPARISON_IDS_LIST_URL = prefixAPI('ids-list/', MODEL)
const REMOVE_FROM_COMPARISON_URL = prefixAPI('destroy/{id}/', MODEL)

export const addToComparison = createSender(
  COMPARISON_CREATE_URL
)
export const clearComparison = createSimpleSender(
  CLEAR_COMPARISON_URL
)
export const clearCategoryComparison = createSimpleSender(
  CLEAR_CATEGORY_COMPARISON_URL
)
export const getComparisonCategories = createReceiver(
  COMPARISON_CATEGORIES_URL
)
export const getComparisonList = createReceiver(
  COMPARISON_LIST_URL
)
export const getComparisonIdsList = createReceiver(
  COMPARISON_IDS_LIST_URL
)
export const removeFromComparison = createRemover(
  REMOVE_FROM_COMPARISON_URL
)
