//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'

import {
  friendlyUrlParser,
} from '@app/Filters/transformers'

import Controller from '@app/List/Controller'

export default {
  components: { Controller },
  props: {
    initial: {

    },
    baseUrl: {},
    productsResource: {},
    attributesResource: {},
    availableAttributesResource: {},
    label: {},
    sortlist: {
    },
    filterComponent: {},
    kwargs: {},
  },
  data() {
    return {
      default_sort: [
        {
          title: this._('Количество просмотров'),
          key: '-views',
        },
        {
          title: this._('Количество покупок'),
          key: '-bestseller',
        },
        {
          title: this._('Сначала дешевые'),
          key: 'price',
        },
        {
          title: this._('Сначала дорогие'),
          key: '-price',
        },
        {
          title: this._('Новинки'),
          key: '-date',
        },
        {
          title: this._('По рейтингу'),
          key: '-rating',
        },
      ],
    }
  },
  mounted() {
    console.log(this.initial, '>>>>>>>>>');
  },
  methods: {
    ...mapMutations('search', [
      'SET_SEARCH',
    ]),
    parametersTo({ filters, offset, ...rest }) {
      return {
        ...rest,
        filters: filters || {},
        ...this.kwargs,
      }
    },
    parametersFrom({ ...rest }) {
      const data = friendlyUrlParser()
      return {
        ...rest,
        filters: data,
        ...this.kwargs,
      }
    },

    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.search)
      }
    },
  },
}

