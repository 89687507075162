/* eslint-disable  no-param-reassign */
import { curry } from 'ramda';
import makeTemplateGetter from '@aspectus/resource-template-url-getter';
import {
  defaultDataTransformer,
  jsonTransformer,
} from '@aspectus/resource-commons';

const filtersGetterBase = makeTemplateGetter('{;filters*}');
const filtersGetter = parameters => filtersGetterBase(parameters).slice(1) || null

const partGetter = (name, prefix) => {
  const base = makeTemplateGetter(`${prefix}{/${name}}`);
  return parameters => {
    const result = base(parameters);
    return result === prefix ? null : result;
  };
};

const pageGetter = partGetter('page', 'page');
const labelGetter = makeTemplateGetter('{label}');
const categoryGetter = makeTemplateGetter('{category}');
const getParametersGetter = makeTemplateGetter('{?limit,offset}');

const order_map = {
  label: labelGetter,
  category: categoryGetter,
  filters: filtersGetter,
  pagination_offset: getParametersGetter,
  page: pageGetter,
}

const initOrder = {
  path: [
    'prefix',
    'filters',
  ],
}

export function sortObjKeysAlphabetically(obj) {
  return Object.keys(obj).sort((a, b) => a.localeCompare(b)).reduce((result, key) => {
    result[key] = obj[key];
    return result
  }, {});
}

export const sorter = obj => {
  if (!obj) return obj
  Object.keys(obj).forEach(k => {
    obj[k].sort((a, b) => a.localeCompare(b))
  })
  obj = sortObjKeysAlphabetically(obj)
  return obj
}

export const urlGetter = curry(([prefix, postfix], { path, query = [] }, p) => {
  p.filters = sorter(p.filters)
  const base = { prefix, postfix }
  const path_parts = path.reduce((acc, x, i) => {
    acc[i] = order_map[x] ? order_map[x](p) : base[x]
    return acc
  }, Array.from({ length: path.length }, () => null))
  const query_params = query.reduce((acc, x) => {
    acc.push(order_map[x](p))
    return acc
  }, [])
  return (`${path_parts.filter(x => x !== null).join('/')}/`).replace(/\/\//img, '/').replace(/\/\//img, '/').replace(/%2C/g, ',') + query_params.join('');
});

export const friendlyUrlGenerator = (url, resource, order = initOrder) => resource
  .url(urlGetter(url, order))
  .transform(jsonTransformer)
  .transform(defaultDataTransformer)
