//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  confirmWithPromise,
  getId,
} from '@utils/helpers'
import GalleryItem from './GalleryItem'

const SPLITED_LENGTH = 1

export default {
  name: 'Gallery',
  components: {
    GalleryItem,
  },
  props: {
    id: {},
    filekey: {
      default: 'image',
    },
    limit: {
      type: Number,
      default: 5,
    },
    sizeLimit: {
      type: Number,
      default: 10485760,
    },
    resource: {
    },
    reset: {

    },
    additional: {},
    getResource: {},
    postResource: {},
    patchResource: {},
    deleteResource: {},
    profile: {},
  },
  data() {
    return {
      test: '',
      value: [],
      isPageLoading: false,
      list: [],
      nonFieldError: '',
      isBlocked: false,
      files: [],
      one: 1,
      full: 100,
      imgLengthErr: '',
    }
  },
  watch: {
    reset(nval) {
      if (nval) {
        this.list = []
        this.files = []
      }
    },
  },
  created() {
    this.getInit()
  },
  methods: {
    async getInit() {
      if (!this.getResource) return
      const { items } = (await this.getResource.execute())
      this.list = items.map(el => el.props)
    },
    addVideo(event) {
      this.$emit('inProcess', true)
      this.nonFieldError = ''
      this.isBlocked = true
      const video = getId(event)
      this.showVideoRow = false

      const image = `https://img.youtube.com/vi/${video}/0.jpg`
      this.list.push({
        image,
        progressBarWidth: 0,
        file: event,
      })
      this.loader()
    },
    addPhoto(event) {
      // this.$emit('inProcess', true)
      this.nonFieldError = ''
      this.imgLengthErr = ''
      this.isBlocked = true

      const files = Array.from(event.target.files)

      this.files = files
      files.forEach(file => {
        this.list.push({
          image: window.URL.createObjectURL(file),
          progressBarWidth: 0,
          err: '',
          val: false,
          file,
        })
      })
      this.loader()
    },
    loader() {
      this.list.forEach((element, index) => {
        if (element.id) return
        console.log('🚀 ~ file: Gallery.vue ~ line 129 ~ this.list.forEach ~ element.file', element.file)
        this.postResource.execute({}, {
          ...this.additional, [this.filekey]: element.file,
        }).then(({ data: { item: { id } } }) => {
          this.$set(this.list[index], 'id', id)
          this.$emit('update', this.list)
        })
      })
    },
    async removeImage(i) {
      const question = this._('Вы действительно хотите удалить?')
      try {
        await confirmWithPromise(question, this)
        if (this.list[i].id) {
          this.deleteResource.execute({ id: this.list[i].id })
        }
        this.list.splice(i, SPLITED_LENGTH)
        this.$emit('update', this.list)
      } catch (e) {
        console.log(e);
      }
    },
  },

}
