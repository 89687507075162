//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  isEmpty,
} from '@aspectus/vue-utils'

export default {
  name: 'BoundLabel',
  block: 'd-control-input',
  isEmpty,
  props: {
    labelText: {},
    id: {
      type: String,
      required: false,
    },
    value: {},
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
    showStar: {
      type: Boolean,
      default: true,
    },
  },
}
