//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NativeSorting',
  props: {
    initValue: {},
    values: {},
  },
  data() {
    return {
      checked: this.initValue,
    }
  },
  watch: {
    initValue(nval) {
      this.checked = nval
    },
  },
}
