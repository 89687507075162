//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ProductSimpleBaseView',
  props: {
    product: {
      default: () => { },
    },
    price: {
      default: '0.00',
    },
    isSale: {
      default: () => false,
    },
    load: {},
    disabled: {
      default: () => false,
    },
    canRemove: {},
  },
  data() {
    return {
      currency: window.currency,
    }
  },
}
