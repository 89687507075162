
import {
  getPriceCalculationResoure,
} from '@api/store.service'

export default {
  name: 'PriceCalculator',
  props: ['id', 'quantity'],
  data() {
    return {
      value: {},
      unitPrice: 0,
    }
  },
  async created() {
    await this.getPrices(this.quantity, true, true)
    if (1 !== this.quantity) {
      await this.getPrices(1, true)
    }
  },
  watch: {
    quantity() {
      this.getPrices(this.quantity, false, true)
    },
  },
  methods: {
    async getPrices(quantity, changeFrozen, edit) {
      const { data } = (await getPriceCalculationResoure.execute({}, { product: this.id, quantity }))
      if (edit) this.value = data
      if (changeFrozen) this.unitPrice = data.total
    },
  },
  render() {
    return this.$scopedSlots.default({
      value: this.value,
      unitPrice: this.unitPrice,
    })
  },
}
