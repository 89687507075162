import FavoritePreview from '@app/Likes/Preview'

import AddToFavorites from '@app/Likes/Add'
import FavoriteView from '@app/Likes/View'

export default function install(Vue) {
  Vue.component(FavoritePreview.name, FavoritePreview)
  Vue.component('AddToFavorites', AddToFavorites)
  Vue.component(FavoriteView.name, FavoriteView)
}
