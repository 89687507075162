//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Renderer from '@app/List/Renderer'
import { mapActions, mapState } from 'vuex'
import {
  COOKIE_NAME,
} from '@store/vendor'
import {
  getCookie,
} from '@utils/cookies';

export default {
  props: ['loading', 'items', 'pagination', 'filters'],
  components: {
    Renderer,
  },
  computed: {
    ...mapState('vendor', [
      'view',
    ]),
    back() {
      if (this.items) {} // eslint-disable-line no-empty
      return `${window.location.pathname}${window.location.search}`
    },
  },
  watch: {
    items() {
      this.$nextTick(() => this.trigger_view({ size: this.view, isTiny: this.filters?.tiny }))
    },
  },
  mounted() {
    this.trigger_view({ size: getCookie(COOKIE_NAME) || 16, isTiny: this.filters?.tiny })
  },
  methods: {
    ...mapActions('vendor', [
      'trigger_view',
    ]),
    scroll() {
      const el = document.getElementById('content-view')
      el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    },
    handlePaginate({ offset }) {
      if (this.loading) {
        return
      }
      const init = 0
      const { limit = init } = this.pagination
      this.$emit('update:pagination', { limit, offset })
      this.scroll()
    },
  },
}

