//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Highlight the query part of the search term
import { SEARCH_URL } from '@api/vendor.service'
import {
  mapMutations,
  mapActions,
} from 'vuex'
import cancellablePromiseProxy from '@aspectus/cancellable-promise-proxy';

import debounce from 'lodash.debounce'

import { receiveResource, createResource } from '@resource/resource'

const LIMIT_FOR_SEARCH = 20

// Highlight the query part of the search term
const regexer = value => new RegExp(`(?![^&;]+;)(?!<[^<>]*)(${value})(?![^<>]*>)(?![^&;]+;)`, 'gi')

function highlightTerm(value, term) {
  return value
    .replace(regexer(term),
      '<b>$1</b>')
}

function findValueAndHighlightTerm(template, value, term) {
  return template
    .replace(regexer(value),
      highlightTerm(value, term))
}

const EXCLUDED_SIGNS = ['&', '?', ';']

const AVAILABLE = 'available'
const CONFIRM = 'confirm'
const DISABLE = 'disable'

const AVAILABILITY_CLASSES = {
  [AVAILABLE]: 'is-active',
  [CONFIRM]: 'is-pending',
  [DISABLE]: 'is-disables',
}

export default {
  name: 'SearchController',
  props: ['className', 'fieldId', 'url', 'localsearch'],
  AVAILABILITY_CLASSES,
  data() {
    return {
      // localsearch: '',
      show: false,
      arrowCounter: -1,
      empty: false,
      load: false,
      results: {},
      length: 1,
      meta: {
      },
      currentRequest: '',
      transes: {
        [AVAILABLE]: this._('есть в наличии'),
        [CONFIRM]: this._('под заказ'),
        [DISABLE]: this._('нет в наличии'),
      },
    }
  },
  watch: {
    localsearch(nval) {
      if (!nval) {
        this.hide()
      }
    },
  },
  computed: {
    activated() {
      return this.results.product || this.results.category || this.load || this.empty
    },
  },
  created() {
    const delay = 0
    this.debounceAction = debounce(parameters => {
      this.executer(parameters)
    }, delay)
  },
  methods: {
    findValueAndHighlightTerm,
    highlightTerm,
    ...mapMutations('search', [
      'SET_SEARCH',
    ]),
    ...mapActions('search', [
      'trigger_overlay',
    ]),
    hide() {
      this.reset()
      this.activeClass = false
      // this.trigger_overlay(false)
    },
    getAvailability(item) {
      const available = 'available' === item.availability_status && (!item.track_inventory || item.track_inventory && item.stock_quantity)
      if (available) return AVAILABLE

      const confirm = 'confirmation_of_availability' === item.availability_status && (!item.track_inventory || item.track_inventory && item.stock_quantity)
      if (confirm) return CONFIRM

      const disable = !('available' === item.availability_status || 'confirmation_of_availability' === item.availability_status && !item.track_inventory || item.track_inventory && item.stock_quantity)
      if (disable) return DISABLE

      return DISABLE
    },
    reset() {
      this.results = {}
      this.clearClas = false
      this.empty = false
      this.activeClass = false
      this.arrowCounter = -1
      this.meta = {}
    },
    activeSearch() {
      this.show = !this.show
    },
    clearSearch() {
      this.search = ''
    },
    showSearch() {
      if ('new-header-mobile-search' === this.className) {
        this.show = true
      }
    },
    closeSearch() {
      if ('new-header-mobile-search' === this.className) {
        this.show = false
      }
    },
    dynamicSearch(event) {
      if ('Enter' === event.code || 13 === event.keyCode) {
        this.goToSearch()
        return
      }
      const up = 38
      const down = 40
      const width = 1200
      if (window.innerWidth < width) {
        this.localsearch = event.target.value
      }
      const limit = 3
      if (up !== event.keyCode && down !== event.keyCode) {
        if (this.localsearch.length < limit) return
        this.load = true

        this.debounceExecuter()
      } else {
        this.results = []
        this.empty = false
      }
    },
    debounceExecuter(parameters) {
      this.debounceAction(parameters)
    },
    executer() {
      if (this.currentRequest) this.currentRequest.cancel()
      const cancelController = new AbortController();
      const base = receiveResource.config('signal', cancelController.signal)
      const resource = createResource(SEARCH_URL, base)

      this.currentRequest = cancellablePromiseProxy(
        new Promise((resolve, reject) => {
          resource.execute({
            search: this.localsearch,
            limit: LIMIT_FOR_SEARCH,
          })
            .then(resolve, reject);
        }),
        { cancelController }
      ).then(({ data }) => {
        this.trigger_overlay(true)
        this.results = data
        // this.meta = data.pagination
        this.load = false
        if (Object.keys(data).length) {
          this.empty = false
          return
        }
        this.empty = true
      })
    },
    goToSearch() {
      this.load = true
      this.trigger_overlay(false)
      let searchString = this.localsearch
      // eslint-disable-next-line no-return-assign
      EXCLUDED_SIGNS.forEach(el => searchString = searchString.replaceAll(el, ''))
      window.location.href = `${window.searchPage}/filters/search=${searchString}/`
      this.reset()
    },
    onEnter() {
      this.arrowCounter = -1
      this.goToSearch()
    },
    getFocus(event) {
      // console.log(event)
      if ('Enter' === event.code) {
        this.reset()
        return
      }
      const i = 0
      const one = 1
      const zero = 0
      if (this.results.length > i) {
        if ('ArrowDown' === event.code) {
          this.$refs['search-link'][i].focus()
        }
        document.addEventListener('keydown', e => {
          if ('ArrowDown' === e.code && this.arrowCounter < this.results.length - one) {
            e.preventDefault()
            this.arrowCounter += one
            this.$refs['search-link'][this.arrowCounter].focus()
          }
          if ('ArrowUp' === e.code && this.arrowCounter > zero) {
            e.preventDefault()
            this.arrowCounter -= one
            this.$refs['search-link'][this.arrowCounter].focus()
          }
        })
      }
    },
  },
}

