import {
  userRetrieve,
  userUpdate,
  CheckOppositeAccount,
} from '@api/staff.service'

const SET_USER_INFORMATION = 'SET_USER_INFO'
const SET_PART_OF_USER_INFORMATION = 'SET_PART_OF_USER_INFO'
const SET_OPPOSITE_ACCOUNT = 'SET_OPPOSITE_ACCOUNT'

export function createStore() {
  return {
    namespaced: true,
    state: {
      oppositeAccountExists: false,
      user: {
        props: {
          email_addresses: [],
          phone_numbers: [],
          legal_entity: {},
        },
      },
    },
    getters: {
      emails(state) {
        if (!state.user.props.email_addresses) return []
        return state.user.props.email_addresses.sort(value => (value.props.is_verified ? -1 : 1))
      },
      phones(state) {
        if (!state.user.props.phone_numbers) return []
        return state.user.props.phone_numbers.sort(value => (value.props.is_verified ? -1 : 1))
      },
      legal_entity(state) {
        return state.user.props.legal_entity
      },
      id(state) {
        return state.user.id
      },
    },
    actions: {
      async getUserInfo({ commit }) {
        return userRetrieve.execute().then(({ data }) => {
          commit(SET_USER_INFORMATION, data)
        })
      },
      async getOppositeAccount({ commit }) {
        return CheckOppositeAccount.execute().then(({ data: { exists } }) => {
          commit(SET_OPPOSITE_ACCOUNT, exists)
        })
      },
      updateUserInfo({ commit }, payload) {
        userUpdate.execute({}, { [payload.key]: payload.value }).then(({ data: { props: { image } } }) => {
          commit(SET_PART_OF_USER_INFORMATION, { key: 'image', value: { full_size: image } })
        })
      },
    },
    mutations: {
      [SET_OPPOSITE_ACCOUNT](state, payload) {
        state.oppositeAccountExists = payload
      },
      [SET_USER_INFORMATION](state, payload) {
        state.user = payload
      },
      [SET_PART_OF_USER_INFORMATION](state, payload) {
        state.user.props[payload.key] = payload.value
      },
    },
  }
}
