import {
  createReceiver,
  createOptionsGetter,
  createSender,
  createUpdater,
  createFileSender,
  createRemover,
  createReplacer,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'store'
const p = postfix => MODEL + postfix
const MODEL_PRODUCTS = p('/products')
const MODEL_SETS = p('/sets')
const MODEL_PRODUCTS_IMAGES = `${MODEL_PRODUCTS}/images`
const MODEL_PRODUCTS_VIDEOS = `${MODEL_PRODUCTS}/videos`

export const PRODUCTS_LIST_URL = prefixAPI('/list/{?filters*,offset,limit,search}', MODEL_PRODUCTS)
export const PRODUCTS_LIST_IDS_URL = prefixAPI('/list-ids/{?filters*,offset,limit,search}', MODEL_PRODUCTS)
export const PRODUCT_ATTRIBUTES_URL = prefixAPI('/details/{?ids}', MODEL_PRODUCTS)
export const CREATE_PRODUCT_URL = prefixAPI('/create/', MODEL_PRODUCTS)
export const RETRIEVE_PRODUCT_URL = prefixAPI('/retrieve/{id}/', MODEL_PRODUCTS)
export const UPDATE_PRODUCT_URL = prefixAPI('/update/{id}/', MODEL_PRODUCTS)
export const BULK_UPDATE_PRODUCT_URL = prefixAPI('/bulk-update/', MODEL_PRODUCTS)
export const DUBLICATE_PRODUCT_URL = prefixAPI('/duplicate/{id}/', MODEL_PRODUCTS)
export const PRICE_CALCULATOR_URL = prefixAPI('/actions/calculator/', MODEL)
export const GET_GROUPS_URL = prefixAPI('/groups/list/{?offset,search,limit,category,shop}', MODEL_PRODUCTS)

export const CACHED_CATEGORIES_URL = prefixAPI('/categories/list/cached/', MODEL)
export const SELLER_CATEGORIES_URL = prefixAPI('/categories/list/seller/{id}/', MODEL)

export const PAGES_CREATE_URL = prefixAPI('/pages/create/', MODEL_PRODUCTS)
export const PAGES_URDATE_URL = prefixAPI('/pages/update/{id}/', MODEL_PRODUCTS)
export const PAGES_REMOVE_URL = prefixAPI('/pages/destroy/{id}/', MODEL_PRODUCTS)
export const PAGES_BLOCKS_URL = prefixAPI('/pages/list/{id}/', MODEL_PRODUCTS)

export const PRODUCT_CATEGORIES_URL = prefixAPI('/categories/list/seller/{?offset,search,limit,level,parent}', MODEL)
export const PRODUCT_CATEGORIES_WITH_COUNT_URL = prefixAPI('/categories/list/seller-with-count/{?offset,search,limit,level,parent}', MODEL)
export const PRODUCT_BRANDS_URL = prefixAPI('/directory/manufacturers/{?offset,search,limit,level,parent}', MODEL)
export const ORDERS_DELIVERING_URL = prefixAPI('/statistics/orders/delivering/', MODEL)

export const CATEGORY_EXPORT_URL = '/feeds/attributes/'
export const ALL_CATEGORIES_EXPORT_URL = prefixAPI('/directory/excel-export/all-categories/', MODEL)
export const PARAMETERS_URL = prefixAPI('/attributes/{?category}', MODEL_PRODUCTS)

// SETS
export const SETS_LIST_URL = prefixAPI('/list/{?filters*,offset,limit}', MODEL_SETS)
export const SETS_CREATE_URL = prefixAPI('/create/', MODEL_SETS)
export const SETS_PRODUCTS_ADD_URL = prefixAPI('/products/update/{id}/', MODEL_SETS)
export const SETS_UPDATE_URL = prefixAPI('/update/{id}/', MODEL_SETS)

// product images
export const IMAGES_CREATE_URL = prefixAPI('/create/', MODEL_PRODUCTS_IMAGES)
export const IMAGES_LIST_URL = prefixAPI('/list/', MODEL_PRODUCTS_IMAGES)
export const IMAGES_DELETE_URL = prefixAPI('/destroy/{id}/', MODEL_PRODUCTS_IMAGES)
export const IMAGES_UPDATE_URL = prefixAPI('/update/{id}/', MODEL_PRODUCTS_IMAGES)
// product videos
export const VIDEOS_CREATE_URL = prefixAPI('/create/', MODEL_PRODUCTS_VIDEOS)
export const VIDEOS_LIST_URL = prefixAPI('/list/', MODEL_PRODUCTS_VIDEOS)
export const VIDEOS_DELETE_URL = prefixAPI('/destroy/{id}/', MODEL_PRODUCTS_VIDEOS)

export const getSellerCategories = createReceiver(SELLER_CATEGORIES_URL)
export const getProductsList = createReceiver(PRODUCTS_LIST_URL)
export const getProductsListIds = createReceiver(PRODUCTS_LIST_IDS_URL)
export const createProduct = createSender(CREATE_PRODUCT_URL)
export const updateProduct = createUpdater(UPDATE_PRODUCT_URL)
export const bulkUpdateProduct = createSender(BULK_UPDATE_PRODUCT_URL)
export const getProduct = createReceiver(RETRIEVE_PRODUCT_URL)
export const getProductOptions = createOptionsGetter(CREATE_PRODUCT_URL)
export const getGroupsList = createReceiver(GET_GROUPS_URL)
export const dublicateProduct = createSender(DUBLICATE_PRODUCT_URL)

export const getPagesOptions = createOptionsGetter(PAGES_CREATE_URL)
export const getBlocksList = createReceiver(PAGES_BLOCKS_URL)
export const createProductPages = createSender(PAGES_CREATE_URL)
export const updateProductPages = createReplacer(PAGES_URDATE_URL)
export const removeProductPages = createRemover(PAGES_REMOVE_URL)
export const getProductPages = createReceiver(PRODUCTS_LIST_URL)
export const getProductsAttributes = createReceiver(PRODUCT_ATTRIBUTES_URL)

export const getProductCategories = createReceiver(PRODUCT_CATEGORIES_URL)
export const getProductCategoriesWithCount = createReceiver(PRODUCT_CATEGORIES_WITH_COUNT_URL)
export const getProductBrands = createReceiver(PRODUCT_BRANDS_URL)

export const getCategoryAttributes = createReceiver(PARAMETERS_URL)

// product images
export const ImagesCreate = createFileSender(IMAGES_CREATE_URL)
export const ImagesList = createReceiver(IMAGES_LIST_URL)
export const ImagesDelete = createRemover(IMAGES_DELETE_URL)
// product videos
export const VideosCreate = createFileSender(VIDEOS_CREATE_URL)
export const VideosList = createReceiver(VIDEOS_LIST_URL)
export const VideosDelete = createRemover(VIDEOS_DELETE_URL)

// sets
export const getSetsListResoure = createReceiver(SETS_LIST_URL)
export const createSetResource = createSender(SETS_CREATE_URL)
export const updateProductsInSetResource = createUpdater(SETS_PRODUCTS_ADD_URL)
export const updateSetResource = createUpdater(SETS_UPDATE_URL)

export const getDeliveringOrdersCountResource = createReceiver(ORDERS_DELIVERING_URL)

export const getPriceCalculationResoure = createSender(PRICE_CALCULATOR_URL)

export const getCachedCategoriesResource = createReceiver(CACHED_CATEGORIES_URL)
