import {
  getWishListIds,
} from '@api/like.service'

const ADD_TO_FAVORITES = 'ADD_TO_FAVORITES'
const SET_COUNT = 'SET_COUNT'
const SET_IDS_LIST = 'SET_IDS_LIST'

export function createStore() {
  return {
    namespaced: true,
    state: {
      count: 0,
      ids: [],
    },
    getters: {
      getCount: state => state.count,
    },
    actions: {
      addId({ commit }, payload) {
        commit(ADD_TO_FAVORITES, payload)
        commit(SET_COUNT)
      },
      async getWishListIds({ commit }) {
        const { data: { ids } } = (await getWishListIds.execute())
        commit(SET_IDS_LIST, ids)
        commit(SET_COUNT)
      },
    },
    mutations: {
      [ADD_TO_FAVORITES](state, payload) {
        state.ids.push(payload)
      },
      [SET_COUNT](state) {
        state.count = state.ids.length
      },
      [SET_IDS_LIST](state, payload) {
        state.ids = payload
      },
    },
  }
}
