import {
  mapState,
  mapGetters,
} from 'vuex'

import {
  isEmpty,
} from '@aspectus/vue-utils'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  createOrder,
  getOrderSummary,
} from '@api/order.service'

import {
  AnswerMessage,
} from '@utils/submit'

import pathOr from 'ramda/src/pathOr'
import StepsController from './StepsController'
import SingleStepController from './SingleStepController'
import Cart from './Steps/Cart'
import PaymentAndDelivery from './Steps/PaymentAndDelivery.vue'
import Personal from './Steps/Personal.vue'
import Recipient from './Steps/Parts/Recipient'

const WIDGETS_MAP = [
  {
    type: Personal,
    key: 'personal',
  },

  {
    type: PaymentAndDelivery,
    key: 'payment_and_delivery',
  },

  {
    type: Recipient,
    key: 'recipient',
  },
]
const RIGHT_SIDE_MAP = [
  {
    type: Cart,
    key: 'cart',
    size: 4,
  },
]

export default {
  name: 'OrderView',
  components: {
    StepsController,
    SingleStepController,
  },
  mixins: [FormMixin],
  props: ['shop'],
  widgetsMap: WIDGETS_MAP,
  rightSide: RIGHT_SIDE_MAP,
  data() {
    return {
      offer: window.offer,
      rules: window.rules,
      order: {
        city: {},
        customer_info: {},
        recipient_info: {},
        shipping_info: null,
        shipping_settlement: null,
        shipping_method_config: null,
        payment_gateway: '',
        comment: '',
        register: false,
        is_urgent: false,
        shop: this.shop,
        opendatabot_info: {},
      },
      summary: {},
    }
  },
  watch: {
    'order.shipping_info': {
      handler() { this.getSummary() },
      deep: true,
    },
    'order.payment_gateway': {
      handler() { this.getSummary() },
      deep: true,
    },
  },
  computed: {
    ...mapState('cart', [
      'cart',
    ]),
    ...mapGetters('cart', [
      'getCartByShop',
    ]),
  },
  methods: {
    cartView() {
      this.$modal.show(
        () => import('@app/Cart/View'),
        {
          info: {
          },
        }, {
          height: 'auto',
          classes: ['cart-modal'],
          adaptive: true,
          scrollable: true,
        }, {
          closed: this.checkAndUpdate,
        }
      )
    },
    checkAndUpdate() {
      this.getSummary()
      if (!this.cart.length) window.location.href = window.indexPage
    },
    changeCity($event) {
      this.$set(this.order, 'city', $event)
    },
    update(event) {
      this.order = Object.assign(this.order, event)
    },
    async getSummary() {
      // eslint-disable-next-line camelcase
      const shipping_method_config = this.order.shipping_info && this.order.shipping_info.id
      const { data } = (await getOrderSummary.execute({}, { shipping_method_config, shop: this.shop }))
      this.summary = data
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      const data = JSON.parse(JSON.stringify(this.order))
      data.city = pathOr(
        pathOr(
          pathOr(
            null,
            ['props', 'id'],
            this.order.city
          ),
          ['props', 'city'],
          this.order.warehouse
        ),
        ['props', 'description'],
        this.order.city
      )
      data.payment_gateway = pathOr(null, ['id'], this.order.payment_gateway)
      data.warehouse = pathOr(
        pathOr(null, ['props', 'description'], this.order.warehouse),
        ['props', 'address'],
        this.order.warehouse
      )
      // "shipping_info": [
      //   {
      //   "title": "Отделение Новая Почта",  # брать из title в fields
      //   "value_object": {"type": "django_novaposhta.warehouse", "id": "9144"},
      //   },
      //   {
      //   "title": "Улица Новая почта",
      //   "value": "Название улицы",
      //   },
      // ]

      data.shipping_settlement = pathOr(pathOr(null, ['id'], JSON.parse(window.current_city)), ['props', 'id'], this.order.city)
      data.shipping_method_config = pathOr(null, ['id'], this.order.shipping_info)
      data.shipping_info = this.order.shipping_info.fields.reduce((acc, { title, props: { value } }) => {
        const val = {
          title,
        }
        if (value.id) {
          val.value_object = {
            type: value.type,
            id: value.id,
          }
          acc.push(val)
          return acc
        }
        val.value = value
        acc.push(val)
        return acc
      }, [])

      Object.keys(data).forEach(key => {
        if (isEmpty(data[key])) delete data[key]
      })
      this.submit(valid, data)
    },
    send(data) {
      const action = createOrder
      return action.execute({}, data)
        .then(({ data: res }) => {
          // AnswerMessage(res, this)
          window.location.href = res.redirect.location
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      const meta = {
        messages: [{
          title: this._('Order create error header'),
          text: this.non_field_errors ? this.non_field_errors[0] : '',
        }],
        timeout: 5000,
      }
      AnswerMessage(meta, this)
      this.$refs.validator.setErrors(parsed)
    },
  },
}
