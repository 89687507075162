//
//
//
//
//
//
//
//
//
//

import {
  mapGetters,
  mapState,
} from 'vuex'

import Renderer from '@app/List/Renderer'

export default {
  name: 'ProductPjaxContainer',
  components: {
    Renderer,
  },
  computed: {
    ...mapGetters('pjax', {
      result: 'getResult',
    }),
    ...mapState('pjax', [
      'load',
    ]),
  },
}
