//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'KnowledgeSearch',
  props: ['url', 'value'],
  data() {
    return {
      search: this.value || '',
    }
  },
  methods: {
    submit(valid) {
      if (!valid) return
      window.location.href = `${this.url}filters/search=${this.search}`
    },
  },
}
