
import { confirmWithPromise } from '@utils/helpers'
import { mapActions } from 'vuex'

export default {
  name: 'CartProduct',
  props: {
    product: {
      default: () => ({}),
    },
  },
  data() {
    return {
      load: false,
    }
  },
  methods: {
    ...mapActions('cart', [
      'changeCart',
    ]),
    async updateCart(info) {
      const changes = Math.round((info.count - this.product.base.quantity) * 1e12) / 1e12
      console.log(info.count, '-', this.product.base.quantity, '=', changes)
      if (!changes) return
      this.load = true
      const data = {
        element: {
          id: this.product.base.element.id,
          type: this.product.base.element.type,
        },
        quantity: changes,
      }
      const entities = []
      entities.push(data)

      await this.changeCart({ entities })
        .then(() => {
          this.load = false
        })
    },
    async removeFromCart() {
      const question = this._('Вы действительно хотите удалить товар с корзины?')
      try {
        await confirmWithPromise(question, this)
        const count = -1
        const obj = {
          count,
        }

        this.updateCart(obj, true)
      } catch (e) {
        console.log(e)
      }
    },
  },

  render() {
    return this.$scopedSlots.default({
      removeFromCart: () => this.removeFromCart(),
      updateCart: event => this.updateCart(event),
      isProductload: this.load,
    })
  },
}
