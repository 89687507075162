import {
  waitingListIdsResource,
} from '@api/waitinglist.service'

const SET_IDS_LIST = 'SET_IDS_LIST'
const SET_COUNT = 'SET_COUNT'

export function createStore() {
  return {
    namespaced: true,
    state: {
      count: 0,
      ids: [],
    },
    getters: {
      getCount: state => state.count,
      getIds: state => state.ids,
    },
    actions: {
      async getWaitingListIds({ commit }) {
        const { data: { ids } } = (await waitingListIdsResource.execute())
        commit(SET_IDS_LIST, ids)
        commit(SET_COUNT)
      },
    },
    mutations: {
      [SET_COUNT](state) {
        state.count = state.ids.length
      },
      [SET_IDS_LIST](state, payload) {
        state.ids = payload
      },
    },
  }
}
