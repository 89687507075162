//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ResendTimer',
  props: ['time'],
}
