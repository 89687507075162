/* eslint-disable no-unused-vars */

import SnapSlider from '@tannerhodges/snap-slider';

import sliderControl from '@utils/slider-controls'

import {
  checkLogo,
  imitateHover,
  mobileFlipper,
  prettyMobHeader,
  scrolly,
  changeSellerText,
  antiMobileFlipper,
  themeAccordions,
  logoSprite,
} from './ui-scripts'

import Swipe from './swipe'

const NON_TOUCH = 0

document.addEventListener('DOMContentLoaded', () => {
  checkLogo()
  imitateHover()
  logoSprite()
  // if (
  //   navigator.maxTouchPoints > NON_TOUCH ||
  //   navigator.msMaxTouchPoints > NON_TOUCH
  // ) {
  //   mobileFlipper()
  // }
  scrolly()
  prettyMobHeader()
  sliderControl()
  changeSellerText()
  // document.addEventListener('click', antiMobileFlipper)
  themeAccordions()
  const box = document.querySelector('#slider');
  console.log(box);
  // const classes = Swipe.directions();
  // const elem = box.firstChild;

  // let pos = 0
  // const shift = 50
  // const runAnimation = direction => {
  //   if (direction === "left") {
  //     pos += shift
  //   } else if (direction === "right") {
  //     pos -= shift

  //   }
  //   console.log(pos, box);
  //   box.scrollLeft = pos
  //   box.classList.remove.apply(box.classList, classes);
  // };

  // SWIPE INITIALIZATION
  const swipe = new Swipe(box, {
    corners: true,
    minDistance: 50,
  });
  // const afterEvent = swipe.addEventListener('after', direction => {
  //   console.log(direction);
  //   // runAnimation(direction);
  // });
  const liveEvent = swipe.addEventListener('live', (direction, distance) => {
    // runAnimation(direction);
    if ('left' === direction) {
      box.scrollLeft += distance
    } else if ('right' === direction) {
      box.scrollLeft -= distance
    }
  });

  const sliders = Array.from(document.getElementsByClassName('js-slider-autoplay'))
  sliders.forEach(slider => {
    console.dir(slider);
    setInterval(() => {
      slider.SnapSlider.goto('next', { focus: false })
      // console.log(slider.SnapSlider.getCurrentSlide());
    }, 5000)
  })
})
