
/* eslint-disable prefer-spread */
import { LimitOffsetPagination } from './Pagination'

export default {
  template: '<div></div>',
  name: 'UiLimitPagination',
  props: {
    pagination: {},
    extra: {
      type: Number,
      default: 2,
    },
    needAddBtns: {
      type: Boolean,
      default: () => false,
    },
    reset: {},
  },

  data() {
    return {
      pageSizes: [
        10,
        20,
        40,
        60,
        80,
        100,
      ],
      pagelimit: 10,
      page: {},
      range: {
        min: 0,
        max: Infinity,
      },
      gaps: {
        left: false,
        right: false,
      },
      fills: {
        left: [],
        right: [],
      },
      one: 1,
      zero: 0,
      limit: 200,
      show: true,
    }
  },

  created() {
    if (this.page) this.recalcRange()
  },
  computed: {
    pageObj() {
      const to = this.page.limit + this.page.offset
      const from = this.page.offset + this.one

      return { to, from }
    },
  },
  watch: {
    pagination: {
      deep: true,
      immediate: true,
      handler() {
        this.page = new LimitOffsetPagination(this.pagination)
        // this.pagelimit = this.pagination.limit
        this.show = true
      },
    },
    page() { this.recalcRange() },
    reset(nval) {
      if (nval) {
        this.show = true
      }
    },
  },

  methods: {
    changeLimit() {
      this.$emit('changeLimit', { limit: this.pagelimit })
    },
    showAll(e) {
      this.$emit('changeLimit', { limit: e })
    },
    recalcRange() {
      const [min, max] = this.page.getPageRange()
      const current = this.page.getPageNumber()
      const [start, end] = this.page.getPageRange(this.extra, current)
      // Some javascript magic going on here:
      // Filling up array with page numbers.
      const range = Array
        .apply(null, { length: end - start + this.one })
        .map((x, i) => i + start)
      const currentIndex = range.indexOf(current)
      if (currentIndex !== -this.one) {
        this.fills.left = range.slice(this.zero, currentIndex)
        const minIndex = this.fills.left.indexOf(min)
        if (minIndex >= this.zero) {
          this.fills.left.splice(minIndex, this.one)
        }
        this.fills.right = range.slice(currentIndex + this.one)
        const maxIndex = this.fills.right.indexOf(max)
        if (maxIndex >= this.zero) {
          this.fills.right.splice(maxIndex, this.one)
        }
      } else {
        this.fills.left = []
        this.fills.right = this.fills.left
      }

      this.gaps.left = (
        this.fills.left.length &&
        this.fills.left[0] !== min + this.one
      )
      this.gaps.right = (
        this.fills.right.length &&
        this.fills.right[this.fills.right.length - this.one] !== max - this.one
      )
      this.range.min = min
      this.range.max = max
    },

    select(number) {
      this.$emit('input', this.page.getParameters(number))
    },

    next() {
      this.$emit('input', this.page.next())
    },

    previous() {
      this.$emit('input', this.page.previous())
    },
  },
}
