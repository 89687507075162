
import {
  addToWishlist,
  removeFromWishlist,
} from '@api/like.service'

import {
  mapActions,
  mapState,
} from 'vuex'

import {
  confirmWithPromise,
} from '@utils/helpers'

export default {
  name: 'AddToFavorites',
  props: ['id', 'type'],
  data() {
    return {
    }
  },
  computed: {
    ...mapState('like', [
      'ids',
      'count',
    ]),
    isLiked() {
      return this.ids.includes(this.id)
    },
  },
  methods: {
    ...mapActions('like', [
      'addId',
      'removeId',
    ]),
    add() {
      const data = {
        products: this.returnArray(this.id),
      }
      addToWishlist
        .execute({}, data)
        .then(() => {
          this.addId(this.id)
        })
    },
    async remove() {
      const question = this._('Удалить товар из избранного?')
      await confirmWithPromise(question, this)
      const data = {
        products: this.returnArray(this.id),
      }
      removeFromWishlist
        .execute({}, data)
        .then(() => {
          window.location.reload()
        })
    },
    returnArray(id) {
      return Array.isArray(id) ? id : [id]
    },
  },
  render() {
    return this.$scopedSlots.default({
      add: () => this.add(),
      remove: () => this.remove(),
      is_added: this.isLiked,
    })
  },
}
