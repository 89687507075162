//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const { verifyUrl } = window
export default {
  name: 'NotPermission',
  props: ['info'],
  verifyUrl,
}
