//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AddImg',
  props: ['item', 'idx'],
  methods: {
    removeItem(idx) {
      this.$emit('removeItem', idx)
    },

  },
}
