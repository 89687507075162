import {
  getCachedCategoriesResource,
} from '@api/store.service'

const SAVE_MENU = 'SAVE_MENU'
const SAVE_UPDATED_MENU = 'SAVE_UPDATED_MENU'
// const SET_LOAD = 'SET_LOAD'

function addIsFilterable(categories, ids) {
  for (let i = 0; i < categories.length; i++) {
    const category = categories[i];
    const categoryId = category.id;

    if (ids.includes(categoryId)) {
      category.is_filterable = true;
      category.is_linked = true;
    }

    if (category.children && 0 < category.children.length) {
      addIsFilterable(category.children, ids);
      const hasFilterableChild = category.children.some(child => child.is_filterable);
      if (hasFilterableChild) {
        category.is_filterable = true;
      }
    }
  }
}

export function createStore() {
  return {
    namespaced: true,
    state: {
      data: [],
      load: false,
      all: new Map(),
      menuIsLoading: false,
    },
    getters: {
      menu: state => state.data,
      all: state => state.all,
    },
    actions: {
      async getMenu({ commit, state }, payload) {
        if (state.data && state.data.length) return
        state.menuIsLoading = true
        try {
          const data = (await getCachedCategoriesResource.execute(payload))
          const response = JSON.parse(data)
          // const response = data
          state.menuIsLoading = false
          commit(SAVE_MENU, response.data)
        } catch (e) {
          state.menuIsLoading = false
        }
      },
      async addActiveChild({ commit, state }, payload) {
        return new Promise(resolve => {
          addIsFilterable(state.data, payload)
          commit(SAVE_MENU, state.data)
          resolve(state.data)
        })
      },
      setLoad({ state }, payload) {
        state.menuIsLoading = payload
      },
    },
    mutations: {
      [SAVE_UPDATED_MENU](state, payload) {
        // state.data = payload
        state.all = payload
      },
      [SAVE_MENU](state, payload) {
        state.data = payload
        payload.forEach(p => {
          state.all.set(p.id, p.children)
          p.children.forEach(c => {
            state.all.set(c.id, c.children)
            c.children.forEach(b => {
              state.all.set(b.id, b.children)
              b.children.forEach(e => {
                state.all.set(e.id, e.children)
                e.children.forEach(r => {
                  state.all.set(r.id, r.children)
                })
              })
            })
          })
        })
      },
    },
  }
}
