import {
  UserLogin,
} from '@api/auth.service'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'Login',
  mixins: [FormMixin],
  props: ['info'],
  data() {
    return {
      accept_rules: false,
      offer: window.offer,
      rules: window.rules,
      non_field_errors: '',
      form: {
        username: '',
        password: '',
      },
      fields: [
        {
          size: '12',
          title: this._('Е-mail или номер телефон'),
          rules: 'required|email_phone',
          type: 'text',
          name: 'username',
          tag: 'd-control-input',
          props: {
            autofocus: 'true',
          },
        },
        {
          size: '12',
          title: this._('Пароль'),
          rules: 'required',
          type: 'password',
          name: 'password',
          tag: 'd-control-input',
        },
      ],
    }
  },
  methods: {
    async prepareData() {
      this.non_field_errors = ''
      this.$refs.validator.validate().then(valid => {
        if (!valid) return
        this.form.username = this.form.username.toLowerCase()
        this.submit(valid, this.form)
      })
    },
    send(data) {
      return UserLogin.execute({}, data)
        .then(({ data: { redirect: { location } } }) => {
          // AnswerMessage(resData, this)
          if (this.info.seller) {
            window.location.href = location
            return
          }
          window.location.reload()
          this.$emit('close')
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      console.log(parsed);
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },
}
