//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'

import { confirmWithPromise } from '@utils/helpers'

// const SINGLE_CART = 1

export default {
  name: 'CartView',
  props: {
    info: {
      default: () => {},
    },
  },
  data() {
    return {
      order_page: window.order_page,
      currency: window.currency,
      index_page: window.baseUrl,
      oneClickOrderEnabled: window.is_one_click_order_enabled,
    }
  },
  async mounted() {
    await this.getCart()
  },
  computed: {
    ...mapState('cart', [
      'cart',
    ]),
  },
  methods: {
    ...mapActions('cart', [
      'getCart',
      'clearCart',
    ]),
    initCheckout(shop) {
      console.log(`${this.order_page}${shop}/`);
      window.location.href = `${this.order_page}${shop}/`
      // console.log(this.cart.length > SINGLE_CART, `${this.order_page}${shop}/`)
      // if (this.cart.length > SINGLE_CART) {
      //   return
      // }
      // window.location.href = this.order_page
    },
    async clearAllCart() {
      const question = this._('Вы действительно хотите очистить корзину?')
      try {
        await confirmWithPromise(question, this)
        this.clearCart()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
