import {
  createReceiver,
  createSender,
  createFileSender,
  createRemover,
} from '@resource/resource'

import {
  prefixAPI,
} from '@resource/api'

const GEO_MODEL = 'geo'
const REVIEW_MODEL = 'reviews'
// geo
export const CITIES_URL = prefixAPI('/settlements/{?is_featured,search,order}', GEO_MODEL)
const SET_CITIES_URL = prefixAPI('/set-settlement/', GEO_MODEL)
// search
export const SEARCH_URL = prefixAPI('search/{?search,limit}')
const SEARCH_CATEGORIES_URL = prefixAPI('store/categories/list/product-search/{?search}')
// reviews
const REVIEW_CREATE_URL = prefixAPI('/create/', REVIEW_MODEL)
const REVIEW_LIST_URL = prefixAPI('/list/{?id,type,offset}', REVIEW_MODEL)
export const REVIEW_SELLER_LIST_URL = prefixAPI('/list/{review_type}/by-shop/{?filters*,id,type,offset,limit}', REVIEW_MODEL)
const REVIEW_PHOTO_CREATE_URL = prefixAPI('/photos/create/', REVIEW_MODEL)
const REVIEW_VIDEO_CREATE_URL = prefixAPI('/videos/create/', REVIEW_MODEL)
const REVIEW_PHOTO_REMOVE_URL = prefixAPI('/photos/destroy/{id}/', REVIEW_MODEL)
const REVIEW_VIDEO_REMOVE_URL = prefixAPI('/videos/destroy/{id}/', REVIEW_MODEL)
const REVIEW_MEDIA_URL = prefixAPI('/{key}/list/{?type,id}', REVIEW_MODEL)
const CLAIM_TYPES_URL = prefixAPI('claim/types/list/')
const CLAIM_CREATE_URL = prefixAPI('claim/create/')

// search
export const searchResource = createReceiver(SEARCH_URL)
export const searchCategoriesResource = createReceiver(SEARCH_CATEGORIES_URL)
// geo
export const getCities = createReceiver(CITIES_URL)
export const setCity = createSender(SET_CITIES_URL)
// reviews
export const ReviewCreate = createSender(REVIEW_CREATE_URL)
export const ReviewPhotoCreate = createFileSender(REVIEW_PHOTO_CREATE_URL)
export const ReviewVideoCreate = createSender(REVIEW_VIDEO_CREATE_URL)
export const ReviewPhotoRemove = createRemover(REVIEW_PHOTO_REMOVE_URL)
export const ReviewVideoRemove = createRemover(REVIEW_VIDEO_REMOVE_URL)
export const ReviewListResoure = createReceiver(REVIEW_LIST_URL)
export const ReviewSellerListResoure = createReceiver(REVIEW_SELLER_LIST_URL)
export const ReviewMediaList = createReceiver(REVIEW_MEDIA_URL)

export const ClaimTypeList = createReceiver(CLAIM_TYPES_URL)
export const ClaimCreate = createSender(CLAIM_CREATE_URL)
