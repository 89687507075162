//
//
//
//
//
//


/**
 * Date-time object to a readable form transformer component.
 *
 * TODO:
 *
 *  - [ ] Timezone handling.
 *  - [ ] Actual time format support.
 *  - [ ] Countdown format support(like "10s ago").
 *
 * @version 0.1.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */
export default {
  name: 'DateFormat',
  props: {
    /**
     * Date, that must be formatted.
     */
    value: {
      type: [String, Number, Date],
      required: true,
    },

    /**
     * Date format. By default `.toLocaleString` will be used.
     *
     * @todo Format string is not ready yet.
     */
    format: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      date: null,
      formatted: null,
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(date) {
        this.date = new Date(date);
      },
    },
    format: {
      immediate: true,
      handler: 'formatDate',
    },

    date: {
      immediate: true,
      handler: 'formatDate',
    },
  },

  methods: {
    formatDate() {
      if (null === this.format) {
        this.formatted = this.date.toLocaleString();
      }
    },
  },
}

