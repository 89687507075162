/* eslint-disable no-unused-expressions */
// const { language } = window

import AOS from 'aos';

AOS.init();

// import SnapSlider from '@tannerhodges/snap-slider';

// const slider = new SnapSlider('#slider')

export function checkLogo() {
}

const hoverFn = (child, timeout) => {
  window.mounseover_timeout = setTimeout(() => {
    const overlay = document.getElementById('menu-overlay')
    const menu = document.getElementById('fat-menu')
    const triggers = Array.from(document.getElementsByClassName('catalog-btn'))
    triggers.forEach(b => b.classList.add('is-opened'))
    menu.classList.add('is-active')
    menu.classList.add('is-hovered')
    overlay.classList.remove('is-hidden')
    // eslint-disable-next-line no-underscore-dangle
    window.app.__vue__.getMenu()
  }, timeout)
}

export function imitateHover() {
  const parent = document.getElementById('desktop-hover')
  const timeout = 700
  if (!parent) return
  parent.addEventListener('mouseenter', () => {
    hoverFn(parent, timeout)
  })
  parent.addEventListener('mouseleave', () => {
    clearTimeout(window.mounseover_timeout)
  })
}

export function mobileFlipper() {
  const cards = Array.from(document.getElementsByClassName('flip-container'))
  cards.forEach(el => {
    el.addEventListener('click', () => {
      cards.forEach(card => card.classList.remove('hover'))
      el.classList.add('hover')
    })
  })
}
export function antiMobileFlipper(e) {
  if ('string' === typeof e.target.className && e.target.className.includes('category-card')) return
  const cards = Array.from(document.getElementsByClassName('flip-container'))
  cards.forEach(card => card.classList.remove('hover'))
}

export function prettyMobHeader() {
  const header = document.getElementById('header-wrap')
  const offset = 60
  if (!header) return
  const scrollHeader = () => {
    if (document.body.scrollTop > offset ||
      document.documentElement.scrollTop > offset) {
      header.classList.add('is-active')
      return false
    }
    header.classList.remove('is-active')
    return false
  }
  window.addEventListener('scroll', scrollHeader, { passive: true })
}

function scrollFunction() {
  const btnScroll = document.getElementById('scroll-top')
  const offset = 60
  if (document.body.scrollTop > offset ||
    document.documentElement.scrollTop > offset) {
    btnScroll.classList.add('is-active')
  } else {
    btnScroll.classList.remove('is-active')
  }
}

export const scrolly = () => {
  // Scroll to Up
  scrollFunction()
  window.addEventListener('scroll', scrollFunction, { passive: true })
}

export function themeAccordions() {
  const [parent] = document.getElementsByClassName('answers-block')
  console.log(parent);
  if (!parent) return
  const themes = [...parent.getElementsByClassName('theme-link')]
  themes.forEach(theme => {
    theme.addEventListener('click', () => {
      const answers = [...parent.getElementsByClassName('js-answers')]
      const child = answers.find(el => el.dataset.id === theme.dataset.id)
      console.log(child);
    })
  })
}

export function changeSellerText() {
  const sellerBtns = [...document.getElementsByClassName('js-seller-link')]
  const [sellerText] = [...document.getElementsByClassName('js-seller-text')]
  const [firstBtn] = sellerBtns
  if (!sellerBtns || !sellerText) return
  sellerBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      sellerText.innerHTML = btn.dataset.text
      sellerBtns.forEach(item => {
        item !== btn ? item.classList.remove('is-active') : item.classList.add('is-active')
      })
    })
  })
  firstBtn.dispatchEvent(new Event('click'))
}

const BG_COUNTS = 17

const IMAGE_WIDTH = 44

export function logoSprite() {
  const elements = [...document.getElementsByClassName('js-cart-entity')]
  elements.forEach(el => {
    setInterval(() => {
      const val = Math.floor(Math.random() * (BG_COUNTS - 0) + 0)
      el.style.backgroundPosition = `-${IMAGE_WIDTH * val}px 0px`
    }, 3000)
  })
}
